import { parse, stringify } from "qs";
import React, { useContext, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Modal } from "@Components/atoms";
import { Filter, FilterContainer } from "@Components/organisms/Filter";
import { SortFilter } from "@Components/organisms/SortFilter";
import ListingPageTemplate from "@Components/templates/ListingPageTemplate";
import { ModalsContext } from "@Context/index";
import { useQueryString } from "@Hooks/index";
import * as Sentry from "@sentry/browser";
import { gtmEnhancedEcommerce } from "@Utilities/gtm";
import { replaceTargetQS } from "@Utilities/queryStringHelper";
import { toArray } from "@Utilities/utilities";
import { getAttraqtPrices } from "@Utilities/utils";

import { SessionDataContext } from "../../../context/sessionData";
import { trackListingPage } from "../../../helpers/criteo";
import useBanners from "./api/fetchBanners";
import useQuickLinks from "./api/fetchQuickLinks";
import useWidget from "./api/fetchVariants";
import { calculateProductsAndBannersPositions } from "./calculateProductsAndBannersPositions";
import createFilters from "./createFilters";
import createMetadata from "./createMetadata";
import useCategoryParams, { SORT_OPTIONS } from "./useCategoryParams";
import getCriteoData from "./utils";

const VARIANTS_PER_PAGE = 24;

interface ListingPageMatchParams {
  category: string;
  subcategory?: string;
}

export function ListingPage() {
  const match = useRouteMatch<ListingPageMatchParams>();
  const history = useHistory();
  const [updateQS] = useQueryString();
  const [sessionData] = useContext(SessionDataContext);
  const { pageNumber, sortBy, filterParams } = useCategoryParams();
  const { openModal, closeModal, modals } = useContext(ModalsContext);
  const filterAmount = Object.keys(filterParams).length;
  let criteoData = [] as string[];

  const currentCategory = match.params.category;
  const currentSubcategory = match.params.subcategory;
  const listingType = currentSubcategory ? "subcategory" : "category";
  const combinedCategoryPath = currentSubcategory
    ? `${currentSubcategory?.toLowerCase()}/${currentCategory?.toLowerCase()}`
    : currentCategory;

  const categorySlug = currentSubcategory || currentCategory;
  const pageTitle = categorySlug.replaceAll("-", " ");

  const currentPage = pageNumber || 1;
  const variantsOnPage = currentPage * VARIANTS_PER_PAGE;

  useEffect(() => {
    if (sessionData && criteoData?.length > 0) {
      trackListingPage(sessionData.hashed_email, criteoData);
    }
  }, [JSON.stringify(sessionData), criteoData]);

  useEffect(() => {
    if (pageTitle && combinedCategoryPath)
      gtmEnhancedEcommerce(
        "impressionView",
        combinedCategoryPath,
        `${pageTitle} Listing Page`.toUpperCase(),
      );
  }, [pageTitle, combinedCategoryPath]);

  const { data: recommendations, isLoading: isLoadingVariants } = useWidget({
    category: currentCategory,
    subcategory: currentSubcategory,
    userId: sessionData?.user_id,
    isAuthenticated: sessionData?.is_authenticated,
    trigger: sessionData.loaded,
  });

  window.ebRecoIds = window.ebRecoIds || [];
  window.ebRecoIds.push(recommendations?.id);

  const { data: banners, isLoading: isLoadingBanners } = useBanners({
    category: categorySlug,
    trigger: sessionData.loaded,
  });
  const { data: quickLinksData } = useQuickLinks({ category: categorySlug });

  if (isLoadingVariants || isLoadingBanners || !sessionData.loaded)
    return (
      <ListingPageTemplate
        variants={[]}
        isShowMore={false}
        header=""
        loading
        loadMoreVariants={() => undefined}
        openFilters={() => undefined}
        handleProductTracking={() => undefined}
      />
    );

  const variants = createFilters(recommendations?.recommendations, filterParams, sortBy);
  const facets = createMetadata(recommendations?.recommendations, filterParams, listingType);
  const quickLinks = quickLinksData?.quickLinksCollection?.items[0]?.linkCollection?.items;
  const variantAmount = variants?.length;

  const [productsWithBanner, countProductsPerPage] = calculateProductsAndBannersPositions(
    banners,
    false,
    true,
    variants,
    currentPage,
    VARIANTS_PER_PAGE,
    pageTitle,
  );

  const isShowMore = variantsOnPage < variantAmount;
  const variantsAfterPagination = productsWithBanner.slice(0, countProductsPerPage);

  criteoData = getCriteoData(variantsAfterPagination);

  const handleProductTracking = (variant: any, i: number) => {
    const [variantPrice] = getAttraqtPrices(variant?.product?.retailPrice, variant?.product?.price);

    const xoWidget = {
      id: recommendations?.metadata?.widgetId,
      sourceId: recommendations?.id,
      name: recommendations?.widget?.title,
    };

    gtmEnhancedEcommerce(
      "impressionClick",
      [
        {
          id: variant?.id,
          name: variant?.product?.webname,
          category: variant?.product?.mainCategory,
          price: variantPrice?.toFixed(2)?.toString(),
          colour: variant?.product?.color,
          size: variant?.product?.sizes[0],
          url: variant?.product?.url,
          image: variant?.product?.photo,
          position: i,
        },
      ],
      `${pageTitle} Listing Page`.toUpperCase(),
      xoWidget,
    );
  };

  const handleBannerTracking = (banner: any) => {
    const bannerMedia = banner?.items?.contentCollection?.items?.find(
      (item) => item?.__typename === "Media",
    );
    gtmEnhancedEcommerce(
      "promotionClick",
      [
        {
          id: banner?.items?.internalName,
          name: banner?.items?.externalName,
          creative: bannerMedia?.internalName,
          position: banner?.internalName,
        },
      ],
      `${pageTitle} Listing Page`.toUpperCase(),
    );
  };

  const handleQuickLinkTracking = (label: string) => {
    gtmEnhancedEcommerce(
      "custom",
      {
        event: "eventTracking",
        category: "QuickLink",
        action: "Click",
        label,
      },
      `${pageTitle} Listing Page`.toUpperCase(),
    );
  };

  const loadMoreVariants = () => {
    const query = parse(history.location.search.substr(1));
    query.page = String(currentPage + 1);
    return history.replace({
      search: stringify(query, { arrayFormat: "repeat" }),
    });
  };

  const setFilter = (selectedFilter: string, target: string, activeOptions: string[]) => {
    gtmEnhancedEcommerce(
      "custom",
      { event: "eventTracking", action: selectedFilter, category: "Filters", label: target },
      "Search Page",
    );
    if (activeOptions.includes(selectedFilter)) {
      const newFilterList = activeOptions.filter(
        (activeFilters) => activeFilters !== selectedFilter,
      );
      updateQS(target, newFilterList);
    } else {
      updateQS(target, [...activeOptions, selectedFilter]);
    }
  };

  const setSortFilter = (selectedFilter: string) =>
    replaceTargetQS(history, "order", selectedFilter);

  const openFilters = () => {
    openModal("Filters");
  };

  return (
    <>
      <ListingPageTemplate
        variants={variantsAfterPagination}
        isShowMore={isShowMore}
        header={pageTitle}
        loading={false}
        loadMoreVariants={loadMoreVariants}
        quickLinks={quickLinks}
        openFilters={openFilters}
        handleProductTracking={handleProductTracking}
        handleBannerTracking={handleBannerTracking}
        handleQuickLinkTracking={handleQuickLinkTracking}
      />
      <Modal
        isOpen={modals.includes("Filters")}
        hide={() => closeModal("Filters")}
        animation="SlideFromRight"
        overlay
      >
        <FilterContainer currentProductAmount={variantAmount} currentFilterAmount={filterAmount}>
          <SortFilter options={SORT_OPTIONS} activeOption={sortBy} setFilter={setSortFilter} />
          {facets?.map((filter) => {
            const filterSlug = filter?.attribute;
            const filterOptions = filter?.values;
            const filterName = filter?.name;
            const filterType = filter?.type;
            // tu jest coś z dupy
            const activeOptions = filterParams[filter?.attribute]
              ? toArray(filterParams[filter?.attribute])
              : [];
            return (
              <Filter
                target={filterSlug}
                options={filterOptions}
                activeOptions={activeOptions}
                label={filterName}
                type={filterType}
                setFilter={setFilter}
              />
            );
          })}
        </FilterContainer>
      </Modal>
    </>
  );
}

export default ListingPage;
