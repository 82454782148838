import { createUseStyles } from "react-jss";

export interface PositioningTypes {
  columnLength: number;
  columnStart: number;
  rowLength: number;
  rowStart: number;
}

export interface ItemSpacing {
  gutter: [number, number, number, number];
}

export interface GridItemStylingProps extends PositioningTypes, ItemSpacing {}

const useStyles = createUseStyles(() => {
  return {
    gridItem: (props: GridItemStylingProps) => ({
      gridColumnStart: `${props.columnStart + 1}`,
      gridColumnEnd: `span ${props.columnLength}`,
      gridRowStart: `${props.rowStart + 1}`,
      gridRowEnd: `span ${props.rowLength}`,
      marginTop: `${Number(props.gutter[0]) * 0.5}rem`,
      marginRight: `${Number(props.gutter[1]) * 0.5}rem`,
      marginBottom: `${Number(props.gutter[2]) * 0.5}rem`,
      marginLeft: `${Number(props.gutter[3]) * 0.5}rem`,
    }),
  };
});

export default useStyles;
