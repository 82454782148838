import { useFetchCustomPageQuery } from "./graphql/operation.generated";

interface UseCustomPageProps {
  pageSlug: string;
}

const useCustomPage = ({ pageSlug }: UseCustomPageProps) =>
  useFetchCustomPageQuery({ pageSlug }, { refetchOnWindowFocus: false });

export default useCustomPage;
