import React from "react";
import PropTypes from "prop-types";
import ContentLoader from "react-content-loader";

const ProductSkeleton = ({ className }) => {
  return (
    <ContentLoader className={className} width="100%" height="100%" preserveAspectRatio={"xMinYMin slice"}>
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
    </ContentLoader>
  );
};

ProductSkeleton.propTypes = {
  className: PropTypes.string,
};

export default ProductSkeleton;
