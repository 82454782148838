import React from "react";
import { useStyles } from "../style";
import SVG from "react-inlinesvg";

import closeIcon from "../../../../../../images/collapse_icon.svg";

interface CloneButtonProps {
  onClick: () => void;
  className: string;
}

export const CloseButton = ({ onClick, className }: CloneButtonProps) => {
  const classes = useStyles();

  return (
    <button className={`${classes.closeButton} ${className}`} onClick={onClick}>
      <SVG src={closeIcon} />
    </button>
  );
};

export default CloseButton;
