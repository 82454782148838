import React from "react";

import { Button, ModalTemplate, ProductThumbnail, Typography } from "@Components/atoms";
import Price from "@Components/molecules/Price";
import Product from "@Components/molecules/Product";
import { formatGrammaticalNumber } from "@Utilities/utils";

import { useWidget } from "./api/fetchVariants";
import { ProductLoader, TextSkeleton } from "./loaders";
import { useStyles } from "./style";

interface BagWidgetProps {
  hide: () => void;
}

export function BagWidget({ hide }: BagWidgetProps) {
  const classes = useStyles();

  const { isLoading, error, data } = useWidget();

  if (error) return null;

  function goToCart() {
    window.location.href = "/bag";
  }

  const totalPrice = data?.total?.net;
  return (
    <ModalTemplate>
      {{
        header: (
          <Typography
            variant="h2"
            gutterBottom={false}
            fontSize="14px"
            fontFamily="primaryFont"
            fontWeight={600}
          >
            Your shopping basket:{" "}
            {isLoading ? (
              <TextSkeleton />
            ) : (
              formatGrammaticalNumber(data?.quantity, "items", "item")
            )}
          </Typography>
        ),
        content: (
          <>
            <div className={classes.productList}>
              {isLoading ? (
                <ProductLoader />
              ) : (
                data?.lines.reverse().map((item, i) => {
                  const variantImage = item.product_variant.product_image.image_270x270;
                  const variantName = item.product_variant.name;
                  const variantRetailPrice =
                    item.product_variant.stock_info_per_segment.default.retail_price;
                  const variantPrice = item.product_variant.stock_info_per_segment.default.price;
                  const variantQuantity = item.quantity;
                  const variantSize = item.product_variant.variant_size.display_value;
                  const variantColor = item.product_variant.color.display_value;

                  return (
                    <Product key={i} className={classes.productContainer}>
                      <ProductThumbnail
                        mainImg={variantImage}
                        alt={variantName}
                        className={classes.customImage}
                        holdAspectRatio
                        loader={false}
                      />
                      <div className={classes.details}>
                        <Typography fontWeight={500}>{variantName}</Typography>
                        <Typography fontWeight={500} textTransform="capitalize">
                          {variantColor}
                        </Typography>
                        <Price
                          retailPrice={variantRetailPrice}
                          price={variantPrice}
                          data-test="PDP-product-price-basket"
                        />
                        <Typography customColor="#727272">Size: {variantSize}</Typography>
                        <Typography customColor="#727272">
                          Qty:
                          {variantQuantity}
                        </Typography>
                      </div>
                    </Product>
                  );
                })
              )}
            </div>
            <div className={classes.contentPlug} />
            <div className={classes.summaryContainer}>
              <Typography gutterBottom={false} fontSize="14px" fontWeight={600}>
                Total
              </Typography>
              <Typography
                gutterBottom={false}
                textTransform="uppercase"
                data-test="PDP-in-total-basket"
              >
                {isLoading ? <TextSkeleton /> : `£${totalPrice}`}
              </Typography>
            </div>
          </>
        ),
        navigation: (
          <div className={classes.navigationContainer}>
            <Button
              className={classes.customButton}
              color="secondary"
              onClick={hide}
              fluid
              data-test="PDP-button-to-continue-shipping"
            >
              Continue Shopping
            </Button>
            <Button onClick={() => goToCart()} fluid data-test="PDP-button-to-checkout">
              View Basket
            </Button>
          </div>
        ),
      }}
    </ModalTemplate>
  );
}

export default BagWidget;
