import classNames from "classnames";
import * as CSS from "csstype";
import React from "react";
import ContentLoader from "react-content-loader";
import { useTheme } from "react-jss";

import { capitalize } from "@Utilities/utils";

import { useStyles } from "./style";

export const FONT_VARIANTS = {
  primaryFont: "primaryFont",
  secondaryFont: "secondaryFont",
} as const;

export interface TypographyStyleProps {
  cursor?: CSS.CursorProperty;
  customColor?: string;
  decorator?: CSS.TextDecorationProperty<1>;
  fontSize?: string;
  fontWeight?: CSS.FontWeightProperty;
  textTransform?: CSS.TextTransformProperty;
}

interface TypographyProps extends TypographyStyleProps {
  align?: CSS.TextAlignProperty;
  children?: React.ReactNode;
  className?: string;
  color?: CSS.ColorProperty;
  component?: keyof JSX.IntrinsicElements;
  display?: CSS.DisplayProperty;
  fontFamily?: keyof typeof FONT_VARIANTS;
  gutterBottom?: boolean;
  gutterSide?: boolean;
  gutterTop?: boolean;
  handleClick?: () => void;
  loading?: boolean;
  noWrap?: boolean;
  paragraph?: boolean;
  variant?: keyof typeof defaultVariantMapping;
}

function TextSkeleton() {
  return (
    <ContentLoader width={96} height={12} viewBox="0 0 96 12">
      <rect x="0" y="0" rx="0" ry="0" width="96" height="12" />
    </ContentLoader>
  );
}

const defaultVariantMapping = {
  h1: "h1" as const,
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  paragraph: "p",
  newParagraph: "p",
  caption: "span",
} as const;

export function Typography({
  variant = "paragraph",
  gutterBottom = true,
  gutterTop,
  gutterSide,
  component,
  paragraph,
  children,
  className,
  noWrap,
  align = "inherit",
  display = "block",
  color = "initial",
  decorator,
  textTransform,
  handleClick,
  cursor,
  loading = false,
  fontWeight = "initial",
  fontFamily,
  fontSize,
  customColor,
  ...otherProps
}: TypographyProps) {
  const theme = useTheme() as any;
  const extendedTheme = {
    theme,
    decorator,
    textTransform,
    cursor,
    fontWeight,
    fontSize,
    customColor,
    ...otherProps,
  };
  const classes = useStyles(extendedTheme);
  const ComponentType = component || defaultVariantMapping[variant] || "span";

  const styledClass = classNames(
    className,
    classes.root,
    classes.system,
    variant === "newParagraph" && classes.newParagraph,
    gutterBottom && classes.typographyGutter,
    gutterTop && classes.typographyGutterTop,
    gutterSide && classes.typographyGutterSide,
    paragraph && classes.typographyParagraph,
    decorator && classes.typographyDecorator,
    cursor && classes.typographyCursor,
    noWrap && classes.noWrap,
    textTransform && classes.textTransform,
    align !== "inherit" && classes["align".concat(capitalize(align) as string)],
    display !== "initial" && classes["display".concat(capitalize(display) as string)],
    color !== "initial" && classes["color".concat(capitalize(color) as string)],
    fontFamily && classes[fontFamily],
    fontSize && classes.typographyFontSize,
    customColor && classes.customColor,
  );

  return (
    <ComponentType className={styledClass} onClick={handleClick} {...otherProps}>
      {loading ? <TextSkeleton /> : children}
    </ComponentType>
  );
}

export default Typography;
