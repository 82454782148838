import React, { memo, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { Accordion, Grid, Typography } from "@Components/atoms";
import { clearTargetQS } from "@Utilities/queryStringHelper";

import FilterOption, { FilterOptionType } from "./FilterOption";
import { useStylesFilter as useStyles } from "./style";

type OptionType = {
  selected: boolean;
  value: string;
};
export interface FilterProps {
  activeOptions: Array<string>;
  columns?: number;
  elementKey?: number;
  label: string;
  options: OptionType[];
  setFilter: (selectedFilter: string, target: string, activeOptions: string[]) => void;
  target: string;
  type?: FilterOptionType;
}

export const Filter = memo(
  ({
    target,
    options,
    activeOptions,
    label,
    elementKey,
    type = "button",
    columns = 2,
    setFilter,
  }: FilterProps) => {
    const history = useHistory();
    const classes = useStyles();
    const numberOfActiveFilter = activeOptions.length;

    const clearFilters = useCallback(
      (e) => {
        e.stopPropagation();
        clearTargetQS(history, target);
      },
      [history, target],
    );

    return (
      <>
        <Accordion.Toggle elementKey={elementKey} className={classes.customToggle}>
          <div className={classes.customHeader}>
            <Typography variant="caption" gutterBottom={false} textTransform="uppercase">
              {label}
            </Typography>
            {numberOfActiveFilter > 0 && (
              <Typography
                variant="caption"
                gutterBottom={false}
                decorator="underline"
                handleClick={clearFilters}
              >
                Clear {numberOfActiveFilter}
              </Typography>
            )}
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse elementKey={elementKey} className={classes.customCollapse}>
          <Grid container>
            {options.map((key, index) => {
              if (key.value) {
                return (
                  <FilterOption
                    key={index}
                    handleClick={() => setFilter(key.value, target, activeOptions)}
                    selected={key.selected}
                    filterValue={key.value}
                    type={type}
                    columns={columns}
                  />
                );
              }
              return null;
            })}
          </Grid>
        </Accordion.Collapse>
      </>
    );
  },
);

Filter.displayName = "Filter";

export default Filter;
