import * as React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import {
  compose,
  space,
  SpaceProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  border,
  BorderProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  typography,
  TypographyProps
} from "styled-system";

type System = SpaceProps &
  ColorProps &
  LayoutProps &
  FlexboxProps &
  GridProps &
  BorderProps &
  PositionProps &
  TypographyProps &
  ShadowProps;

interface TagProps extends System {
  className?: string;
  children: React.ReactNode;
  component?: React.ElementType;
}

const useStyles = createUseStyles(() => {
  const system = compose(space, color, layout, flexbox, grid, border, position, shadow, typography);
  return {
    system,
  };
});

export const Box = React.forwardRef<HTMLElement, TagProps>(function Box(
  { children, className, component: Component = "div", ...system },
  ref,
) {
  const extendedTheme = {
    ...system,
  };
  const classes = useStyles(extendedTheme);
  const styledClasses = classNames([className, classes.system]);

  if (typeof children === "function") {
    return children({ styledClasses, system });
  }

  return (
    <Component ref={ref} className={styledClasses} {...system}>
      {children}
    </Component>
  );
});

export default Box;
