import React from "react";

import { DefaultTextFragment } from "@Components/pages/CustomPage/api/graphql/schema.generated";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { RICHTEXT_OPTIONS } from "@Utilities/richTextOptions";

import useStyles from "./style";

interface TextBlockProps {
  data: DefaultTextFragment;
}

export function TextBlock({ data }: TextBlockProps) {
  const classes = useStyles();

  const text = data.longText?.json;

  return (
    <div className={classes.textBlock}>{documentToReactComponents(text, RICHTEXT_OPTIONS)}</div>
  );
}

export default TextBlock;
