import { FilterOptionType } from "@Components/organisms/Filter/FilterOption";

import { isSelected, uniqueArray } from "./utils";

type AttributeType = {
  [key: string]: {
    [key: string]: {
      name: string;
      slug: string;
      type: FilterOptionType;
    };
  };
};

type ListingPageType = "collection" | "category" | "subcategory";

type FilterType = {
  [key: string]: string[];
};

type VariantType = {
  product: {
    [key: string]: string | string[];
  };
};

export const ATTRIBUTES: AttributeType = {
  category: {
    colorGroupHEX: {
      name: "Colour",
      slug: "colour",
      type: "hex",
    },
    availableSizes: {
      name: "Size",
      slug: "size",
      type: "button",
    },
    subCategory: {
      name: "Subcategory",
      slug: "subcategory",
      type: "button",
    },
  },
  subcategory: {
    colorGroupHEX: {
      name: "Colour",
      slug: "colour",
      type: "hex",
    },
    availableSizes: {
      name: "Size",
      slug: "size",
      type: "button",
    },
  },
  collection: {
    colorGroupHEX: {
      name: "Colour",
      slug: "colour",
      type: "hex",
    },
    availableSizes: {
      name: "Size",
      slug: "size",
      type: "button",
    },
    mainCategory: {
      name: "Category",
      slug: "category",
      type: "button",
    },
    subCategory: {
      name: "Subcategory",
      slug: "subcategory",
      type: "button",
    },
  },
};

// generate  for filters

const createMetadata = (
  variants: VariantType[],
  filterParams: FilterType,
  listingType: ListingPageType,
) => {
  if (variants?.length === 0 || variants === null) return [];
  let facets = [] as any[];

  const numberOfAllFilters = Object.keys(filterParams).length;

  const targetAttributes = ATTRIBUTES[listingType];
  const filterAttributes = Object.keys(targetAttributes);
  const filterObject = Object.keys(filterParams);

  facets = filterAttributes.flatMap((att) => {
    const uniqueAttributeValues = uniqueArray(
      variants.map((item) => item.product[att]).flat(),
    ).sort();

    const filterAttributesWithoutCurrent = filterObject.filter((attribute) => attribute !== att);

    const activeFilter = filterParams[att] || [];
    const numberOfFilters = activeFilter.length;

    const newVariants = variants.filter((variant) => {
      return filterAttributesWithoutCurrent.every((key) => {
        if (key === "availableSizes") {
          return filterParams[key]?.some((size) => variant.product[key]?.includes(size));
        }
        return filterParams[key]?.includes(variant.product[key]);
      });
    });

    const newUniqueAttributeValues = uniqueArray([
      ...newVariants.map((item) => item.product[att]).flat(),
      ...activeFilter,
    ]).sort();

    // if there are less then 2 options for category or subcategory do not show them
    if ((att === "mainCategory" || att === "subCategory") && uniqueAttributeValues.length < 2) {
      return [];
    }

    // if category is not selected do not show subcategory
    if (att === "subCategory" && !filterParams.mainCategory) {
      const uniqueCategoryValues = uniqueArray(
        variants.map((item) => item.product.mainCategory).flat(),
      ).sort();
      if (uniqueCategoryValues.length > 1) {
        return [];
      }
    }

    if (numberOfAllFilters === 0) {
      return {
        attribute: att,
        name: targetAttributes[att].name,
        type: targetAttributes[att].type,
        slug: targetAttributes[att].slug,
        values: uniqueAttributeValues.map((x) => ({
          value: x,
          selected: isSelected(activeFilter, x),
        })),
      };
    }

    if (numberOfAllFilters === 1 && numberOfFilters !== 0) {
      return {
        attribute: att,
        name: targetAttributes[att].name,
        type: targetAttributes[att].type,
        slug: targetAttributes[att].slug,
        values: uniqueAttributeValues.map((x) => ({
          value: x,
          selected: isSelected(activeFilter, x),
        })),
      };
    }

    return {
      attribute: att,
      name: targetAttributes[att].name,
      type: targetAttributes[att].type,
      slug: targetAttributes[att].slug,
      values: newUniqueAttributeValues.map((x) => ({
        value: x,
        selected: isSelected(activeFilter, x),
      })),
    };
  });

  return facets;
};

export default createMetadata;
