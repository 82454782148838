import React from "react";
import { useStyles } from "../style";

interface MessageProps {
  children: React.ReactElement;
  className: string;
}

export const Message = ({ children, className }: MessageProps) => {
  const classes = useStyles();

  return (
    <div className={`${classes.message} ${className}`}>
      {children}
    </div>
  );
};

export default Message;
