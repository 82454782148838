import classNames from "classnames";
import * as CSS from "csstype";
import { AnimatePresence } from "framer-motion";
import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { useTheme } from "react-jss";
import { RemoveScroll } from "react-remove-scroll";

import visibilityAnimations from "@Components/animations/AnimateVisibility";
import { CloseButton, Overlay } from "@Components/atoms";
import { useClickOutside } from "@Hooks/index";
import { ThemeType } from "@Theme/theme";

import { useStyles } from "./style";

type Animations = keyof typeof visibilityAnimations;
type Position = {
  bottom?: CSS.BottomProperty<number>;
  left?: CSS.LeftProperty<number>;
  right?: CSS.RightProperty<number>;
  top?: CSS.TopProperty<number>;
};

interface Props {
  animation?: Animations;
  children: React.ReactNode;
  closeButton?: boolean;
  customPosition?: Position;
  customRoot?: HTMLElement | null;
  duration?: number;
  hide: () => void;
  hideOnOverlayClick?: boolean;
  isOpen: boolean;
  opacity?: boolean;
  overlay: boolean;
}

const Modal = ({
  isOpen,
  hide,
  children,
  animation = "Fade",
  overlay = true,
  hideOnOverlayClick = true,
  customPosition,
  customRoot,
  duration,
  opacity = true,
  closeButton = false,
  ...otherProps
}: Props) => {
  const modalRef = useRef(null);
  const theme = useTheme<ThemeType>();
  const styleProps = { customPosition, animation, theme };
  const classes = useStyles({ ...styleProps, theme });

  const root = customRoot || document.body;
  useClickOutside(modalRef, overlay && hideOnOverlayClick ? hide : null);

  const ModalAnimation = visibilityAnimations[animation];

  const isModalHorizontal = animation === "SlideFromLeft" || animation === "SlideFromRight";

  const styledClasses = classNames(
    classes.modal,
    classes[animation],
    customPosition && classes.customPosition,
    opacity && classes.modalOpacity,
  );

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isOpen && (
        <>
          {overlay && <Overlay />}
          <ModalAnimation duration={duration} className={styledClasses}>
            <RemoveScroll enabled={isOpen} style={{ height: "100%" }}>
              <div ref={modalRef} className={classes.modalContent} {...otherProps}>
                {isModalHorizontal && closeButton && (
                  <CloseButton
                    onClick={hide}
                    className={
                      animation === "SlideFromLeft"
                        ? classes.buttonPositionLeft
                        : classes.buttonPositionRight
                    }
                  />
                )}
                {children}
              </div>
            </RemoveScroll>
          </ModalAnimation>
        </>
      )}
    </AnimatePresence>,
    root,
  );
};

export default Modal;
