import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles((theme) => ({
  headerWrapper: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: theme.zIndexValues.higher,
  },
  headerWrapperFixed: {
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: theme.zIndexValues.higher,
  },
}));

export const useMenuStyles = createUseStyles((theme) => ({
  navMenuContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  navigationItem: {
    width: "100%",
  },
  modal: {
    width: "100vw",
    height: "calc(100% - 60px)",
    paddingBottom: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "scroll",
  },
  styledList: {
    listStyleType: "none",
    margin: "0",
    fontSize: "13px",
    "@global": {
      li: {
        margin: "1.5rem 0",
      },
      a: {
        display: "block",
        margin: "1.5rem 0",
      },
    },
  },
  listTitle: {
    color: theme.color.darkGray,
  },
  countryPicker: {
    width: "319px",
    margin: "2rem auto 0 auto",
    padding: "15px 15px",
    textAlign: "center",
    marginRight: "auto",
    [theme.breakpoints.up("sm")]: {
      margin: "0 0 0 2rem",
    },
  },
  dropdownContainer: {
    top: "101%",
    padding: "2rem",
  },
}));

export default useStyles;
