import React from "react";

import { CoreLinkFragment, CoreMediaFragment } from "../../../../globals/fragments.generated";
import { NavigationItem as NavigationItemType } from "../../../../globals/types";

const filterNulls = function <T>(el: T) {
  return el !== null;
};

type RenderPropType<T> = (props: T) => React.ReactNode;
interface RenderChildren<T> {
  children: RenderPropType<T>;
}

interface NavigationItemData {
  itemColor: string;
  itemMetaTitle: string;
  itemTitle: string;
  itemTitleLink: string;
  links: CoreLinkFragment[];
  media: CoreMediaFragment[];
  navigationItems: NavigationItemType[];
}

interface NavigationItemProps extends NavigationItemType, RenderChildren<NavigationItemData> {
  navigationItemsCollection: NavigationItemType;
}

function NavigationItem({
  children,
  colour,
  externalName,
  internalName,
  mainLink,
  mediaCollection,
  navigationLinksCollection,
  navigationItemsCollection,
}: NavigationItemProps) {
  const itemTitle = externalName || "";
  const itemMetaTitle = internalName || "";
  const itemTitleLink = mainLink?.url || "";
  const itemColor = colour?.colourHex || "";
  const media = mediaCollection?.items.filter(filterNulls) || [];
  const links = navigationLinksCollection?.items.filter(filterNulls) || [];
  const navigationItems = navigationItemsCollection?.items.filter(filterNulls) || [];
  return children({
    itemTitle,
    itemMetaTitle,
    itemTitleLink,
    itemColor,
    media,
    links,
    navigationItems,
  });
}

export default NavigationItem;
