import { createUseStyles } from "react-jss";

export interface FullWidthHeaderStyleProps {
  height: number | null;
  marginBottom: string;
}

const useStyles = createUseStyles({
  fullHeightBlock: (props: FullWidthHeaderStyleProps) => ({
    height: props.height,
    width: "100%",
    marginBottom: props.marginBottom,
  }),
});

export default useStyles;
