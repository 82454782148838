import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import gridStyle, { SPACINGS } from "./helpers";

import { capitalize } from "@Utilities/utils";

const useStyles = createUseStyles(gridStyle);

export const Grid = props => {
  const {
    alignContent,
    alignItems,
    container,
    direction,
    item,
    justify,
    lg,
    md,
    sm,
    children,
    spacing,
    wrap,
    xl,
    xs,
    zeroMinWidth,
    className,
  } = props;

  const classes = useStyles();

  const styleClasses = classNames(
    classes.root,
    className,
    container && [classes.container, spacing !== 0 && classes[`spacing-xs-${String(spacing)}`]],
    item && classes.item,
    zeroMinWidth && classes.zeroMinWidth,
    direction !== "row" && classes[`direction-xs-${String(direction)}`],
    wrap !== "wrap" && classes[`wrap-xs-${String(wrap)}`],
    alignItems !== "stretch" && classes[`alignItems${capitalize(alignItems.split("-")).join("")}`],
    alignContent !== "stretch" && classes[`align-content-xs-${String(alignContent)}`],
    justify !== "flex-start" && classes[`justify${capitalize(justify.split("-")).join("")}`],
    xs !== false && classes[`grid-xs-${String(xs)}`],
    sm !== false && classes[`grid-sm-${String(sm)}`],
    md !== false && classes[`grid-md-${String(md)}`],
    lg !== false && classes[`grid-lg-${String(lg)}`],
    xl !== false && classes[`grid-xl-${String(xl)}`],
  );

  return <div className={styleClasses}>{children}</div>;
};

export default Grid;

Grid.defaultProps = {
  alignContent: "stretch",
  alignItems: "stretch",
  children: null,
  container: false,
  direction: "row",
  item: false,
  justify: "flex-start",
  lg: false,
  md: false,
  sm: false,
  spacing: 0,
  wrap: "wrap",
  xl: false,
  xs: false,
  zeroMinWidth: false,
};

Grid.propTypes = {
  alignContent: PropTypes.oneOf(["stretch", "center", "flex-start", "flex-end", "space-between", "space-around"]),
  alignItems: PropTypes.oneOf(["flex-start", "center", "flex-end", "stretch", "baseline"]),
  children: PropTypes.node,
  classes: PropTypes.object,
  container: PropTypes.bool,
  direction: PropTypes.oneOf(["row", "row-reverse", "column", "column-reverse"]),
  item: PropTypes.bool,
  justify: PropTypes.oneOf(["flex-start", "center", "flex-end", "space-between", "space-around", "space-evenly"]),
  lg: PropTypes.oneOf([false, "auto", true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([false, "auto", true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: PropTypes.oneOf([false, "auto", true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  spacing: PropTypes.oneOf(SPACINGS),
  wrap: PropTypes.oneOf(["nowrap", "wrap", "wrap-reverse"]),
  xl: PropTypes.oneOf([false, "auto", true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xs: PropTypes.oneOf([false, "auto", true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  zeroMinWidth: PropTypes.bool,
  className: PropTypes.string,
};
