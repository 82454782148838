import React, { useContext, useEffect } from "react";

import {
  FullWidthMedia,
  HomepageCarousel,
  HomepageSkeleton,
  MultiColumnImage,
  SideTextImage,
} from "@Components/organisms";
import { trackHomePage } from "@Utilities/criteo";

import { SessionDataContext } from "../../../context/sessionData";
import useCarousel from "./api/fetchCarousel";
import useHomepage from "./api/fetchHomepage";

export function Homepage() {
  const { isLoading: loadingHome, error: errorHome, data: dataHome } = useHomepage();
  const { isLoading: loadingCarousel, error: errorCarousel, data: dataCarousel } = useCarousel();
  const [sessionData] = useContext(SessionDataContext);

  useEffect(() => {
    if (sessionData && sessionData.csrf_token) {
      trackHomePage(sessionData?.hashed_email);
    }
  }, [sessionData]);

  if (errorHome || errorCarousel) return <HomepageSkeleton />;

  if (loadingHome || loadingCarousel) return <HomepageSkeleton />;

  if (
    dataHome &&
    dataHome.pageCollection &&
    dataHome.pageCollection.items.length > 0 &&
    dataCarousel
  ) {
    const homepageData = dataHome.pageCollection.items[0];
    const productVariants = dataCarousel?.variants;
    const loadedHome = homepageData!
      .contentCollection!.items.filter((item) => item !== null)
      .map((contentItem, index) => {
        const banner = contentItem?.__typename === "FullWidthImage";
        const imageWithText = contentItem?.__typename === "ImageWithText";
        const multiColumnContent = contentItem?.__typename === "MultiColumnContent";

        return (
          <>
            {banner && <FullWidthMedia key={index} data={contentItem} />}
            {imageWithText && <SideTextImage key={index} data={contentItem} />}
            {multiColumnContent && <MultiColumnImage key={index} data={contentItem} />}
          </>
        );
      });

    loadedHome.splice(1, 0, <HomepageCarousel variants={productVariants} />);

    return <>{loadedHome}</>;
  }
  return <HomepageSkeleton />;
}
export default Homepage;
