import React from "react";
import { useTheme } from "react-jss";

import Typography from "../../atoms/Typography";
import { useMediaQuery } from "@Hooks";

const Copyright = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const date = new Date();
  const year = date.getUTCFullYear();

  return <Typography align={matches ? "center" : "right"}>&copy; {year} Pretty Green</Typography>;
};

export default Copyright;
