import React, { createContext, useEffect } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { createUseStyles } from "react-jss";
import { motion, AnimatePresence } from "framer-motion";
import classNames from "classnames";
import { RICHTEXT_OPTIONS } from "@Utilities/richTextOptions";

import { Alert } from "@Components/atoms";
import { Infobar as InfobarType } from '../../../globals/types';
import { useInfobar } from "./useInfobar";

type Country = "uk" | "nonUk";

interface Infobar {
  data: InfobarType[];
  className?: string;
  type: Country
}

const useStyles = createUseStyles((theme) => ({
  infobar: {
    display: "inline-block",
    maxWidth: "1440px"
  },
  infobar__text: {
    color: theme.color.lightGray,
    "& a": {
      color: theme.color.white,
      textDecoration: "underline"
    },
  },
  invisible: {
    display: "none !important",
  },
  closeButton: {
    position: "absolute",
    right: "0",
    top: "8px",
    margin: 0
  },
  customAlert: {
    justifyContent: "center"
  },
}));

const VARIANTS = {
  show: {
    opacity: 1,
  },
  hide: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const TEXT_VARIANTS = {
  show: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  hide: {
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
};

const Message = ({ messages }: { messages: InfobarType[] }) => {
  const classes = useStyles();
  const [activeInfobar, setActiveInfobar] = React.useState(0);
  const numberOfInfobars = messages.length - 1;

  useEffect(() => {
    if (numberOfInfobars === 0) return
    const interval = setInterval(() => {
      setActiveInfobar(oldInfobar => {
        if (oldInfobar === numberOfInfobars) return 0;
        return oldInfobar + 1
      });
    }, 8000);
    return () => clearInterval(interval);
  }, []);

  const renderedMsg = messages.map((message, i) => (
    <motion.span key={i} className={classes.infobar__text} variants={TEXT_VARIANTS} initial={"hide"} exit={"hide"} animate={"show"}>
      {documentToReactComponents(message.description?.json, RICHTEXT_OPTIONS)}
    </motion.span>
  ))

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      {renderedMsg[activeInfobar]}
    </AnimatePresence>
  );
};

const Infobar = ({ data, type }: React.PropsWithChildren<Infobar>) => {
  const classes = useStyles();

  const { closeInfobar, isInfobarVisible } = useInfobar(JSON.stringify(data), type);

  const combinedStyle = classNames({ "globale-hide": type == "uk" }, { "globale-show": type == "nonUk" }, { [classes.invisible]: type == "nonUk" })

  return (
    <div className={combinedStyle}>
      <AnimatePresence initial={false}>
        {isInfobarVisible && (
          <motion.div
            key="infobar"
            variants={VARIANTS}
            animate={"show"}
            exit={"hide"}
          >
            <Alert className={classes.customAlert}>
              <Alert.Message className={classes.infobar}>
                <Message messages={data} />
              </Alert.Message>
              <Alert.CloseButton className={classes.closeButton} onClick={closeInfobar} />
            </Alert>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};


export default Infobar;