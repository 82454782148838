import { useFetchBlogPostQuery } from "./graphql/schema.generated";

interface UseBlogPostProps {
  pageSlug: string;
}

const useBlogPost = ({ pageSlug }: UseBlogPostProps) =>
  useFetchBlogPostQuery({ pageSlug }, { refetchOnWindowFocus: false });

export default useBlogPost;
