import classNames from "classnames";
import React from "react";

import useStyles from "./style";

type ChildrenTemplate = {
  content: React.ReactElement;
  header: React.ReactElement;
  navigation: React.ReactElement;
};

interface ModalTemplateProps {
  children: ChildrenTemplate;
  className?: string;
}

const defaultProps = {
  className: "",
};

export function ModalTemplate({ children, className }: ModalTemplateProps) {
  const classes = useStyles();
  const styledClasses = classNames(className, classes.modal);
  const { header, content, navigation } = children;

  return (
    <div className={styledClasses}>
      {header && <div className={classes.modalHeader}>{header}</div>}
      {content && <div className={classes.modalContent}>{content}</div>}
      {navigation && <div className={classes.modalNavigation}>{navigation}</div>}
    </div>
  );
}

ModalTemplate.defaultProps = defaultProps;

export default ModalTemplate;
