/* eslint-disable react/prop-types */
import React from "react";
import SwiperCore, { Scrollbar, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

interface CarouselProps extends Swiper {
  scrollbar?: boolean;
  scrollbarLoading?: boolean;
  children: React.ReactNode;
  customNavigation?: React.ReactElement;
}

const useStyles = createUseStyles({
  scrollbar: {
    background: "transparent",
  },
  scrollbarLoading: {
    visibility: "hidden",
  },
});

export const Carousel = React.forwardRef<HTMLDivElement, CarouselProps>(function Carousel(
  { children, scrollbar, scrollbarLoading, customNavigation, ...swiperProps },
  ref,
): React.ReactElement {
  SwiperCore.use([Scrollbar, Navigation, Autoplay]);

  const classes = useStyles();

  return (
    <Swiper {...swiperProps} scrollbar={{ el: ".swiper-scrollbar", hide: false }}>
      {React.Children.map(children, (child, i) => {
        return <SwiperSlide key={i}>{child}</SwiperSlide>;
      })}
      {customNavigation}
      {scrollbar && (
        <div
          className={classNames("swiper-scrollbar", classes.scrollbar, !scrollbarLoading && classes.scrollbarLoading)}
        ></div>
      )}
    </Swiper>
  );
});

export default Carousel;
