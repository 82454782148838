import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Modals from "./modalsContext";
import { Modal } from "@Components/atoms";

import { BagWidget } from "@Components/organisms";

export const ModalsProvider = ({ children }) => {
  const [modals, setModals] = useState([]);
  const [rendered, setRender] = useState(false);

  const openModal = (newModal) => {
    if (!modals.includes(newModal)) {
      setModals((oldArr) => [...oldArr, newModal]);
    } else {
      console.error("Modal with the given name already exists");
    }
  };

  const closeModal = (oldModal) => {
    if (modals.includes(oldModal)) {
      setModals(modals.filter((modal) => modal !== oldModal));
    } else {
      console.error("Modal with the given name does not exist");
    }
  };

  useEffect(() => {
    if (!rendered) {
      !!modals.length && setRender(true);
    }
  }, [modals, rendered]);

  return (
    <Modals.Provider value={{ openModal, closeModal, modals }}>
      {rendered && (
        <>
          <Modal
            isOpen={modals.includes("bagWidgetModal")}
            hide={() => closeModal("bagWidgetModal")}
            customRoot={document.getElementById("navigation")}
            animation={"SlideFromRight"}
            data-test="basket-modal"
            closeButton
          >
            <BagWidget hide={() => closeModal("bagWidgetModal")} />
          </Modal>
        </>
      )}
      {children}
    </Modals.Provider>
  );
};

ModalsProvider.propTypes = {
  children: PropTypes.node,
};

export default ModalsProvider;
