import React, { useState } from "react";
import { useTheme } from "react-jss";
import { ThemeType } from "@Theme/theme";
import classNames from "classnames";

import { ProductSkeleton, AspectRatio } from "@Components/atoms";
import { useStyles } from './styles';

interface Props {
  children?: React.ReactNode;
  mainImg: string;
  coverImg?:string;
  alt?: string;
  className?: string;
  holdAspectRatio?: boolean;
  loader?: boolean;
}

export const ProductThumbnail = ({ children, mainImg, coverImg, alt, className, holdAspectRatio = true, loader = true }: Props) => {
  const [showImg, setShowImg] = useState(false);
  const theme = useTheme<ThemeType>();
  const classes = useStyles();
  const styledContainer = classNames(classes.imageContainer, className, holdAspectRatio && classes.aspectRatioHolder);
  const styledMainImage = classNames(showImg || classes.invisible, classes.mainImage)
  const styledLoader = showImg ? classes.invisible : ""

  const showWhenFullyLoaded = () => {
    setShowImg(true);
  };

  return (
    <div className={styledContainer}>
      {loader && (
        <AspectRatio ratio={theme.aspectRatio.defaultImageAspectRatio} className={styledLoader}>
          <ProductSkeleton />
        </AspectRatio>
      )}
      <img alt={alt} className={styledMainImage} src={mainImg} onLoad={showWhenFullyLoaded} />
      {coverImg ? <img alt={alt} className={classes.coverImage} src={coverImg} /> : null}
      {children}
    </div>
  );
};

export default ProductThumbnail;