import createExternalEvents from "../../utils/createExternalEvent";
import { ModalSettings } from "./ErrorModalContext";

interface ModalEvents {
  closeModal: (id: string) => void;
  openModal: (payload: ModalSettings) => void;
}

export const [useModalsEvents, createEvent] = createExternalEvents<ModalEvents>("modals");

export const openModal = createEvent("openModal");
export const closeModal = createEvent("closeModal");

// eslint-disable-next-line import/prefer-default-export
export const modals = {
  open: openModal,
  close: closeModal,
};
