import React from "react";

import Image from "@Components/atoms/Image/Image";
import Link from "@Components/atoms/Link/Link";
import ShopTheLook from "@Components/organisms/Contentful/ShopTheLook";

import { DefaultStaticMediaFragment } from "../../../../globals/fragments.generated";
import useStyles from "./style";

export type ShopTheLookType = {
  externalLink: string;
  name: string;
};

interface MediaBlockProps {
  data: DefaultStaticMediaFragment;
  isMobile: boolean;
}

export function StaticMedia({ data, isMobile }: MediaBlockProps) {
  const containerLink = data.link?.url;
  const shopTheLookProductList = data.shopTheLook;
  const isMobileImageProvided = !!data.imageMobile?.url;

  const mobileImage = isMobileImageProvided ? data.imageMobile : data.imageDesktop;
  const desktopImage = data.imageDesktop;

  const image = isMobile ? mobileImage : desktopImage;
  const lowQualityImage = image?.urlLowQuality || "";
  const classes = useStyles();
  return (
    <Link href={containerLink}>
      <div className={classes.mediaBlock}>
        {shopTheLookProductList && shopTheLookProductList.length > 0 ? (
          <div className={classes.mediaBlock__shopTheLook}>
            <ShopTheLook>
              {shopTheLookProductList.map((shopTheLook) => {
                const parsedProduct = JSON.parse(shopTheLook || "") as ShopTheLookType;
                const productTitle = parsedProduct.name.toUpperCase();
                return (
                  <ShopTheLook.Link href={parsedProduct.externalLink}>
                    {productTitle}
                  </ShopTheLook.Link>
                );
              })}
            </ShopTheLook>
          </div>
        ) : null}
        <picture style={{ display: "flex" }}>
          <source srcSet={image?.url} type="image/webp" />
          <Image src={image?.urlJpg} fallbackSrc={lowQualityImage} />
        </picture>
      </div>
    </Link>
  );
}

export default StaticMedia;
