import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";

import { Link } from "@Components/atoms/";

const useStyles = createUseStyles(theme => ({
  socialLinksContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "2rem auto",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
      margin: 0,
    },
  },
  iconContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: "0 6px",
    [theme.breakpoints.up("sm")]: {
      padding: "0 0 0 12px",
    },
  },
  linkIcon: {
    width: "31px",
    height: "31px",
    [theme.breakpoints.up("sm")]: {
      width: "28.2px",
      height: "28.2px",
    },
  },
  copyright: {
    textAlign: "center",
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
    },
  },
}));

export const SocialLinks = ({ media }) => {
  const classes = useStyles();

  return (
    <div className={classes.socialLinksContainer}>
      {media.map(iconLink => {
        return (
          <Link href={iconLink.link?.url} key={iconLink.mediaDesktop.url} openInNewTab>
            <picture className={classes.iconContainer}>
              <img src={iconLink.mediaDesktop.url} className={classes.linkIcon} alt={iconLink.altText}></img>
            </picture>
          </Link>
        );
      })}
    </div>
  );
};

export default SocialLinks;

SocialLinks.propTypes = {
  media: PropTypes.array,
};
