import React from "react";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";

export const STALE_QUERY_OPTIONS = {
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchIntervalInBackground: false,
  refetchOnMount: false,
  staleTime: 0,
  cacheTime: 0,
  refetchInterval: 0,
  keepPreviousData: true,
  retry: false,
  retryOnMount: false,
} as const;

type UseLazyQueryType<TData extends Record<string, any>, TError = unknown> = {
  onError: (err: TError) => void;
  onSuccess: () => void;
  useQueryHook: (
    variables: Record<string, any>,
    options: UseQueryOptions,
  ) => ReturnType<typeof useQuery>;
};

const useLazyQuery = <TData extends Record<string, any>, TError = unknown>({
  onSuccess,
  onError,
  useQueryHook,
}: UseLazyQueryType<TData, TError>) => {
  const queryClient = useQueryClient();
  const [isActive, setActive] = React.useState(false);
  const queryValue = React.useRef({});

  const queryProps = useQueryHook(queryValue.current, {
    onSuccess,
    onError,
    enabled: isActive,
    ...STALE_QUERY_OPTIONS,
  });

  const triggerQuery = (value: Record<string, any>) => {
    queryValue.current = value;
    if (isActive) {
      return queryProps.refetch();
    }

    setActive(true);
    return queryClient.invalidateQueries("fetchOrders", {
      exact: true,
      refetchActive: true,
    });
  };

  return { triggerQuery, queryProps };
};

export default useLazyQuery;
