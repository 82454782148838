import { useQuickLinksQuery } from "./graphql/operation.generated";

interface UseQuickLinksProps {
  slug: string;
}

const useQuickLinks = ({ slug }: UseQuickLinksProps) =>
  useQuickLinksQuery({ category: slug }, { refetchOnWindowFocus: false });

export default useQuickLinks;
