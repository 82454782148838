/* eslint-disable import/no-unresolved */
import React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "babel-polyfill";
import "es6-promise/auto";
import "isomorphic-fetch";
import "../legacy/sentry";

import { Footer, Header } from "@Components/organisms";
import {
  CollectionPage,
  CustomPage,
  Homepage,
  ListingPage,
  NewsletterSignIn,
  ProductDetails,
  SearchPage,
} from "@Components/pages";
import BlogPage from "@Components/pages/BlogPage";
import ReturnApp from "@Components/pages/ReturnApp";
import { ModalsProvider } from "@Context/index";
import { MantineProvider } from "@mantine/core";

import { withNotFoundCheck } from "./HOC";
import MantineTheme from "./theme/mantine/theme";
import { DJANGO_ROUTES } from "./url";

interface LayoutProps {
  children: React.ReactElement;
}

function Layout({ children }: LayoutProps) {
  return (
    <>
      <Header />
      {children}
      <Footer />
      <ReactQueryDevtools />
    </>
  );
}

export function Routes() {
  return (
    <Router>
      <Layout>
        <ModalsProvider>
          <Switch>
            <Route path="/" component={Homepage} exact />
            <Route path="/newsletter" component={NewsletterSignIn} />
            <Route
              path="/category/:category/:subcategory?/"
              component={withNotFoundCheck(ListingPage)}
            />
            <Route path="/edits/:collection" component={withNotFoundCheck(CollectionPage)} />
            <Route path="/search/" component={SearchPage} />
            <Route path="/products/" component={withNotFoundCheck(ProductDetails)} />
            <Route path="/order/:id">
              <ReturnApp isLoggedIn />
            </Route>
            <Route path="/return-order/">
              <ReturnApp />
            </Route>
            <Route path={DJANGO_ROUTES} component={() => null} />
            <MantineProvider withNormalizeCSS theme={MantineTheme}>
              <Route path="/news/:yyyy/:mm/:dd/:name" component={BlogPage} />
              <Route path="/:custom+" component={CustomPage} />
            </MantineProvider>
          </Switch>
        </ModalsProvider>
      </Layout>
    </Router>
  );
}

export default Routes;
