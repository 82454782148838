import React from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { usePostData } from "@Hooks/index";
import { Button, TextField, Typography } from "@Components/atoms";
import { EMAIL_PATTERN } from "@Utilities/patterns";

export const NotifyMeButtonModule = ({ isAuth, currentVariantID, pageLoading }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm();
  const newUserEmail = watch("userEmail", "");
  const { res: resNotify, callAPI: postNotifyMe } = usePostData({
    url: "/api/products/notify-me/",
    payload: JSON.stringify({
      variant: currentVariantID,
      ...(!isAuth && { email: newUserEmail }),
    }),
    contentType: "application/json",
  });

  const success = resNotify.success;
  const loading = resNotify.isLoading;
  const postError = resNotify.error;

  const buttonText = success ? "Thanks! You’ll Be Updated" : "Notify Me";
  const buttonDisabled = (!newUserEmail?.length && !isAuth) || success;
  const error = !!Object.keys(errors)?.length || postError;

  const showTextInput = !pageLoading && !isAuth;
  return (
    <form autoComplete="off" onSubmit={handleSubmit(postNotifyMe)}>
      <Typography align="center">We will email you if this item comes back in stock.</Typography>
      {showTextInput && (
        <TextField
          type="email"
          outline
          autocomplete="off"
          placeholder="Enter your email address"
          {...register("userEmail", {
            required: {
              value: true,
              message: "Please provide your email address",
            },
            pattern: {
              value: EMAIL_PATTERN,
              message: "Provided email address is not valid ",
            },
          })}
          error={error}
          errorText={errors.userEmail && errors.userEmail.message}
        />
      )}
      <Button type="submit" loading={loading} fluid disabled={buttonDisabled}>
        {!pageLoading && buttonText}
      </Button>
    </form>
  );
};

NotifyMeButtonModule.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  currentVariantID: PropTypes.string.isRequired,
};

export default NotifyMeButtonModule;
