import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  rootColorBox: {
    cursor: "pointer",
  },
});

const ColorBox = ({ selected, fill, handleClick, outer = "32", inner = "26", padding = "3" }) => {
  const classes = useStyles();

  return (
    <div className={classes.rootColorBox} onClick={handleClick}>
      <svg width={outer} height={outer}>
        {selected && (
          <rect
            x="0"
            y="0"
            width={outer}
            height={outer}
            stroke="black"
            fill="transparent"
            strokeWidth="1"
          />
        )}
        <rect x={padding} y={padding} width={inner} height={inner} fill={fill} />
      </svg>
    </div>
  );
};

ColorBox.propTypes = {
  selected: PropTypes.bool,
  fill: PropTypes.string,
  outer: PropTypes.string,
  inner: PropTypes.string,
  padding: PropTypes.string,
  handleClick: PropTypes.func,
};

export default ColorBox;
