import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  root: {
    color: "inherit !important",
    textDecoration: "none",
    "&:hover": {
      color: "",
    },
    "&:focus": {
      color: "inherit",
    },
  },
  noPointer: {
    cursor: "auto",
  },
  disabled: {
    cursor: "not-allowed",
    textDecoration: "line-through",
    color: theme.color.darkGray,
    pointerEvents: "none",
  },
  underline: {
    textDecoration: "underline",
  },
}));

export default useStyles;
