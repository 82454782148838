import React from "react";
import { useRouteMatch } from "react-router-dom";

import ContentfulPageTemplate from "@Components/templates/ContentfulPageTemplate/ContentfulPageTemplate";

import useCustomPage from "./api/fetchCustomPage";

interface CustomPageMatchParams {
  custom: string;
}

export function CustomPage() {
  const match = useRouteMatch<CustomPageMatchParams>();

  const { data, isLoading } = useCustomPage({
    pageSlug: `/${match.params.custom}`,
  });

  if (isLoading) return <div />;

  if (data?.customPageCollection?.items.length === 0) return <div />;

  const customPageBlocks = data?.customPageCollection?.items[0];
  return <ContentfulPageTemplate data={customPageBlocks} />;
}

export default CustomPage;
