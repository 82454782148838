import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  staticAccordion: {
    backgroundColor: theme.color.lightGray,
    borderTop: "none",
    "& svg": {
      display: "none",
    },
  },
}));

export default useStyles;
