import { ProductDetails } from "./ProductDetailsPage";
import CollectionPage from "./CollectionPage";
import Homepage from "./Homepage";
import NewsletterSignIn from "./NewsletterSignIn";
import ListingPage from "./ListingPage";
import SearchPage from "./SearchPage";
import CustomPage from "./CustomPage";

export {
  ProductDetails,
  Homepage,
  NewsletterSignIn,
  CollectionPage,
  ListingPage,
  SearchPage,
  CustomPage,
};
