import React, { useContext, useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { SessionDataContext } from "@Context/index";
import Alert from "@Components/atoms/Alert";



export const NotiStack = () => {
  const [sessionData] = useContext(SessionDataContext);
  const [alerts, setAlerts] = useState(sessionData?.messages);

  useEffect(() => {
    if (sessionData?.messages?.length > 0) {
      setAlerts(sessionData?.messages)
    }
  }, [sessionData])

  const closeAlert = (msg: string) => {
    setAlerts(arr => arr.filter(alert => alert.message !== msg))
  };

  const variants = {
    active: {
      backgroundColor: "#f00"
    },
    inactive: {
      backgroundColor: "#fff",
      transition: { duration: 2 }
    }
  }

  return (
    <AnimatePresence>
      {alerts.map(alarm => (
        <motion.div
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Alert type={alarm.tags} key={alarm.tags}>
            <Alert.Message>{alarm.message}</Alert.Message>
            <Alert.CloseButton onClick={() => closeAlert(alarm.message)} />
          </Alert>
        </motion.div>))}
    </AnimatePresence>
  );
};

export default NotiStack;
