import * as React from "react";
import { createUseStyles, useTheme } from "react-jss";
import classNames from "classnames";

import { mapResponsive } from "@Utilities/utils";

export interface AspectRatioProps {
  ratio?: number;
  className?: string;
  children: React.ReactNode;
}

const useStyles = createUseStyles({
  rootAspectRatio: {
    position: "relative",
    "&:before": {
      height: 0,
      content: `""`,
      display: "block",
      paddingBottom: (props: AspectRatioProps) => mapResponsive(props.ratio, r => `${(1 / r) * 100}%`),
    },
    "& > :first-child": {
      position: "absolute",
      top: "0",
      right: "0",
      bottom: "0",
      left: "0",
      width: "100%",
    },
    "& > img": {
      height: "100%",
      objectFit: "cover",
    },
  },
});

export const AspectRatio = React.forwardRef<HTMLDivElement, AspectRatioProps>(function AspectRatio(
  { children, ratio, className, ...other },
  ref,
) {
  const classes = useStyles({ ratio } as any);
  const styledClasses = classNames(classes.rootAspectRatio, className);

  return (
    <div ref={ref} className={styledClasses} {...other}>
      {children}
    </div>
  );
});

export default AspectRatio;
