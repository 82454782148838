import { useListState } from "@mantine/hooks";

import { ReasonWithType } from "../types";

const useReason = () => {
  const [returnReasons, reasonHandlers] = useListState<ReasonWithType>([]);

  const handleReturnReasonChange = (newReason: ReasonWithType) => {
    const isReasonSelected = returnReasons.find(
      (oldReason) => oldReason.productVariantId === newReason.productVariantId,
    );

    if (isReasonSelected) {
      return reasonHandlers.filter(
        (reason) => reason.productVariantId !== newReason.productVariantId,
      );
    }

    return reasonHandlers.append(newReason);
  };

  const handleReturnReasonRemove = (productVariantId: string) => {
    return reasonHandlers.filter((reason) => reason.productVariantId !== productVariantId);
  };

  const handleReturnReasonQuantityChange = (id: string, newQuantity: number) => {
    return reasonHandlers.apply((item) => {
      if (item.productVariantId === id) {
        const newReason = item;
        newReason.quantity = newQuantity;
        return newReason;
      }
      return item;
    });
  };

  return [
    returnReasons,
    handleReturnReasonChange,
    handleReturnReasonRemove,
    handleReturnReasonQuantityChange,
  ] as const;
};

export default useReason;
