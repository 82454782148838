import { ColorBox } from "@Components/atoms";
import { capitalize } from "@Utilities/utils";
import classNames from "classnames";
import React, { memo } from "react";

import { useFilterOptionStyles as useStyles } from "./style";

export type FilterOptionType = "hex" | "button";

interface FilterOptionProps {
  columns: number;
  filterValue: string;
  handleClick: () => void;
  selected: boolean;
  type: FilterOptionType;
}

const FilterOption = memo<FilterOptionProps>(
  ({ selected, filterValue, type, columns, handleClick }) => {
    const classes = useStyles();

    const styledClasses = classNames(
      classes.buttonOption,
      selected && classes.buttonOptionSelected,
      columns === 2 ? classes.buttonTwoColumns : classes.buttonThreeColumns,
    );

    if (type === "hex")
      return <ColorBox handleClick={handleClick} selected={selected} fill={filterValue} />;
    if (type === "button")
      return (
        <div onClick={handleClick} aria-hidden="true" className={styledClasses}>
          {capitalize(filterValue)}
        </div>
      );
    return <div />;
  },
);

export default FilterOption;
