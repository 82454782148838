import { valueToArray } from "@Utilities/utilities";
import { parse } from "qs";
import { useLocation } from "react-router-dom";

export const SORT_OPTIONS = {
  popular: { attribute: "relevant", order: "desc", title: "Popular" },
  high: { attribute: "price", order: "desc", title: "Price - High" },
  low: { attribute: "price", order: "asc", title: "Price - Low" },
  latest: { attribute: "availableDate", order: "desc", title: "Latest" },
};

export type SortTypes = keyof typeof SORT_OPTIONS;

const useCategoryParams = () => {
  const location = useLocation();

  const urlQS = parse(location.search.substr(1));
  const { page: pageNumber, order, ...restOfParams } = urlQS;
  const filterParams = valueToArray(restOfParams as {});
  const sortBy = String(order);
  const softs = Object.keys(SORT_OPTIONS).includes(sortBy) ? sortBy : "popular";
  const prefabricatedParams = {
    pageNumber: Number(pageNumber),
    sortBy: softs as SortTypes,
    filterParams,
  };
  return prefabricatedParams;
};

export default useCategoryParams;
