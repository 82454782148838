import React from "react";

import Tag from "@Components/atoms/Tag";
import Typography from "@Components/atoms/Typography";

function New() {
  return (
    <Tag>
      <Typography customColor="#000" gutterBottom={false} gutterSide>
        NEW
      </Typography>
    </Tag>
  );
}

export default New;
