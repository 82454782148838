import React, { PropsWithChildren } from "react";

import {
  ActionIcon,
  AspectRatio,
  createStyles,
  Divider,
  Drawer,
  Image,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconX } from "@tabler/icons";

import { Line } from "../../types";

const useStyles = createStyles({
  imageBox: {
    width: "50%",
    minWidth: 170,
    marginRight: "auto",
    marginLeft: "auto",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
});

interface ReturnOptionsDrawerModalProps {
  disabled: boolean;
  handleClose: () => void;
  isOpen: boolean;
  variant: Line;
}

const PRODUCT_ASPECT_RATIO = 170 / 224;

function ReturnOptionsDrawerModal({
  handleClose,
  isOpen,
  variant,
  children,
  disabled,
}: PropsWithChildren<ReturnOptionsDrawerModalProps>) {
  const { classes } = useStyles();

  const targetDOM = document.getElementById("shadow")?.shadowRoot?.getElementById("return-portal");

  if (!targetDOM) {
    return console.error("Missing modal root");
  }

  return (
    <Drawer
      opened={isOpen}
      onClose={handleClose}
      target={targetDOM}
      title={
        <>
          <div className={classes.titleContainer}>
            <Title order={3} pb="md" transform="uppercase" weight="bold">
              Tell us about your item
            </Title>
            <ActionIcon pb="md" onClick={handleClose}>
              <IconX />
            </ActionIcon>
          </div>
          <Divider color="black" size="xs" />
        </>
      }
    >
      <Stack spacing="xl">
        {/* TODO: add smaller then xs for spacing */}
        <Stack spacing={5}>
          <Text transform="capitalize" size="lg">
            {variant?.productName}
          </Text>
          <Text transform="capitalize" size="sm">
            Colour: {variant?.productColor}
          </Text>
          <Text transform="capitalize" size="sm">
            Size: {variant?.productSize}
          </Text>
        </Stack>
        <AspectRatio ratio={PRODUCT_ASPECT_RATIO} className={classes.imageBox}>
          <Image src={variant?.productImageUrl} />
        </AspectRatio>
        <Text>Why do you want to return this item?</Text>
        <Stack>{children}</Stack>
      </Stack>
    </Drawer>
  );
}
export default ReturnOptionsDrawerModal;
