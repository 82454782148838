import React from "react";
import { useTheme } from "react-jss";

import { AspectRatio, Link, ProductThumbnail, Typography } from "@Components/atoms";
import Box from "@Components/atoms/Box";
import Price from "@Components/molecules/Price";
import Product from "@Components/molecules/Product";
import Status from "@Components/molecules/Status";
import { useMediaQuery } from "@Hooks/index";
import { ThemeType } from "@Theme/theme";
import { getAttraqtPrices } from "@Utilities/utils";

import { useStyles } from "./style";

interface SearchProductProps {
  handleClick?: () => void;
  product: any;
  productAspectRatio: number;
}

const defaultProps = {
  handleClick: () => undefined,
};

function SearchProduct({ product, productAspectRatio, handleClick }: SearchProductProps) {
  const [retailPrice, price] = getAttraqtPrices(product?.retailPrice, product?.price);
  const isOnSale = product?.discountedPercentage > 0;
  const isComingSoon = product?.status === "comingsoon";
  const isInStock = product?.stock !== 0;
  const isPriceVisible = isComingSoon || isInStock;
  const theme = useTheme<ThemeType>();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const productTypography = isMobile ? "12px" : "14px";
  const isNew = product?.collectionsList?.includes("new-arrivals");
  const isNewSeason = product?.collectionsList?.includes("new-season");
  return (
    <div className={classes.productBox} key={product?._id?.original_id}>
      <Link href={product?.url} onClick={handleClick}>
        <Product>
          <AspectRatio ratio={productAspectRatio}>
            <ProductThumbnail
              mainImg={product?.photo}
              coverImg={product?.secondImage}
              alt={product?.title}
              holdAspectRatio
            >
              <Status
                isOnSale={isOnSale}
                isComingSoon={isComingSoon}
                isNew={isNew}
                isNewSeason={isNewSeason}
              >
                {(statusTag) => (
                  <Box position="absolute" bottom="12px" left="12px">
                    {statusTag}{" "}
                  </Box>
                )}
              </Status>
            </ProductThumbnail>
          </AspectRatio>
          <Typography
            align="center"
            fontSize={productTypography}
            gutterSide
            data-test="product-name"
          >
            {product?.webname}
          </Typography>
          {isPriceVisible ? (
            <Typography align="center" variant="caption" fontSize={productTypography}>
              <Price retailPrice={retailPrice} price={price} />
            </Typography>
          ) : (
            <Typography
              align="center"
              component="span"
              variant="newParagraph"
              color="secondary"
              gutterBottom={false}
              data-test="out-of-stock"
              display="block"
            >
              Out of Stock
            </Typography>
          )}
        </Product>
      </Link>
    </div>
  );
}

SearchProduct.defaultProps = defaultProps;

export default SearchProduct;
