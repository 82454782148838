import React from "react";
import ContentLoader from "react-content-loader";

export const ProductLoader = () => (
  <ContentLoader
    speed={2}
    width={270}
    height={132}
    viewBox="0 0 270 132"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{
      margin: 16,
    }}
  >
    <rect x="114" y="30" rx="3" ry="3" width="166" height="10" />
    <rect x="0" y="0" rx="0" ry="0" width="100" height="132" />
    <rect x="114" y="57" rx="3" ry="3" width="104" height="10" />
    <rect x="114" y="89" rx="3" ry="3" width="104" height="10" />
  </ContentLoader>
);

export const TextSkeleton = () => (
  <ContentLoader width={96} height={12} viewBox="0 0 96 12">
    <rect x="0" y="0" rx="0" ry="0" width="96" height="12" />
  </ContentLoader>
);
