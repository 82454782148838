import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import classNames from "classnames";

import { capitalize } from "@Utilities/utils";

const useStyles = createUseStyles(theme => ({
  priceRoot: {
    display: "inline-block",
    color: theme.color.darkGray,
    marginLeft: "0.5rem",
  },
  priceDiscount: {
    color: "#ec5840",
  },
  pricePercentage: {
    color: "#ec5840",
  },
  priceCrossed: {
    textDecoration: "line-through",
  },
}));

export const PRICE_TYPE = [undefined, "discount", "percentage", "crossed"];

export const Price = ({ children, className, type }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const styledClasses = classNames(
    className,
    classes.priceRoot,
    !!type && classes["price".concat(capitalize(String(type)))],
  );

  return (
    <span className={styledClasses}>
      {children}
      {type === "percentage" && "% Off"}
    </span>
  );
};

export default Price;

Price.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  type: PropTypes.oneOf(PRICE_TYPE),
};
