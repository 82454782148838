import { parse, stringify } from "qs";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { Modal } from "@Components/atoms";
import { Filter, FilterContainer } from "@Components/organisms/Filter";
import { SortFilter } from "@Components/organisms/SortFilter";
import ListingPageTemplate from "@Components/templates/ListingPageTemplate";
import { ModalsContext } from "@Context/index";
import { useAttraqtSearch, useQueryString } from "@Hooks/index";
import { gtmEnhancedEcommerce } from "@Utilities/gtm";
import { replaceTargetQS } from "@Utilities/queryStringHelper";
import { schemaResultPage } from "@Utilities/schemaResultPage";
import { toArray } from "@Utilities/utilities";
import { getAttraqtPrices } from "@Utilities/utils";

import { SORT_OPTIONS, useSearchParams } from "./useSearchParams";

const VARIANTS_PER_PAGE = 24;

export function SearchPage() {
  const history = useHistory();
  const params = useSearchParams();
  const [updateQS] = useQueryString();
  const { openModal, closeModal, modals } = useContext(ModalsContext);

  const { searchSlug, pageNumber, sortBy, filterParams } = params;

  const currentPage = pageNumber || 1;
  const variantsOnPage = currentPage * VARIANTS_PER_PAGE;
  const activeFilters = Object.keys(params?.filterParams);
  const sortObject = SORT_OPTIONS[sortBy];

  const { response, loading: loadingAttraqt, errorResponse } = useAttraqtSearch({
    searchSlug,
    filterParams,
    sortObject,
    variantAmount: variantsOnPage,
  });
  const variantsMetadata = response?.metadata;
  const amountOfVariants = variantsMetadata?.count || 0;
  const isShowMore = variantsOnPage < amountOfVariants;
  const variants = response?.items?.slice(0, variantsOnPage);

  const filterAmount = activeFilters.length;

  const loadMoreVariants = () => {
    const query = parse(history.location.search.substr(1));
    query.page = String(currentPage + 1);
    return history.replace({
      search: stringify(query, { arrayFormat: "repeat" }),
    });
  };

  const openFilters = () => {
    openModal("Filters");
  };

  const setSortFilter = (selectedFilter: string) => {
    const orderQS = "order";
    replaceTargetQS(history, orderQS, selectedFilter);
    gtmEnhancedEcommerce(
      "custom",
      { event: "eventTracking", action: selectedFilter, category: "Filters", label: orderQS },
      "Search Page",
    );
  };

  const setFilter = (selectedFilter: string, target: string, activeOptions: string[]) => {
    gtmEnhancedEcommerce(
      "custom",
      { event: "eventTracking", action: selectedFilter, category: "Filters", label: target },
      "Search Page",
    );
    if (activeOptions.includes(selectedFilter)) {
      if (target === "mainCategory" && filterParams.subCategory) {
        updateQS("subCategory", []);
      }
      const newFilterList = activeOptions.filter(
        (activeFilters) => activeFilters !== selectedFilter,
      );
      updateQS(target, newFilterList);
    } else {
      updateQS(target, [...activeOptions, selectedFilter]);
    }
  };

  const handleProductTracking = (variant: any, i: number) => {
    const pageName = searchSlug.toUpperCase();
    const [variantPrice] = getAttraqtPrices(variant?.product?.retailPrice, variant?.product?.price);
    gtmEnhancedEcommerce(
      "impressionClick",
      [
        {
          id: variant?.id,
          name: variant?.product?.webname,
          category: variant?.product?.mainCategory,
          price: variantPrice?.toFixed(2)?.toString(),
          colour: variant?.product?.color,
          size: variant?.product?.sizes[0],
          url: variant?.product?.url,
          image: variant?.product?.photo,
          position: i,
        },
      ],
      `${pageName} SEARCH LISTING PAGE`,
    );
  };

  schemaResultPage["@type"] = "SearchResultsPage";

  return (
    <>
      <ListingPageTemplate
        variants={variants}
        isShowMore={isShowMore}
        header={searchSlug}
        loading={loadingAttraqt}
        loadMoreVariants={loadMoreVariants}
        openFilters={openFilters}
        handleProductTracking={handleProductTracking}
      />
      <Modal
        isOpen={modals.includes("Filters")}
        hide={() => closeModal("Filters")}
        animation="SlideFromRight"
        overlay
      >
        <FilterContainer
          currentProductAmount={variantsMetadata?.count}
          currentFilterAmount={filterAmount}
        >
          <SortFilter options={SORT_OPTIONS} activeOption={sortBy} setFilter={setSortFilter} />
          {variantsMetadata?.facets?.map((filter) => {
            const filterSlug = filter?.attribute;
            const filterOptions = filter?.values;
            const filterHeader = filter?.title;
            const activeOptions = filterParams[filter?.attribute]
              ? toArray(filterParams[filter?.attribute])
              : [];
            return filterSlug === "colorGroupHEX" ? (
              <Filter
                target={filterSlug}
                options={filterOptions}
                activeOptions={activeOptions}
                label={filterHeader}
                type="hex"
                setFilter={setFilter}
              />
            ) : (
              <Filter
                target={filterSlug}
                options={filterOptions}
                activeOptions={activeOptions}
                label={filterHeader}
                setFilter={setFilter}
              />
            );
          })}
        </FilterContainer>
      </Modal>
    </>
  );
}

export default SearchPage;
