/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */

import React from "react";

import IsolatedMantineProvider from "@Components/IsolatedMantineProvider";

import ErrorModalProvider from "./components/ErrorModal/ErrorModalProvider";
import { WizardProvider } from "./context/WizardContext";
import ReturnView from "./views/ReturnView";

interface ReturnPortalProps {
  isLoggedIn?: boolean;
}

function ReturnApp({ isLoggedIn = false }: ReturnPortalProps) {
  return (
    <WizardProvider numberOfSteps={4}>
      <ErrorModalProvider>
        <IsolatedMantineProvider id="return-portal">
          <ReturnView isLoggedIn={isLoggedIn} />
        </IsolatedMantineProvider>
      </ErrorModalProvider>
    </WizardProvider>
  );
}

export default ReturnApp;
