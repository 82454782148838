import { lighten } from "polished";
import { createUseStyles } from "react-jss";

export interface MenuHeaderStyleProps {
  color: string;
}

const useStyles = createUseStyles({
  menuTitle: (props: MenuHeaderStyleProps) => ({
    display: "flex",
    alignItems: "center",
    paddingRight: "3rem",
    cursor: "pointer",
    fontSize: "13px",
    color: props?.color,
    "&:hover": {
      // kill me
      color: lighten(0.15, props?.color ? props?.color : "#1a1a1a"),
      transition: "color .3s",
    },
  }),
});

export default useStyles;
