import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  carouselContainer: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  carouselNavigation: {
    [theme.breakpoints.up("md")]: {
      flex: "1 0 20%",
      maxWidth: "300px",
      minWidth: "250px",
      padding: "2rem 4rem",
    },
  },
  customCarousel: {
    "& .swiper-slide": {
      color: theme.color.inactiveGray,
      width: "auto !important",
      margin: "0 10px",
    },
    "& .swiper-slide-active": {
      color: theme.color.black,
    },
  },
  navigationListElement: {
    textTransform: "uppercase",
    marginBottom: "20px",
    fontSize: "20px",
    fontFamily: theme.typography.secondaryFontFamily,
    color: theme.color.inactiveGray,
    [theme.breakpoints.up("sm")]: {
      flex: "1 0 20%",
      paddingLeft: "0",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  elementActive: {
    color: theme.color.black,
  },
  navigationArrow: {
    width: "15px",
    height: "15px",
    top: "45%",
    color: theme.color.black,
    mixBlendMode: "difference",
    filter: "invert(1)",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "&:after": {
      fontSize: "29px",
    },
  },
  productsCarousel: {
    "& .swiper-button-prev": {
      extend: "navigationArrow",
      left: "28px",
    },
    "& .swiper-slide": {
      maxWidth: "390px",
    },
    "& .swiper-button-next": {
      extend: "navigationArrow",
      right: "28px",
    },
    "& .swiper-wrapper": {
      transitionTimingFunction: "linear !important",
    },
  },
}));

export default useStyles;
