import * as Types from '../../../../../globals/types';

import { useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from '../../../../../globals/fetcher';
export type SuggestionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SuggestionsQuery = { __typename?: 'Query', contentContainerCollection: { __typename?: 'ContentContainerCollection', items: Array<{ __typename?: 'ContentContainer', shortText: string | null, externalName: string | null, internalName: string | null, contentCollection: { __typename?: 'ContentContainerContentCollection', items: Array<{ __typename: 'Button' } | { __typename: 'InputField' } | { __typename: 'Link', url: string | null, internalName: string | null, externalName: string | null, openInNewWindow: boolean | null, follow: boolean | null, contentfulMetadata: { __typename?: 'ContentfulMetadata', tags: Array<{ __typename?: 'ContentfulTag', name: string | null, id: string | null } | null> } } | { __typename: 'Media' } | { __typename: 'Navigation' } | { __typename: 'Product' } | null> } | null } | null> } | null };


export const SuggestionsDocument = `
    query Suggestions {
  contentContainerCollection(
    where: {internalName: "Suggested Searches"}
    limit: 1
  ) {
    items {
      shortText
      externalName
      internalName
      contentCollection {
        items {
          __typename
          ... on Link {
            url
            internalName
            externalName
            openInNewWindow
            follow
            contentfulMetadata {
              tags {
                name
                id
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useSuggestionsQuery = <
      TData = SuggestionsQuery,
      TError = unknown
    >(
      variables?: SuggestionsQueryVariables,
      options?: UseQueryOptions<SuggestionsQuery, TError, TData>
    ) =>
    useQuery<SuggestionsQuery, TError, TData>(
      variables === undefined ? ['Suggestions'] : ['Suggestions', variables],
      fetcher<SuggestionsQuery, SuggestionsQueryVariables>(SuggestionsDocument, variables),
      options
    );