import React, { useState } from "react";

import Dropdown from "@Components/molecules/Dropdown";

import Link from "./components/Link";
import useStyles from "./style";

interface ShopTheLookProps {
  children: React.ReactElement | React.ReactElement[];
}

const defaultProps = {};

export function ShopTheLook({ children }: ShopTheLookProps) {
  const [isDropdownDown, setDropdown] = useState(false);
  const classes = useStyles();

  return (
    <>
      {!isDropdownDown && (
        <>
          <span className={classes.shopTheLook__icon} onClick={() => setDropdown(true)}>
            +{" "}
          </span>
          <span className={classes.shopTheLook__text} onClick={() => setDropdown(true)}>
            SHOP THE LOOK
          </span>
        </>
      )}
      <Dropdown isOverlay={false} isActive={isDropdownDown} className={classes.customDropdown}>
        {children}
      </Dropdown>
    </>
  );
}

ShopTheLook.defaultProps = defaultProps;
ShopTheLook.Link = Link;

export default ShopTheLook;
