import React, { useContext } from "react";

const AccordionContext = React.createContext(null);

export const useAccordionContext = () => {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error("Accordion Context must be used inside Accordion.");
  }
  return context;
};

export default AccordionContext;
