import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import React from "react";

import { Stack } from "@mantine/core";

import { FADE_DOWN_PROPS } from "../../animations";
import {
  useReturngMethodsQuery,
  useReturnOptionsQuery,
  useReturnOrderMutation,
} from "../../api/graphql/schema.return.generated";
import OrderLayout from "../../components/OrderLayout";
import RadioList from "../../components/RadioList/RadioList";
import ReasonPicker from "../../components/ReasonPicker";
import ReturnOptionsDrawer from "../../components/ReturnOptionsDrawer/ReturnOptionsDrawer";
import { useWizard } from "../../context/WizardContext";
import useDrawer from "../../hooks/useDrawer";
import { STALE_QUERY_OPTIONS } from "../../hooks/useLazyFormQuery";
import { generateLabelLink, getReadableDataFormat } from "../../utils";
import OrderList from "../RetunPageList/RetunPageList";
import ReturnSummary from "../ReturnPageSummary/ReturnPageSummary";
import ReturnPageThankYou, {
  PostalProviderInstructions,
  ReturnLabelButton,
  SelfReturnInstructions,
} from "../ReturnPageThankYou/ReturnPageThankYou";
import useOrderData from "../useOrder";
import useReason from "../useReason";

interface ReturnPageProps {
  data: unknown;
  dataSource: React.ReactNode;
}

function ReturnPage({ data, dataSource }: ReturnPageProps) {
  const [selectedVariantIdForDrawer, isOpenDrawer, handleOpenDrawer, handleCloseDrawer] =
    useDrawer();
  const [selectedReturnMethod, setReturnMethod] = React.useState<string>("");

  const handleReturnMethod = (returnOption: string) => {
    setReturnMethod(returnOption);
  };

  const { step, handleNextStep, handlePrevStep } = useWizard();

  const [
    returnReasons,
    handleReturnReasonChange,
    handleReturnReasonRemove,
    handleReturnReasonQuantityChange,
  ] = useReason();

  const orderData = data?.getOrder?.order;

  const { mutate: mutateOrderReturn, data: mutationReturnData } = useReturnOrderMutation({
    onSuccess: () => handleNextStep(),
  });

  const orderReturnResponse = mutationReturnData?.returnOrder?.order?.returnData;

  const { data: returnOptionsQueryResult } = useReturnOptionsQuery(undefined, {
    enabled: step === 1,
    ...STALE_QUERY_OPTIONS,
  });

  const { data: returnMethodsQueryResult } = useReturngMethodsQuery(undefined, {
    enabled: step === 1,
    ...STALE_QUERY_OPTIONS,
  });

  const returnItem = returnReasons.map((reason) => {
    return {
      productVariantId: reason.productVariantId,
      quantity: reason.quantity,
      reason: `${reason.subReason ? `${reason.reason}:${reason.subReason}` : reason.reason}`,
    };
  });

  const handleReturn = () => {
    if (orderData) {
      return mutateOrderReturn({
        email: orderData.email,
        id: orderData.id,
        returnItem,
        returnMethod: selectedReturnMethod,
      });
    }
    return console.error("You tried to use return mutation before order query");
  };

  const { lines, id, returnData, returnPossibleUntil, email } = orderData || {};
  const orderId = id || "";

  const [returnedLines, toReturnLines, productsNotSuitableForReturn, returnedProducts] =
    useOrderData(returnData || [], lines || [], returnReasons || []);

  const isAfterReturnPeriod = new Date() > new Date(returnPossibleUntil);

  const numberOfReturnDays = 30;
  const formatedReturnPossibleUntilData = getReadableDataFormat(returnPossibleUntil);

  const possibleReturnOptions = returnOptionsQueryResult?.reasonOptions;

  // To ostatniego kroku
  const packageOrderReference = orderReturnResponse?.filter(
    (el) => el.productVariantId === returnReasons?.[0].productVariantId,
  );

  const labelLink = generateLabelLink(
    orderId,
    packageOrderReference?.[0].packageReference || "",
    email,
  );

  const selectedVariant = toReturnLines.find(
    (variant) => variant.productVariant.productVariantId === selectedVariantIdForDrawer,
  );

  return (
    <AnimatePresence exitBeforeEnter>
      {step === 0 ? (
        <motion.div key="stepOne" {...FADE_DOWN_PROPS}>
          {dataSource}
        </motion.div>
      ) : null}
      {step === 1 ? (
        <motion.div key="stepTwo" {...FADE_DOWN_PROPS}>
          <OrderLayout orderId={orderId}>
            <OrderList
              handleNextStep={handleNextStep}
              handleReturnReasonRemove={handleReturnReasonRemove}
              handleReturnReasonQuantityChange={handleReturnReasonQuantityChange}
              handleOpenDrawer={handleOpenDrawer}
              returnReasons={returnReasons}
              returnedLines={returnedLines}
              toReturnLines={toReturnLines}
              isAfterReturnPeriod={isAfterReturnPeriod}
              returnPossibleUntil={formatedReturnPossibleUntilData}
              numberOfReturnDays={numberOfReturnDays}
              productsNotSuitableForReturn={productsNotSuitableForReturn}
            />
          </OrderLayout>
        </motion.div>
      ) : null}
      {step === 2 ? (
        <motion.div key="stepThree" {...FADE_DOWN_PROPS}>
          <OrderLayout orderId={orderId}>
            <ReturnSummary
              handlePrevStep={handlePrevStep}
              submitReturn={handleReturn}
              products={returnedProducts}
            >
              <RadioList
                methods={returnMethodsQueryResult?.getReturningMethods}
                setReturnMethod={handleReturnMethod}
              />
            </ReturnSummary>
          </OrderLayout>
        </motion.div>
      ) : null}
      {step === 3 ? (
        <motion.div key="stepFour" {...FADE_DOWN_PROPS}>
          <OrderLayout orderId={orderId}>
            <ReturnPageThankYou>
              {selectedReturnMethod === "Royal Mail" ? (
                <Stack align="center">
                  <ReturnLabelButton labelLink={labelLink} />
                  <PostalProviderInstructions />
                </Stack>
              ) : (
                <SelfReturnInstructions />
              )}
            </ReturnPageThankYou>
          </OrderLayout>
        </motion.div>
      ) : null}
      <ReturnOptionsDrawer
        handleClose={handleCloseDrawer}
        isOpen={isOpenDrawer}
        variant={selectedVariant?.productVariant}
        disabled={false}
      >
        <ReasonPicker
          handleReturnReasonChange={handleReturnReasonChange}
          handleClose={handleCloseDrawer}
          handleReturnReasonRemove={handleReturnReasonRemove}
          productVariantId={selectedVariant?.productVariant.productVariantId || ""}
          count={selectedVariant?.quantity || 0}
          possibleReturnOptions={possibleReturnOptions}
        />
      </ReturnOptionsDrawer>
    </AnimatePresence>
  );
}

export default ReturnPage;
