import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  imgContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      flexWrap: "no-wrap",
    },
    [theme.breakpoints.up("md")]: {
      margin: "0 0 0 -20px",
      width: "calc(100% + 20px)",
      justifyContent: "flex-start",
    },
  },
  multiColumnImg: {
    flexBasis: "100%",
    padding: "2rem 0",
    [theme.breakpoints.up("sm")]: {
      flexBasis: "49%",
    },
    [theme.breakpoints.up("md")]: {
      flexBasis: "31%",
      flexGrow: "1",
      maxWidth: "49%",
      margin: "0 0 0 20px",
    },
    [theme.breakpoints.up("lg")]: {
      flexBasis: "15%",
    },
    "& > div": {
      textAlign: "left",
    },
    "& img": {
      width: "100%",
    },
  },
  moreThanThree: {
    extend: "multiColumnImg",
    [theme.breakpoints.up("md")]: {
      maxWidth: "31%",
    },
  },
}));

export default useStyles;
