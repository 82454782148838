import React, { useRef, useEffect } from "react";
import { createUseStyles, useTheme } from "react-jss";
import ContentLoader from "react-content-loader";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useQueryString } from "@Hooks/index";

const useStyles = createUseStyles((theme) => ({
  container: {
    display: "grid",
    justifyContent: "center",
    gridTemplateColumns: "repeat(auto-fit, minmax(min(100%/3, max(42px, 100%/6)), 42px))",
    textTransform: "uppercase",
  },
  root: {
    height: "44px",
    padding: "0 4px",
    textAlign: "center",
    border: `1px solid ${theme.color.black}`,
    cursor: "pointer",
    margin: "0 -1px -1px 0",
    color: theme.color.black,
    transition: "all .2s",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    wordBreak: "break-word",
  },
  selected: {
    backgroundColor: theme.color.black,
    color: theme.color.white,
    "&$disabled": {
      backgroundColor: theme.color.darkGray,
      color: theme.color.white,
    },
  },
  disabled: {
    borderColor: theme.color.darkGray,
    backgroundColor: theme.color.lightGray,
    color: theme.color.darkGray,
  },
  loader: {
    borderColor: theme.color.darkGray,
    backgroundColor: theme.color.lightGray,
    color: theme.color.darkGray,
  },
}));

const Skeleton = () => {
  return (
    <div style={{ width: `100%`, display: "flex", justifyContent: "center", alignItems: "center" }}>
      <ContentLoader width={182} height={44} viewBox="0 0 182 44">
        <rect x="0" y="0" rx="0" ry="0" width="44" height="44" />
        <rect x="46" y="0" rx="0" ry="0" width="44" height="44" />
        <rect x="92" y="0" rx="0" ry="0" width="44" height="44" />
        <rect x="138" y="0" rx="0" ry="0" width="44" height="44" />
      </ContentLoader>
    </div>
  );
};

const Size = ({ children, selected, disabled, handleClick }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const styleClasses = classNames(
    classes.root,
    disabled && classes.disabled,
    selected && classes.selected,
  );

  return (
    <div
      className={styleClasses}
      onClick={handleClick}
      data-test={disabled ? "size-unavailable" : null}
    >
      {children}
    </div>
  );
};

export const SizePicker = ({
  loading,
  sizes,
  currentVariantID,
  setCurrentVariantID,
  outOfStock,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [updateQS] = useQueryString();

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
  }, []);

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <div className={classes.container} data-test="sizes">
          {sizes.map((option) => {
            const disabled = !option.in_stock;
            const selected = option.variant_id === currentVariantID && !firstRender.current;
            return (
              <Size
                key={option.variant_id}
                selected={selected}
                disabled={disabled || outOfStock}
                handleClick={() => {
                  setCurrentVariantID(option.variant_id);
                  updateQS("size", option.raw_value);
                }}
              >
                {option.size}
              </Size>
            );
          })}
        </div>
      )}
    </>
  );
};

SizePicker.propTypes = {
  sizes: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  setCurrentVariantID: PropTypes.func.isRequired,
  currentVariantID: PropTypes.string.isRequired,
};

Size.propTypes = {
  children: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default SizePicker;
