import PropTypes from "prop-types";
import React from "react";

import { Container, Link, Typography } from "@Components/atoms";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { gtmEnhancedEcommerce } from "@Utilities/gtm";

import { DefaultMultiColumnContentFragment } from "../../../../globals/fragments.generated";
import useStyles from "./style";

interface MultiColumnImageProps {
  data: DefaultMultiColumnContentFragment;
}

export function MultiColumnImage({ data }: MultiColumnImageProps) {
  const classes = useStyles();

  const moreThanThree = data.contentCollection.items.length > 3;

  return (
    <Container>
      <div className={classes.imgContainer}>
        {data.contentCollection.items
          .filter((item) => item !== null)
          .slice(0, 6)
          .map((img, index) => {
            const imgMedia = img.contentCollection.items.find(
              (item) => item.__typename === "Media",
            );
            return imgMedia ? (
              <div
                className={moreThanThree ? classes.moreThanThree : classes.multiColumnImg}
                key={index}
              >
                <Link
                  href={imgMedia.link ? imgMedia.link.url : "/"}
                  onClick={() =>
                    gtmEnhancedEcommerce(
                      "promotionClick",
                      [
                        {
                          id: img?.internalName,
                          name: img?.externalName,
                          creative: imgMedia?.internalName,
                          position: data?.internalName,
                        },
                      ],
                      "Homepage",
                    )
                  }
                >
                  <picture>
                    <source srcSet={imgMedia.mediaDesktop.url} type="image/webp" />
                    <img
                      style={{ marginBottom: 10 }}
                      src={imgMedia.mediaDesktop.urlJpg}
                      alt={imgMedia.altText}
                    />
                  </picture>
                </Link>
                <div className={classes.imgContent}>
                  {img?.shortText ? (
                    <Typography variant="h3" fontSize="12px" color="secondary" gutterTop>
                      {img.shortText}
                    </Typography>
                  ) : null}
                  {img?.externalName ? (
                    <Typography variant="h2" fontSize="18px">
                      {img.externalName}
                    </Typography>
                  ) : null}
                  {img?.richText ? (
                    <Typography>{documentToReactComponents(img.richText.json)}</Typography>
                  ) : null}
                </div>
              </div>
            ) : null;
          })}
      </div>
    </Container>
  );
}

MultiColumnImage.propTypes = {
  data: PropTypes.object,
};

export default MultiColumnImage;
