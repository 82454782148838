import React from "react";
import { CookiesProvider } from "react-cookie";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "react-jss";
import { QueryClient, QueryClientProvider } from "react-query";

import "babel-polyfill";
import "es6-promise/auto";
import "isomorphic-fetch";
import "../legacy/sentry";

import { SessionDataProvider } from "@Context/index";

import Routes from "./routes";
import Theme from "./theme";

const queryClient = new QueryClient();
const container = document.getElementById("app");
const root = createRoot(container!);

root.render(
  <CookiesProvider>
    <QueryClientProvider client={queryClient}>
      <SessionDataProvider>
        <ThemeProvider theme={Theme}>
          <Routes />
        </ThemeProvider>
      </SessionDataProvider>
    </QueryClientProvider>
  </CookiesProvider>,
);
