import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { motion } from "framer-motion";
import SVG from "react-inlinesvg";
import classNames from "classnames";

import { useAccordionContext } from "../AccordionContext";
import logo_raw from "../../../../../..//images/x-icon.svg";

const useStyles = createUseStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    height: "55px",
    padding: "0 28px",
    alignItems: "center",
    width: "100%",
    borderBottom: `1px solid ${theme.color.grey}`,
    cursor: "pointer",
  },
  indicator: {
    transformOrigin: "center",
  },
}));

const ToggleIndicator = ({ className, isOpen }) => (
  <motion.div
    initial="collapsed"
    animate={isOpen ? "open" : "collapsed"}
    exit="collapsed"
    variants={{
      open: { fontSize: "1rem", rotate: 0 },
      collapsed: { fontSize: "1rem", rotate: 45 },
    }}
    transition={{ duration: 0.4 }}
    className={className}
  >
    <SVG src={logo_raw} />
  </motion.div>
);

ToggleIndicator.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
};

const Toggle = ({ elementKey, onClick, children, className, ...otherProps }) => {
  const { onToggle, activeElementKey } = useAccordionContext();
  const isOpen = useMemo(() => activeElementKey.includes(elementKey), [elementKey, activeElementKey]);
  const classes = useStyles({ isOpen });

  const accordionClick = event => {
    onToggle(elementKey);

    if (onClick) {
      onClick(event);
    }
  };

  const styledClasses = classNames(classes.container, className);

  return (
    <div className={styledClasses} onClick={accordionClick} {...otherProps}>
      {children}
      <ToggleIndicator className={classes.indicator} isOpen={isOpen} />
    </div>
  );
};

Toggle.propTypes = {
  elementKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
};

export default Toggle;
