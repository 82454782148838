import { useQuickLinksQuery } from "../../CollectionPage/API/graphql/operation.generated";

interface UseQuickLinksProps {
  category: string;
}

const useQuickLinks = ({ category }: UseQuickLinksProps) =>
  useQuickLinksQuery({ category }, { refetchOnWindowFocus: false });

export default useQuickLinks;
