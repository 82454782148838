import React from "react";
import { z } from "zod";

import { useFetchOrdersQuery } from "../api/graphql/schema.return.generated";
import { openModal } from "../components/ErrorModal/events";
import { useWizard } from "../context/WizardContext";
import useLazyFormQuery from "../hooks/useLazyFormQuery";
import useLazyQuery from "../hooks/useLazyQuery";
import OrderView from "./OrderView/OrderView";
import ReturnPage from "./ReturnPage/ReturnPage";
import ReturnPageLogin from "./ReturnPageLogin/ReturnPageLogin";

export const INIT_FORM_SCHEMA = z.object({
  id: z
    .string({
      required_error: "Incorrect order number",
    })
    .min(6, { message: "Please provided order number" }),
  email: z
    .string({
      required_error: "Email is required",
    })
    .email({ message: "Incorrect email" }),
});

interface ReturnPortalProps {
  isLoggedIn: boolean;
}

export const INIT_ORDER_DATA = {
  email: "",
  id: "",
};

function ReturnView({ isLoggedIn }: ReturnPortalProps) {
  const { handleNextStep } = useWizard();

  const {
    handleForm: handleLoginForm,
    register: registerLoginInput,
    isLoading,
    data: dataNotLoggedIn,
    formErrors,
  } = useLazyFormQuery({
    useQueryHook: useFetchOrdersQuery,
    defaultValues: INIT_ORDER_DATA,
    onSuccess: () => handleNextStep(),
    onError: (e) =>
      openModal({
        title: <h4>Sorry, there was a problem.</h4>,
        children: <p>{String(e)}</p>,
        centered: true,
      }),
    formSchema: INIT_FORM_SCHEMA,
  });

  if (isLoggedIn) {
    const myAccountOrderView = $("#return-portal-order-view");

    const { queryProps: dataLoggedIn, triggerQuery } = useLazyQuery({
      useQueryHook: useFetchOrdersQuery,
      onSuccess: () => {
        myAccountOrderView.fadeOut({ complete: handleNextStep() });
      },
      onError: (e: unknown) =>
        openModal({
          title: <h4>Sorry, there was a problem.</h4>,
          children: <p>{String(e)}</p>,
          centered: true,
        }),
    });

    return (
      <ReturnPage
        data={dataLoggedIn?.data}
        dataSource={<OrderView triggerQuery={triggerQuery} />}
      />
    );
  }

  return (
    <ReturnPage
      data={dataNotLoggedIn}
      dataSource={
        <ReturnPageLogin
          handleForm={handleLoginForm}
          registerInput={registerLoginInput}
          disabled={isLoading}
          formErrors={formErrors}
        />
      }
    />
  );
}

export default ReturnView;
