import React from "react";
import classNames from "classnames";

import { useStyles } from "../style";
import CloseButton from './CloseButton'
import Message from './Message'
import { capitalize } from "@Utilities/utilities";

type AlertType = "error" | "info" | "success" | "warning"
interface AlertProps {
  children: React.ReactNode;
  className?: string;
  type?: AlertType;
}

export interface CompoundedComponent extends React.ForwardRefExoticComponent<AlertProps & React.RefAttributes<HTMLInputElement>> {
  CloseButton: React.ComponentType<any>;
  Message: React.ComponentType<any>;
}

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(({ children, className, type = "info" }, ref) => {
  const classes = useStyles();

  const styledClasses = classNames(
    className,
    classes.alarmRoot,
    //  fix types -> force const assertion types through capitalize 
    type && classes["alarm".concat(capitalize(String(type)))],
  );

  return (
    <div ref={ref} className={styledClasses}>
      {children}
    </div>
  );
}) as CompoundedComponent;

Alert.CloseButton = CloseButton;
Alert.Message = Message;

export default Alert;
