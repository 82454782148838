export const FADE_DOWN = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

export const FADE_DOWN_PROPS = {
  initial: "hidden",
  animate: "visible",
  exit: "hidden",
  variants: FADE_DOWN,
  transition: { duration: 0.3 },
};
