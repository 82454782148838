import { XORecommendationsPayload } from "@Services/attraqt";

import { ShopTheLookType } from "../types";
import { getProductImageUrl } from "../utils";

export type CarouselProduct = {
  externaLink: string;
  imageUrl: string;
  title: string;
};

export const parseContentfulToCarouselProductSchema = (
  data: ShopTheLookType[],
): CarouselProduct[] => {
  const parsedData = data.map((product) => {
    return {
      imageUrl: getProductImageUrl(product.sku),
      externaLink: product.externalLink,
      title: product.name,
    };
  });
  return parsedData;
};

export const parseXoToCarouselProductSchema = (
  data: XORecommendationsPayload["recommendations"],
): CarouselProduct[] => {
  const parsedData = data.map((recommendation) => {
    return {
      imageUrl: getProductImageUrl(recommendation.id),
      externaLink: recommendation.product.url,
      title: recommendation.product.title,
    };
  });
  return parsedData;
};

export const parseContentfulProductPickerJSON = (unparsedData: (string | null)[]) => {
  return unparsedData.map((product) => JSON.parse(product || "") as ShopTheLookType);
};

export const calculateSlideSize = (slideSize: number, fractionOfFullScreen: number) => {
  let responsiveSlideSize = slideSize / fractionOfFullScreen;
  if (responsiveSlideSize > 1) {
    responsiveSlideSize = 1;
  }
  return `${String(responsiveSlideSize * 100)}%`;
};
