import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import SVG from "react-inlinesvg";

import closeIcon from "../../../../../images/collapse_icon.svg";

const useStyles = createUseStyles(theme => ({
  root: {
    visibility: "hidden",
    position: "absolute",
    background: theme.color.white,
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      visibility: "visible",
      width: "66px",
      height: "65px",
      top: "0",
      left: "-65px",
      border: `1px solid ${theme.color.grey}`,
      borderTop: "none",
      zIndex: theme.zIndexValues.theHighest,
      "& svg": {
        height: "12px",
        width: "12px",
      },
    },
  },
}));

export const CloseButton = ({ className, onClick }) => {
  const classes = useStyles();

  const styledClasses = classNames(className, classes.root);

  return (
    <button className={styledClasses} onClick={onClick}>
      <SVG src={closeIcon} />
    </button>
  );
};

CloseButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default CloseButton;
