export const calculateProductsAndBannersPositions = (
  bannersData: {},
  matchesTablet: boolean,
  matchesDesktop: boolean,
  products: {},
  pageNumber: number,
  perPage: number,
  properCategory: string,
) => {
  const modifiedBannersData = bannersData?.listingPageBanerCollection?.items?.map(
    ({ content: items, ...rest }) => ({ items, ...rest }),
  );

  const filteredBannersData = modifiedBannersData?.filter(
    (item) =>
      item?.items?.contentCollection?.items.find((el) => el.__typename === "Media")?.mediaDesktop
        ?.url,
  );

  const itemsPerRow = matchesDesktop ? 4 : matchesTablet ? 3 : 2;
  const rows = products?.length / itemsPerRow;
  const totalRowsNumber = Number.isInteger(rows) ? Math.ceil(rows) + 1 : Math.ceil(rows);

  const bannersPositions = filteredBannersData?.map((banner) => {
    let positionRow = banner?.positionRow;
    const positionAlign = banner?.positionAlign;
    if (positionRow > totalRowsNumber) {
      positionRow = totalRowsNumber + 1;
    }

    let bannerPlacing = (positionRow - 1) * itemsPerRow;
    const onRight = positionAlign === "Right" || positionAlign === "Middle Right";
    if (matchesDesktop && onRight) {
      bannerPlacing += 2;
    } else if (matchesTablet && onRight) {
      bannerPlacing += 1;
    } else {
      bannerPlacing;
    }
    return bannerPlacing;
  });

  const bannerData = filteredBannersData?.map((banner) => {
    return banner;
  });

  const productsWithBanner = [...products];
  for (let i = 0; i < bannersPositions?.length; i++) {
    productsWithBanner.splice(bannersPositions[i], 0, bannerData[i]);
  }

  const countProductsPerPage = (pageNumber) => {
    const bannersNumberPerPage = bannersPositions?.filter((item) => item <= perPage * pageNumber)
      .length;
    return matchesTablet && !matchesDesktop
      ? pageNumber * perPage - bannersNumberPerPage * 3
      : pageNumber * perPage + bannersNumberPerPage;
  };

  let productsPerPage = countProductsPerPage(pageNumber);

  // temporary hardcoded

  if (properCategory === "pretty-green-x-john-lennon" || properCategory === "john-lennon") {
    productsPerPage = 28;
  }

  return [productsWithBanner, productsPerPage];
};
