import React, { PropsWithChildren } from "react";

import useStyles, { MenuHeaderStyleProps } from "./style";

interface MenuHeaderProps extends MenuHeaderStyleProps {
  onToggle: () => void;
}

function MenuHeader({ children, color, onToggle }: PropsWithChildren<MenuHeaderProps>) {
  const classes = useStyles({ color });
  return (
    <div className={classes.menuTitle} onMouseEnter={onToggle}>
      {children}
    </div>
  );
}

export default MenuHeader;
