import React from "react";

import Accordion from "@Components/atoms/Accordion";
import Link from "@Components/atoms/Link";
import Typography from "@Components/atoms/Typography";

import useStyle from "./style";

function StaticAccordions() {
  const classes = useStyle();
  return (
    <>
      <Link href="/profile/orders/">
        <Accordion className={classes.staticAccordion}>
          <Accordion.Toggle>
            <Typography variant="h2" fontFamily="primaryFont" fontSize="14px" gutterBottom={false}>
              My account
            </Typography>
          </Accordion.Toggle>
        </Accordion>
      </Link>
      <Link href="https://help.prettygreen.com/hc/en-us">
        <Accordion className={classes.staticAccordion}>
          <Accordion.Toggle>
            <Typography variant="h2" fontFamily="primaryFont" fontSize="14px" gutterBottom={false}>
              Contact Us
            </Typography>
          </Accordion.Toggle>
        </Accordion>
      </Link>
    </>
  );
}

export default StaticAccordions;
