import React from "react";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { Table, List } from "@mantine/core";

import { useInlineLinkQuery } from "../components/organisms/Contentful/TextBlock/graphql/operation.generated";
import { Typography } from "@Components/atoms";

export const RICHTEXT_OPTIONS = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: function RichtextTypography(node, children) {
      if (node.content.every((nodeContent) => nodeContent.value === "")) {
        return <br />;
      }
      return (
        <Typography align={node.data?.align} gutterBottom={false}>
          {children}
        </Typography>
      );
    },
    [BLOCKS.HEADING_1]: function RichtextTypography(node, children) {
      if (node.content.every((nodeContent) => nodeContent.value === "")) {
        return <br />;
      }
      return (
        <Typography align={node.data?.align} variant="h1" gutterBottom={false}>
          {children}
        </Typography>
      );
    },
    [BLOCKS.HEADING_2]: function RichtextTypography(node, children) {
      if (node.content.every((nodeContent) => nodeContent.value === "")) {
        return <br />;
      }
      return (
        <Typography align={node.data?.align} variant="h2" gutterBottom={false}>
          {children}
        </Typography>
      );
    },
    [BLOCKS.HEADING_3]: function RichtextTypography(node, children) {
      if (node.content.every((nodeContent) => nodeContent.value === "")) {
        return <br />;
      }
      return (
        <Typography align={node.data?.align} variant="h3" gutterBottom={false}>
          {children}
        </Typography>
      );
    },
    [BLOCKS.HEADING_4]: function RichtextTypography(node, children) {
      if (node.content.every((nodeContent) => nodeContent.value === "")) {
        return <br />;
      }
      return (
        <Typography align={node.data?.align} variant="h4" gutterBottom={false}>
          {children}
        </Typography>
      );
    },
    [BLOCKS.HEADING_5]: function RichtextTypography(node, children) {
      if (node.content.every((nodeContent) => nodeContent.value === "")) {
        return <br />;
      }
      return (
        <Typography align={node.data?.align} variant="h5" gutterBottom={false}>
          {children}
        </Typography>
      );
    },
    [BLOCKS.HEADING_6]: function RichtextTypography(node, children) {
      if (node.content.every((nodeContent) => nodeContent.value === "")) {
        return <br />;
      }
      return (
        <Typography align={node.data?.align} variant="h6" gutterBottom={false}>
          {children}
        </Typography>
      );
    },
    [BLOCKS.HR]: function RichtextTypography() {
      return <hr />;
    },
    [BLOCKS.LIST_ITEM]: function RichtextTypography(node, children) {
      return <List.Item>{children}</List.Item>;
    },
    [BLOCKS.OL_LIST]: function RichtextTypography(node, children) {
      return (
        <List
          size="xs"
          type="ordered"
          styles={{
            root: {
              listStyleType: "decimal",
              ol: {
                listStyleType: "upper-alpha",
                ol: {
                  listStyleType: "lower-roman",
                  ol: {
                    listStyleType: "lower-alpha",
                  },
                },
              },
            },
          }}
        >
          {children}
        </List>
      );
    },
    [BLOCKS.UL_LIST]: function RichtextTypography(node, children) {
      return (
        <List
          size="xs"
          styles={{
            root: {
              listStyleType: "disc",
              ul: {
                listStyleType: "circle",
                ul: {
                  listStyleType: "square",
                },
              },
            },
          }}
          type="unordered"
        >
          {children}
        </List>
      );
    },
    [BLOCKS.QUOTE]: function RichtextTypography(node, children) {
      return <blockquote>{children}</blockquote>;
    },
    [BLOCKS.TABLE]: function RichtextTypography(node, children) {
      return (
        <Table striped withBorder withColumnBorders fontSize="xs">
          <tbody>{children}</tbody>
        </Table>
      );
    },
    [BLOCKS.TABLE_CELL]: function RichtextTypography(node, children) {
      return <td>{children}</td>;
    },
    [BLOCKS.TABLE_HEADER_CELL]: function RichtextTypography(node, children) {
      return <th>{children}</th>;
    },
    [BLOCKS.TABLE_ROW]: function RichtextTypography(node, children) {
      return <tr>{children}</tr>;
    },
    [INLINES.ENTRY_HYPERLINK]: function RichtextTypography(node, children) {
      const entryId = node.data.target.sys.id;

      const { data, isSuccess } = useInlineLinkQuery({ linkId: entryId });
      if (isSuccess) {
        return (
          <a
            target={data.link.openInNewWindow ? "_blank" : "_self"}
            href={data.link.url}
            rel="noreferrer"
          >
            {children}
          </a>
        );
      }
      return "";
    },
    [INLINES.HYPERLINK]: function RichtextTypography(node, children) {
      return (
        <a target="_blank" href={node.data.uri} rel="noreferrer">
          {children}
        </a>
      );
    },
  },
  renderMark: {
    [MARKS.BOLD]: function RichtextTypography(text) {
      return <b>{text}</b>;
    },
    [MARKS.CODE]: function RichtextTypography(text) {
      return <code>{text}</code>;
    },
    [MARKS.ITALIC]: function RichtextTypography(text) {
      return <i>{text}</i>;
    },
    [MARKS.UNDERLINE]: function RichtextTypography(text) {
      return <u>{text}</u>;
    },
  },
};
