import React from "react";

import { Anchor, Button, Container, createStyles, Divider, List, Stack, Text } from "@mantine/core";
import { IconCircle, IconCircleCheck } from "@tabler/icons";

const useStyles = createStyles({
  svgIcon: {
    fill: "white",
  },
});

export function PostalProviderInstructions() {
  return (
    <Stack spacing="lg">
      <Text align="center" fw={700} size="xl">
        Return instructions
      </Text>
      <Divider color="black" />
      <List size="sm" spacing="xl" center icon={<IconCircle height={15} width={15} />}>
        <List.Item>
          Complete the returns note and place it in the package with your items. Use the adhesive
          strips to seal the package.
        </List.Item>
        <List.Item>
          You can print the label or scan the QR code in your confirmation email at the post office.
        </List.Item>
        <List.Item>
          If you have printed your label, affix it to the box over any existing labels.
        </List.Item>
        <List.Item>
          Drop your package off at your nearest post office. If you opted to have the labels printed
          there, show them the email with the QR code.
        </List.Item>
        <List.Item>
          Pretty Green also offer Royal Mails collection service. If you would like to arrange for
          your parcel to be collected, please{" "}
          <Anchor target="blank" href="https://send.royalmail.com/collect" underline inherit>
            book it here
          </Anchor>
          . Please note, this service requires the label to be printed.
        </List.Item>
      </List>
    </Stack>
  );
}

export function SelfReturnInstructions() {
  return (
    <Stack spacing="lg">
      <Text align="center" fw={700} size="xl">
        Return instructions
      </Text>
      <Divider color="black" />
      <List
        size="sm"
        spacing="xl"
        center
        icon={<IconCircle color="black" fill="black" height={15} width={15} />}
      >
        <List.Item>
          Complete the returns note and place it in the package with your items. Use the adhesive
          strips to seal the package.
        </List.Item>
        <List.Item>
          Please return the parcel to us using your chosen method: Pretty Green Bay 12 Warehouse 39
          Barlby Road Selby YO8 5BY
        </List.Item>
      </List>
      <Divider color="black" />
    </Stack>
  );
}

export function ReturnLabelButton({ labelLink }: { labelLink: string }) {
  return (
    <Button component="a" href={labelLink} variant="outline">
      Download Return Label
    </Button>
  );
}

function ThankYou({ children = null }: React.PropsWithChildren<ThankYouProps>) {
  const classes = useStyles();

  return (
    <Container pb="xl" size="sm">
      <Stack pb="xl">
        <Text align="center">
          <IconCircleCheck className={classes.svgIcon} height={92} width={92} stroke={1} />
        </Text>
        <Text size="lg" align="center">
          You are all set!
        </Text>
        <Text size="sm" align="center">
          A confirmation email has been sent to your inbox.{" "}
        </Text>
        <Text size="sm" align="center">
          Please ensure the return item(s) are in the same condition in which you received them with
          their original tags and packaging.
        </Text>
      </Stack>
      {children}
    </Container>
  );
}

export default ThankYou;
