import React, { PropsWithChildren } from "react";

import useStyles, { GridItemStylingProps } from "./style";

function Item({
  children,
  columnLength,
  columnStart,
  rowLength,
  rowStart,
  gutter,
}: PropsWithChildren<GridItemStylingProps>) {
  const classes = useStyles({ columnLength, columnStart, rowLength, rowStart, gutter });

  return <div className={classes.gridItem}>{children}</div>;
}

export default Item;
