import React from "react";
import { useTheme } from "react-jss";

import { Button, Container, Link, Typography } from "@Components/atoms";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useMediaQuery } from "@Hooks/index";
import { ThemeType } from "@Theme/theme";
import { gtmEnhancedEcommerce } from "@Utilities/gtm";

import { DefaultImageWithTextFragment } from "../../../../globals/fragments.generated";
import { goToLink } from "../utils";
import useStyles from "./style";

interface SideTextImageProps {
  data: DefaultImageWithTextFragment;
}

export function SideTextImage({ data }: SideTextImageProps) {
  const theme = useTheme<ThemeType>();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const sideImgText = data.content;
  const sideImgContent = sideImgText.contentCollection.items.filter((item) => item !== null);
  const sideImgMedia = sideImgContent.find((item) => item.__typename === "Media");
  const sideImgButton = sideImgContent.find((item) => item.__typename === "Button");
  const isLeft = data.imagePosition === true;

  const handleTracking = () => {
    gtmEnhancedEcommerce(
      "promotionClick",
      [
        {
          id: data?.content?.internalName,
          name: data?.content?.externalName,
          creative: sideImgMedia?.internalName,
          position: data?.internalName,
        },
      ],
      "Homepage",
    );
  };

  return sideImgMedia ? (
    <Container
      className={isLeft ? classes.imgContainer : classes.rightSideImg}
      bottomGutters
      topGutters
    >
      <Link
        href={sideImgMedia.link ? sideImgMedia.link.url : "/"}
        className={classes.sideImg}
        onClick={handleTracking}
      >
        <picture>
          <source srcSet={sideImgMedia.mediaDesktop.url} type="image/webp" />
          <img src={sideImgMedia.mediaDesktop.urlJpg} alt={sideImgMedia.altText} />
        </picture>
      </Link>
      <div className={classes.sideImgContentContainer}>
        <div className={classes.sideImgContent}>
          {sideImgText?.shortText ? (
            <Typography variant="h3" fontSize="12px" textTransform="uppercase" color="secondary">
              {sideImgText.shortText}
            </Typography>
          ) : null}
          {sideImgText?.externalName ? (
            <Typography variant="h2" fontSize="18px" align="center">
              {sideImgText.externalName}
            </Typography>
          ) : null}
          {sideImgText?.richText ? (
            <Typography align="center">
              {documentToReactComponents(sideImgText.richText.json)}
            </Typography>
          ) : null}
          {sideImgButton?.externalName ? (
            <Button
              color="secondary"
              fontSize={matches ? "14px" : "12px"}
              withMargin="20px"
              onClick={() => goToLink(sideImgButton.link ? sideImgButton.link.url : "/")}
            >
              {sideImgButton.externalName}
            </Button>
          ) : null}
        </div>
      </div>
    </Container>
  ) : null;
}

export default SideTextImage;
