import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { compose, space } from "styled-system";
import SVG from "react-inlinesvg";
import classNames from "classnames";

import closeIcon from "@Images/close_icon.svg";

interface CheckboxProps {
  name: string;
  label?: string;
  error: boolean;
  errorText?: string;
  checked: boolean;
}

const useStyles = createUseStyles(theme => ({
  system: compose(space),
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  label: {
    display: "inline-block",
    padding: "0 10px",
    fontSize: "8px",
    lineHeight: "1.3",
    color: theme.color.black,
  },
  checkbox: {
    display: "none",
  },
  fakeCheckbox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    minWidth: "50px",
    height: "50px",
    backgroundColor: theme.color.white,
  },
  closeIcon: {
    stroke: (props: CheckboxProps) => (props.checked ? theme.color.black : theme.color.lightGray),
  },
  error: {
    border: (props: CheckboxProps) => (!props.checked ? `1px solid ${theme.palette.error.main}` : null),
  },
  errorText: {
    color: theme.palette.error.main,
    height: "17px",
    marginBottom: 0,
    visibility: (props: CheckboxProps) => (props.error ? "visible" : "hidden"),
  },
}));

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  { name, label, error, errorText },
  ref,
) {
  const [checked, setChecked] = useState(false);

  const classes = useStyles({ checked, error });

  const styledClasses = classNames(classes.fakeCheckbox, error && classes.error);

  const Label = () => {
    return label ? <label className={classes.label}>{label}</label> : null;
  };

  const Error = ({ children = "" }) => {
    return !checked && <p className={classes.errorText}>{children}</p>;
  };

  return (
    <>
      <div onClick={() => setChecked(!checked)} className={classes.checkboxContainer}>
        <input name={name} checked={checked} type="checkbox" ref={ref} className={classes.checkbox} />
        <span className={styledClasses}>
          <SVG className={classes.closeIcon} src={closeIcon} />
        </span>
        <Label />
      </div>
      <Error>{errorText}</Error>
    </>
  );
});

export default Checkbox;
