import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles(theme => {

  const quickLink = {
    color: `${theme.color.darkGray} !important`,
    fontSize: "13px",
    [theme.breakpoints.up("sm")]: {
      padding: "0 17px",
    },
  }
  return ({
    quickLinksContainer: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "nowrap",
      paddingTop: "24px",
      "& > *": {
        ...quickLink
      }
    },
    customCarousel: {
      paddingTop: "16px",
      textAlign: "center",
      "& .swiper-wrapper": {
        display: "inline-flex",
        width: "auto",
      },
      "& .swiper-slide": {
        display: "inline-block",
        width: "auto",
        padding: "0 17px",
      },
      "& > *": {
        ...quickLink
      }
    },
  })
});

export default useStyles;