import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles(theme => ({
    listHeader: {
      margin: `${theme.spacing(3)} 0`,
      "& > span": {
        flex: 1,
        display: "flex",
        justifyContent: "center",
      },
    },
    modalButton: {
      fontSize: "14px",
      width: "100%",
      position: "absolute",
      background: theme.color.white,
      bottom: 0,
      padding: "20px 28px",
    },
    listingHeader: {
      letterSpacing: "1px",
      fontSize: "18px",
    },
    buttonContainer: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    headerDivider: {
      marginBottom: theme.spacing(1),
    },
    productListContainer: {
      textAlign: "center",
      marginLeft: "-2px",
      width: "calc(100% + 4px)",
      marginBottom: theme.spacing(2),
      display: "grid",
      gridAutoFlow: "dense",
      gridTemplateColumns: "repeat(2, 1fr)",
      [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "repeat(3, 1fr)",
        width: "calc(100% + 8px)",
        marginLeft: "-4px",
      },
      [theme.breakpoints.up("md")]: {
        gridTemplateColumns: "repeat(4, 1fr)",
        width: "calc(100% + 8px)",
        marginLeft: "-4px",
      },
    },
    productImage: {
      marginRight: "auto",
      marginLeft: "auto",
      marginBottom: "11px",
      width: "calc(100% - 5px)",
      [theme.breakpoints.up("sm")]: {
        width: "calc(100% - 10px)",
      },
    },
    loader: {
      marginBottom: "11px",
      width: "98%",
      marginRight: "auto",
      marginLeft: "auto",
      "& > svg": {
        marginRight: "auto",
        marginLeft: "auto",
        width: "calc(100% - 22px)",
      },
    },
  }));