import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  modal: {
    display: "flex",
    flexDirection: "column",
    width: "375px",
    height: "100%",
    background: "white",
    minHeight: "-webkit-fill-available", // fixes bottom bar covering content on iOS devices
    color: theme.color.black,
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      overflow: "auto",
    },
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "65px",
    width: "100%",
    borderBottom: `1px solid ${theme.color.lightGray} !important`,
  },
  modalContent: {
    flex: 10,
    width: "100%",
    height: "100%",
    overflow: "scroll",
    scrollbarWidth: "none", // Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Chrome, Safari, Opera
    },
    "&:-ms-overflow-style": "none", // IE, Edge
  },
  modalNavigation: {
    width: "100%",
  },
}));

export default useStyles;
