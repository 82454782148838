import React from "react";

import { Button, Container, Divider, Grid, Group, Stack, Text } from "@mantine/core";

import Order from "../../components/Order/Order";
import { ReturnReference } from "../../types";
import { addCurrencyToPrice } from "../../utils";

interface ReturnSummaryProps {
  handlePrevStep: () => void;
  products: ReturnReference[];
  submitReturn: () => void;
}

function ReturnSummary({
  products,
  submitReturn,
  handlePrevStep,
  children,
}: React.PropsWithChildren<ReturnSummaryProps>) {
  const totalAmount = addCurrencyToPrice(
    products.reduce((prev, curr) => {
      return prev + curr.productVariant.price * curr.quantity;
    }, 0),
  );

  if (products.length === 0) {
    return <p>You did not selected any products</p>;
  }

  return (
    <Container pb="xl" size="sm">
      <Stack>
        <Stack pb="xl">
          <Text align="center" size="sm">
            Review your return
          </Text>
          <Text align="center" size="sm">
            You will be able to download your prepared shipping label once your return is submitted
          </Text>
        </Stack>
        <Grid pb="xl">
          <Grid.Col span={12} mb="xl">
            <Stack>
              <div>
                <Text size="lg" fw={700}>
                  Return summary
                </Text>
                <Divider color="black" mb="md" />
              </div>
              <Grid.Col offset={1} span={10}>
                <Stack>
                  {products.map((product) => {
                    const { quantity, reason } = product;
                    return (
                      <Order
                        media={<Order.Photo />}
                        order={product.productVariant}
                        reason={{ quantity, reason }}
                      >
                        <Stack spacing={5} justify="flex-start">
                          <Order.Title />
                          <Order.Color />
                          <Order.Size />
                          <Order.Quantity />
                          <Order.Price />
                        </Stack>
                        <Order.Reason />
                      </Order>
                    );
                  })}
                </Stack>
              </Grid.Col>
              <Divider color="black" />
              <Text fz="xl">Total: {totalAmount}</Text>
              <Divider color="black" />
              {children}
            </Stack>
          </Grid.Col>
        </Grid>
        <Group grow>
          <Button fullWidth onClick={handlePrevStep} variant="outline" uppercase>
            Previous
          </Button>
          <Button fullWidth onClick={submitReturn} variant="outline" uppercase>
            Submit
          </Button>
        </Group>
      </Stack>
    </Container>
  );
}
export default ReturnSummary;
