import { createUseStyles } from "react-jss";

export interface CustomGridStyleProps {
  columns: number;
  gridGap: number;
  isFullWidth?: boolean;
}

const useStyles = createUseStyles(() => {
  return {
    customGrid: {
      maxWidth: (props: CustomGridStyleProps) => (props.isFullWidth ? "unset" : "1440px"),
      marginRight: "auto",
      marginLeft: "auto",
      gap: (props: CustomGridStyleProps) => Number(props.gridGap),
      display: "grid",
      gridTemplateColumns: (props: CustomGridStyleProps) => `repeat(${props.columns}, 1fr)`,
    },
  };
});

export default useStyles;
