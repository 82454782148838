import React from "react";
import { createUseStyles, useTheme } from "react-jss";

import { Container } from "@Components/atoms";
import { Newsletter } from "@Components/molecules";
import { useMediaQuery } from "@Hooks/index";
import desktopBackgroundWebp from "@Images/sign-up-desktop.webp";
import mobileBackground from "@Images/sign-up-mobile.jpg";
import mobileBackgroundWebp from "@Images/sign-up-mobile.webp";

const useStyles = createUseStyles((theme) => ({
  containerBackground: {
    "& img": {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
  },
  container: {
    position: "relative",
    height: "calc(100vh - 65px)",
  },
  newsletterContainer: {
    width: "100%",
    padding: "18px 0 0 0 ",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.color.lightGray,
    [theme.breakpoints.up("sm")]: {
      width: "635px",
    },
  },
}));

const NEWSLETTER_TITLE = "Backstage Pass";
const NEWSLETTER_SUBTITLE =
  "Sign up for exclusive content, playlists, new arrivals, collaborations and more...";
const POLICY_MESSAGE = (
  <span>
    By subscribing you agree to our{" "}
    <a href="https://www.prettygreen.com/terms/">Terms & Conditions</a> and{" "}
    <a href="https://www.prettygreen.com/privacy/ ">Privacy Policy</a>.
  </span>
);

function NewsletterSignIn() {
  const theme = useTheme() as any;
  const extendedTheme = { theme };
  const classes = useStyles(extendedTheme);
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Container className={classes.container} disableGutters>
      <picture className={classes.containerBackground}>
        <source
          srcSet={isMobile ? mobileBackgroundWebp : desktopBackgroundWebp}
          type="image/webp"
        />
        <img src={mobileBackground} alt="newsletter" />
      </picture>
      <div className={classes.newsletterContainer}>
        <Newsletter
          newsletterTitle={NEWSLETTER_TITLE}
          newsletterSubtitle={NEWSLETTER_SUBTITLE}
          policyMessage={POLICY_MESSAGE as JSX.Element}
        />
      </div>
    </Container>
  );
}

export default NewsletterSignIn;
