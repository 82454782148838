interface ConditionalWrapperProps {
  children: React.ReactElement;
  condition: boolean;
  wrapper: (children: React.ReactElement) => React.ReactElement;
}

export const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps) =>
  condition ? wrapper(children) : children;

export const getThumbnailsSizeFromOriginalUrl = (url: string, size: string, quality = 70) =>
  url
    ?.replace("media/products", "media/__sized__/products")
    .replace(".jpg", `-thumbnail-${size}-${quality}.jpg`);

export const toArray = (value: (string | number | null)[]) => {
  if (Array.isArray(value)) {
    return value;
  }
  return [value];
};

type stringObject = {
  [key: string]: (string | number | null)[];
};

type stringObjecta = {
  [key: string]: (string | number | null)[];
};

export const valueToArray = (obj: stringObject): stringObject => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      delete obj.key;
    } else {
      obj[key] = toArray(obj[key]);
    }
  });
  return obj;
};
/*
  Capitalize, string/array of strings
*/

export const capitalize = <T extends string[] | string>(
  slug: T,
): T extends string ? string : string[] => {
  if (Array.isArray(slug)) {
    const capitalizeArray = slug.map((el) => el.charAt(0).toUpperCase() + el.slice(1));
    return capitalizeArray as any;
  }
  return (slug.charAt(0).toUpperCase() + slug.slice(1)) as any;
};
