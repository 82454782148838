import { createUseStyles, useTheme } from "react-jss";

export const useStyles = createUseStyles(theme => ({
    buttonOption: {
      padding: "11px 0",
      border: "1px solid black",
      textAlign: "center",
      margin: "4px",
      cursor: "pointer",
      flex: "1 0 50%",
      maxWidth: "calc(50% - 8px)",
    },
    buttonOptionSelected: {
      backgroundColor: theme.color.black,
      color: theme.color.white,
    },
    customHeader: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: "18px",
      paddingBottom: "18px",
      width: "calc(100% - 30px)",
    },
    customToggle: {
      padding: "0 20px",
    },
    customCollapse: {
      padding: "10px 20px",
    },
  }));
