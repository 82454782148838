import React from "react";
import PropTypes from "prop-types";

import { Basket } from "@Components/atoms/Icons";
import { Link } from "@Components/atoms";

export const BasketModule = ({ cartCounter, ...other }) => {
  // ToDo: add autocomplete
  // const { register, watch } = useForm();
  // const searchSlug = watch('search');

  // const escPress = useKeyPress('Escape');
  // const enterPress = useKeyPress('Enter');

  // const { loading, error, data } = useQuery(SEARCH, { variables: { slug: searchSlug } });

  // useEffect(() => {
  //   isSearchOpen && toggleOpen();
  // }, [escPress]);

  // useEffect(() => {
  //   enterPress && (window.location.href = `/search/?q=${searchSlug}`);
  // }, [enterPress]);

  return (
    <Link href="/bag/">
      <Basket productAmount={cartCounter} {...other} />
    </Link>
  );
};

BasketModule.propTypes = {
  cartCounter: PropTypes.number.isRequired,
};

export default BasketModule;
