import { parse, stringify } from "qs";

export const checkIfInStock = (product, activeSize) => {
  let isInStock;
  if (product) {
    product.sizes.filter(obj => {
      if (obj.raw_value == activeSize) {
        isInStock = obj.in_stock;
      }
    });
    return !!isInStock;
  }
  return false;
};

export const getSKU = (id, color) => {
  if (id && color) {
    const sku = `${id}-${color}`.toUpperCase();
    return sku;
  }
};

export const getFullSKU = (id, color, size) => {
  if (id && color && size) {
    const sku = `${id}-${color}-${size}`.toUpperCase();
    return sku;
  }
};

export const getParams = location => {
  const getLastNumberRegEx = /(\d+)(?!.*\d)/;
  const query = parse(location.search.substr(1));
  return {
    id: getLastNumberRegEx.exec(location.pathname)[0],
    size: query.size === undefined ? [] : [].concat(query.size)[0],
    color: query.color === undefined ? [] : [].concat(query.color)[0],
  };
};

export const addQS = (history, target, slug) => {
  const query = parse(history.location.search.substr(1));
  let baseQuery = {
    color: [].concat(query.color),
    size: [].concat(query.size),
  };
  switch (target) {
    case "size":
      baseQuery.size = slug;
      break;
    case "colour":
      baseQuery.color = slug;
      break;
  }
  return history.replace({
    search: stringify(baseQuery, { arrayFormat: "repeat" }),
  });
};
