import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  root: (props: ButtonProps) => ({
    fontSize: props.fontSize,
    position: "relative",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(props.size),
  }),
  fluid: {
    flex: 1,
    width: "100%",
    padding: (props: ButtonProps) => `${theme.spacing(props.size)} 0`,
  },
  primary: {
    border: `1px solid ${theme.color.black}`,
    backgroundColor: theme.color.black,
    color: theme.color.white,
  },
  secondary: {
    border: `1px solid ${theme.color.black}`,
    backgroundColor: theme.color.white,
    color: theme.color.black,
  },
  tertiary: {
    border: `1px solid ${theme.color.black}`,
    backgroundColor: theme.color.lightGray,
    color: theme.color.black,
  },
  quaternary: {
    border: `1px solid ${theme.color.white}`,
    backgroundColor: "transparent",
    color: theme.color.white,
  },
  disabled: {
    opacity: 0.6,
    cursor: "not-allowed",
  },
  loader: {
    position: "absolute",
    height: "40%",
  },
  content: {
    visibility: (props: ButtonProps) => (props.loading ? "hidden" : "visible"),
    margin: (props: ButtonProps) => `0 ${props.withMargin}`,
    lineHeight: 0,
  },
  iconWrapper: {
    "& svg": {
      marginRight: theme.spacing(0.5),
      width: "15px",
      height: "15px",
    },
  },
}));

export default useStyles;
