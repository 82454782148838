import React from "react";
import { motion } from "framer-motion";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(theme => ({
  arrow: {
    position: "absolute",
    bottom: "73px",
    left: "49.5%",
    transform: "translateX(-50%)",
    display: "inline-block",
    width: "15px",
    height: "15px",
    borderTop: `1px solid ${theme.color.black}`,
    borderRight: `1px solid ${theme.color.black}`,
    mixBlendMode: "difference",
    filter: "invert(1)",
  },
}));

export const BannerArrow = () => {
  const classes = useStyles();

  return (
    <motion.div
      initial={{ rotate: 135 }}
      className={classes.arrow}
      animate={{ y: 44, opacity: ["0", "1", "0"] }}
      transition={{ ease: "easeOut", repeat: Infinity, duration: 1.5 }}
    ></motion.div>
  );
};

export default BannerArrow;
