import { createContext } from "react";

export type SessionAlertMessage = {
  message: string;
  tags: "error" | "info" | "success" | "warning";
};

// fix types for sessionData
// saleor/static/storefront/legacy/components/sessiondata.js remove nulls
export interface SessionDataType {
  cart_counter: number;
  country: "GDP";
  country_flag: "GDP";
  csrf_token: null | string;
  currency: "GDP";
  currency_display: boolean;
  enable_ad_tracking: null | string;
  enable_performance_tracking: null | boolean;
  hashed_email: string;
  is_authenticated: boolean;
  is_staff: null | boolean;
  loaded: boolean;
  messages: SessionAlertMessage[];
  tracking_acceptance_date: null | string;
  user_email: null | string;
  user_id: string;
}

export const INIT_DATA: SessionDataType = {
  cart_counter: 0,
  messages: [],
  is_authenticated: false,
  is_staff: null,
  currency: "GDP",
  currency_display: true,
  country_flag: "GDP",
  country: "GDP",
  user_id: "",
  user_email: null,
  csrf_token: null,
  enable_performance_tracking: null,
  enable_ad_tracking: null,
  tracking_acceptance_date: null,
  hashed_email: "",
  loaded: false,
};

type SessionDataContextType = [
  SessionDataType,
  React.Dispatch<React.SetStateAction<SessionDataType>>,
];

export const sessionData = createContext<SessionDataContextType>([INIT_DATA, () => undefined]);

export default sessionData;
