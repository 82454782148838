import * as Types from '../../../../../globals/types';

import { useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from '../../../../../globals/fetcher';
export type FooterQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FooterQuery = { __typename?: 'Query', contentContainerCollection: { __typename?: 'ContentContainerCollection', items: Array<{ __typename?: 'ContentContainer', internalName: string | null, externalName: string | null, richText: { __typename?: 'ContentContainerRichText', json: any } | null, contentCollection: { __typename?: 'ContentContainerContentCollection', items: Array<{ __typename: 'Button', externalName: string | null } | { __typename: 'InputField', placeholderText: string | null, description: { __typename?: 'InputFieldDescription', json: any } | null } | { __typename: 'Link' } | { __typename: 'Media', externalName: string | null, altText: string | null, mediaDesktop: { __typename?: 'Asset', url: string | null } | null, link: { __typename?: 'Link', url: string | null } | null } | { __typename: 'Navigation', internalName: string | null, navigationItemsCollection: { __typename?: 'NavigationNavigationItemsCollection', items: Array<{ __typename?: 'NavigationItem', externalName: string | null, navigationLinksCollection: { __typename?: 'NavigationItemNavigationLinksCollection', items: Array<{ __typename?: 'Link', externalName: string | null, url: string | null } | null> } | null } | null> } | null } | { __typename: 'Product' } | null> } | null } | null> } | null };


export const FooterDocument = `
    query Footer {
  contentContainerCollection(
    where: {OR: [{internalName: "Footer"}, {internalName: "Newsletter Subscription"}]}
    limit: 2
  ) {
    items {
      internalName
      externalName
      richText {
        json
      }
      contentCollection(limit: 7) {
        items {
          __typename
          ... on Navigation {
            internalName
            navigationItemsCollection(limit: 7) {
              items {
                externalName
                navigationLinksCollection {
                  items {
                    externalName
                    url
                  }
                }
              }
            }
          }
          ... on InputField {
            placeholderText
            description {
              json
            }
          }
          ... on Button {
            externalName
          }
          ... on Media {
            externalName
            altText
            mediaDesktop {
              url
            }
            link {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export const useFooterQuery = <
      TData = FooterQuery,
      TError = unknown
    >(
      variables?: FooterQueryVariables,
      options?: UseQueryOptions<FooterQuery, TError, TData>
    ) =>
    useQuery<FooterQuery, TError, TData>(
      variables === undefined ? ['Footer'] : ['Footer', variables],
      fetcher<FooterQuery, FooterQueryVariables>(FooterDocument, variables),
      options
    );