import { createContext, ReactNode } from "react";

import { ModalProps } from "@mantine/core";

export type ModalSettings = Partial<Omit<ModalProps, "opened">> & { modalId?: string };
export interface OpenConfirmModal extends ModalSettings {}
export type ConfirmLabels = Record<"confirm" | "cancel", ReactNode>;
export interface OpenContextModal<CustomProps extends Record<string, any> = {}>
  extends ModalSettings {
  innerProps: CustomProps;
}

export type ModalState =
  | { id: string; props: ModalSettings; type: "content" }
  | { id: string; props: OpenConfirmModal; type: "confirm" }
  | { ctx: string; id: string; props: OpenContextModal; type: "context" };

export interface ModalsContextProps {
  // closeAll: () => void;
  // closeContextModal: <TKey extends MantineModal>(id: TKey, canceled?: boolean) => void;
  closeModal: (id: string, canceled?: boolean) => void;
  // modals: ModalState[];
  // openConfirmModal: (props: OpenConfirmModal) => string;
  // openContextModal: <TKey extends MantineModal>(
  //   modal: TKey,
  //   props: OpenContextModal<Parameters<MantineModals[TKey]>[0]["innerProps"]>,
  // ) => string;
  openModal: (props: ModalSettings) => string;
}

export default createContext<ModalsContextProps | null>(null);
