import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  videoBlock: {
    height: "100%",
    width: "100%",
  },
  videoPlayer: {
    "& video": {
      objectFit: "cover",
      display: "block",
    },
  },
});

export default useStyles;
