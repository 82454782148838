import { useEffect } from "react";

export const useClickOutside = (ref, callback) => {
  useEffect(() => {
    const handleEscape = event => {
      if (event.key === "Escape") {
        callback();
      }
    };

    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        event.stopPropagation();
        return;
      }

      callback();
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    window.addEventListener("keydown", handleEscape);
    window.addEventListener("keyup", handleEscape);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
      window.removeEventListener("keydown", handleEscape);
      window.removeEventListener("keyup", handleEscape);
    };
  }, [ref, callback]);
};

export default useClickOutside;
