import React from "react";
import PropTypes from "prop-types";
import ContentLoader from "react-content-loader";

const TextSkeleton = ({ style, height = 25 }) => {
  return (
    <ContentLoader width={160} height={height} style={style}>
      <rect x="0" y="-20" rx="0" ry="0" width="160" height={height} />
    </ContentLoader>
  );
};

TextSkeleton.propTypes = {
  style: PropTypes.object,
  height: PropTypes.number,
};
export default TextSkeleton;
