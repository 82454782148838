import React from "react";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  styledIcon: {
    fill: "none",
    stroke: (props) => props.color,
    strokeLinecap: "round",
    strokeLinejoin: "round",
  },
  icon: {
    cursor: "pointer",
    marginLeft: "2rem",
    [theme.breakpoints.down("sm")]: {
      transform: "scale(1.2)",
    },
  },
}));

const Search = ({ handleClick, color }) => {
  const theme = useTheme();
  const classes = useStyles({ theme, color });

  return (
    <svg onClick={handleClick} className={classes.icon} width="18" height="18" viewBox="0 0 18 18">
      <g transform="translate(0.5 0.896)">
        <ellipse
          className={classes.styledIcon}
          cx="7"
          cy="6.802"
          rx="7"
          ry="6.802"
          transform="translate(0 -0.396)"
        />
        <line
          className={classes.styledIcon}
          x1="4.35"
          y1="4.35"
          transform="translate(11.65 11.605)"
        />
      </g>
    </svg>
  );
};

export default Search;
