import { useLocation } from "react-router-dom";
import { valueToArray } from "@Utilities/utilities";
import { parse } from 'qs';

export const SORT_OPTIONS = {
  popular: { attribute: "relevant", order: "desc", title: "Popular" },
  high: { attribute: "price", order: "desc", title: "Price - High" },
  low: { attribute: "price", order: "asc", title: "Price - Low" },
  latest: { attribute: "availableDate", order: "desc", title: "Latest" },
}

type sortTypes = keyof typeof SORT_OPTIONS

export const useSearchParams = () => {
  const location = useLocation();

  const urlQS = parse(location.search.substr(1));
  const { q: searchSlug, page: pageNumber, order, ...restOfParams } = urlQS
  const filterParams = valueToArray(restOfParams as {})
  const sortBy = String(order);
  const softs = Object.keys(SORT_OPTIONS).includes(sortBy) ? sortBy : "popular"
  const prefabricatedParams = {
    searchSlug: String(searchSlug),
    pageNumber: Number(pageNumber),
    sortBy: softs as sortTypes,
    filterParams: filterParams,
  }
  return prefabricatedParams
}