import React from "react";
import SVG from "react-inlinesvg";
import { useTheme } from "react-jss";

import "array-flat-polyfill";

import { SearchResponseItem } from "@attraqt/search";
import {
  AspectRatio,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  ProductSkeleton,
  TextSkeleton,
  Typography,
} from "@Components/atoms";
import { PLPBanner, QuickLinks, Variant } from "@Components/molecules";
import { useMediaQuery } from "@Hooks/index";
import * as Sentry from "@sentry/browser";
import { ThemeType } from "@Theme/theme";
import { getAttraqtPrices } from "@Utilities/utils";

import filterIcon from "../../../../../images/current/filter-icon.svg";
import { Link as LinkType } from "../../../globals/types";
import { useStyles } from "./style";

interface ListingPageTemplateProps {
  handleBannerTracking?: (banner: any) => void;
  handleProductTracking?: (variant: any, i: number) => void;
  handleQuickLinkTracking?: (banner: any) => void;
  header: string;
  isShowMore: boolean;
  loadMoreVariants: () => void;
  loading: boolean;
  openFilters: () => void;
  quickLinks?: LinkType[];
  variants: SearchResponseItem[];
}

const defaultProps = {
  handleBannerTracking: () => undefined,
  handleProductTracking: () => undefined,
  handleQuickLinkTracking: () => undefined,
  quickLinks: [],
};

function ListingPageTemplate({
  variants,
  loading,
  header,
  isShowMore,
  quickLinks,
  loadMoreVariants,
  openFilters,
  handleProductTracking = () => undefined,
  handleBannerTracking = () => undefined,
  handleQuickLinkTracking = () => undefined,
}: ListingPageTemplateProps) {
  const theme = useTheme<ThemeType>();
  const classes = useStyles();

  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const matchesTablet = useMediaQuery(theme.breakpoints.up("sm"));

  const skeletonNumber = matchesTablet && !matchesDesktop ? 6 : 8;
  const isAnyVarianAvailable = !loading && variants?.length === 0;
  const isQuickLinkAvailable = quickLinks?.length > 0;

  return (
    <>
      <Container bottomGutters>
        <Grid container alignItems="center" className={classes.listHeader}>
          <Grid xs={3} />
          <Grid xs={6}>
            <Typography
              className={classes.listingHeader}
              variant="h1"
              gutterBottom={false}
              align="center"
              fontFamily="secondaryFont"
              textTransform="uppercase"
            >
              {header}
            </Typography>
          </Grid>
          <Grid xs={3}>
            <Typography
              align="right"
              cursor="pointer"
              gutterBottom={false}
              handleClick={openFilters}
            >
              Filters
              <SVG src={filterIcon} />
            </Typography>
          </Grid>
        </Grid>
        <Divider absolute color="light" className={classes.headerDivider} />
        {isQuickLinkAvailable && (
          <QuickLinks>
            {quickLinks?.map((link) => (
              <Link
                href={link.url}
                key={link.externalName}
                openInNewTab={link.openInNewWindow}
                onClick={() => handleQuickLinkTracking(link.externalName)}
              >
                {link.externalName}
              </Link>
            ))}
          </QuickLinks>
        )}
      </Container>
      <Container disableGutters bottomGutters>
        <div className={classes.productListContainer}>
          {loading &&
            [...Array(skeletonNumber)].map((_, i) => (
              <div className={classes.loader} key={i}>
                <AspectRatio ratio={theme.aspectRatio.defaultImageAspectRatio}>
                  <ProductSkeleton />
                </AspectRatio>
                <Typography align="center">
                  <TextSkeleton height={50} style={{ margin: "8px 0 8px" }} />
                </Typography>
              </div>
            ))}
          {variants?.length > 0 &&
            variants?.map((variant, i) => {
              const isBanner = !!variant?.categoryId;
              const mainPhotoUrl = variant?.product?.photo;
              const coverPhoto = variant?.product?.secondImage;
              const variantTitle = variant?.product?.webname;
              const variantStatus = variant?.product?.status;
              const [variantSalePrice, variantPrice] = getAttraqtPrices(
                variant?.product?.retailPrice,
                variant?.product?.price,
              );
              const variantUrl = variant?.product?.url;
              const variantOnSale = variant?.product?.discountedPercentage > 0;
              const isInStock = variant?.product?.stock > 0;
              const isNew = variant?.product?.collectionsList?.includes("new-arrivals");
              const isNewSeason = variant?.product?.collectionsList?.includes("new-season");
              const variantAlt = `${variantTitle} image`;
              if (isBanner) {
                const bannerMedia = variant?.items?.contentCollection?.items?.find(
                  (item) => item?.__typename === "Media",
                );
                const bannerURL = variant?.items?.contentCollection?.items?.find(
                  (item) => item?.__typename === "Link",
                );
                const bannerType = variant?.type;
                const bannerInternalName = variant?.internalName;
                const bannerExternalName = variant?.items?.externalName;
                const bannerShortText = variant?.items?.shortText;
                return (
                  <PLPBanner
                    mainImg={bannerMedia?.mediaDesktop?.url}
                    mobileImg={bannerMedia?.mediaMobile?.url}
                    alt={bannerMedia?.altText}
                    type={bannerType}
                    link={bannerURL}
                    key={bannerInternalName}
                    className={classes.productImage}
                    bannerTitle={bannerExternalName}
                    bannerDescription={bannerShortText}
                    matchesDesktop={matchesDesktop}
                    onClick={() => handleBannerTracking(variant)}
                  />
                );
              }
              return (
                <Variant
                  url={variantUrl}
                  mainImage={mainPhotoUrl}
                  coverImage={coverPhoto}
                  alt={variantAlt}
                  title={variantTitle}
                  price={variantPrice}
                  retailPrice={variantSalePrice}
                  status={variantStatus}
                  inStock={isInStock}
                  variantOnSale={variantOnSale}
                  isNew={isNew}
                  isNewSeason={isNewSeason}
                  onClick={() => handleProductTracking(variant, i)}
                />
              );
            })}
        </div>
        {isAnyVarianAvailable && (
          <Grid container justify="center" xs={12}>
            <Typography variant="h2" gutterBottom={false} align="center" gutterTop>
              No products found
            </Typography>
          </Grid>
        )}
        {isShowMore && (
          <Grid container justify="center">
            <Grid className={classes.buttonContainer} xs={12} sm={6} md={4} lg={3}>
              <Button fluid color="secondary" onClick={loadMoreVariants}>
                View More
              </Button>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
}

ListingPageTemplate.defaultProps = defaultProps;

export default ListingPageTemplate;
