import { useState, useEffect } from "react";

export const useMediaQuery = (queryInput) => {
  const supportMatchMedia =
    typeof window !== "undefined" && typeof window.matchMedia !== "undefined";
  const defaultMatches = false;
  const query = queryInput.replace(/^@media( ?)/m, "");

  const [match, setMatch] = useState(() => {
    if (supportMatchMedia) {
      return matchMedia(query).matches;
    }

    return defaultMatches;
  });

  useEffect(() => {
    let active = true;

    if (!supportMatchMedia) {
      return undefined;
    }

    const queryList = matchMedia(query);

    const updateMatch = () => {
      if (active) {
        setMatch(queryList.matches);
      }
    };

    updateMatch();
    queryList.addListener(updateMatch);
    return () => {
      active = false;
      queryList.removeListener(updateMatch);
    };
  }, [query, supportMatchMedia]);
  return match;
};

export default useMediaQuery;
