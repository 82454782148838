import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles((theme) => ({
  dropdownContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    padding: "2rem 2rem 4rem 2rem",
    margin: "0 0 2rem 0",
    whiteSpace: "nowrap",
    // backgroundColor should be transparet
    backgroundColor: "#fff",
    zIndex: theme.zIndexValues.higher,
    [theme.breakpoints.down("xs")]: {
      overflowY: "scroll",
      height: "100vh",
    },
  },
}));
