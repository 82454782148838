import { createUseStyles } from "react-jss";

export interface HeaderLogoStyleProps {
  color: string;
}

const useStyles = createUseStyles({
  logoContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    minHeight: "65px",
    maxHeight: "65px",
    "& svg": {
      padding: "6px 0",
      height: "65px",
      fill: (props: HeaderLogoStyleProps) => props?.color,
      "@media (min-width: 1441px)": {
        paddingLeft: "20px",
      },
    },
  },
});

export default useStyles;
