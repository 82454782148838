import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  cssTriangle: props => ({
    ...((props.direction === 'down' && {
      borderBottomWidth: '0',
      borderTopStyle: 'solid',
      borderColor: `${props.color} transparent transparent transparent`,
    }) ||
      (props.direction === 'up' && {
        borderTopWidth: '0',
        borderBottomStyle: 'solid',
        borderColor: `transparent transparent ${props.color} transparent`,
      }) ||
      (props.direction === 'right' && {
        borderRightWidth: '0',
        borderLeftStyle: 'solid',
        borderColor: `transparent transparent transparent ${props.color}`,
      }) ||
      (props.direction === 'left' && {
        borderLeftWidth: '0',
        borderRightStyle: 'solid',
        borderColor: `transparent ${props.color} transparent transparent`,
      })),
    display: 'block',
    border: `inset ${props.size}`,
    content: '',
  }),
});

const Arrow = props => {
  const { onClick } = props;
  const classes = useStyles(props);

  return (
    <div onClick={onClick}>
      <div className={classes.cssTriangle} />
    </div>
  );
};

export default Arrow;

Arrow.defaultProps = {
  size: '1rem',
  color: '#777',
  direction: 'up',
};

Arrow.propTypes = {
  size: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['down', 'up', 'right', 'left']).isRequired,
};
