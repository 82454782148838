export const trackHomePage = (email: string) => {
  dataLayer.push({
    PageType: "HomePage",
    email: email,
  });
}

export const trackProductPage = (email: string, productID: string) => {
  dataLayer.push({
    PageType: "ProductPage",
    email: email,
    ProductID: productID,
  });
}

export const trackListingPage = (email: string, products: {}[]) => {
  dataLayer.push({
    PageType: "ListingPage",
    email: email,
    ProductIDList: products,
  });
}