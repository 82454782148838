import React from "react";

import { PriceValue } from "@Components/atoms";
import { TestProps } from "@Utilities/types";
import { getFullPrice, getPercentageDiscount } from "@Utilities/utils";

import useStyles from "./style";

interface PriceProps extends TestProps {
  price: number;
  retailPrice: number;
}

export function Price({ retailPrice, price, ...other }: PriceProps) {
  const classes = useStyles();

  const fullRetailPrice = getFullPrice(retailPrice);
  const fullPrice = getFullPrice(price);
  const percentageDiscount = getPercentageDiscount(price, retailPrice);

  return (
    <div className={classes.root} {...other}>
      {retailPrice !== price ? (
        <>
          <PriceValue type="discount">{fullPrice}</PriceValue>
          <PriceValue type="crossed">{fullRetailPrice}</PriceValue>
          <PriceValue type="percentage">{percentageDiscount}</PriceValue>
        </>
      ) : (
        <PriceValue>{fullPrice}</PriceValue>
      )}
    </div>
  );
}

export default Price;
