import React from "react";

import ComingSoonTag from "./components/ComingSoon";
import NewTag from "./components/New";
import NewSeasonTag from "./components/NewSeason";
import SaleTag from "./components/Sale";

interface StatusProps {
  children: (component: JSX.Element | null) => JSX.Element | null;
  isComingSoon?: boolean;
  isNew?: boolean;
  isNewSeason?: boolean;
  isOnSale?: boolean;
}
export function Status({ isComingSoon, isNewSeason, isNew, isOnSale, children }: StatusProps) {
  if (isOnSale) {
    return children(<SaleTag />);
  }
  if (isComingSoon) {
    return children(<ComingSoonTag />);
  }
  if (isNewSeason) {
    return children(<NewSeasonTag />);
  }
  if (isNew) {
    return children(<NewTag />);
  }
  return children(null);
}

Status.ComingSoon = ComingSoonTag;
Status.NewSeason = NewSeasonTag;
Status.New = NewTag;
Status.Sale = SaleTag;

export default Status;
