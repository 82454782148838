import React, { useLayoutEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles(theme => ({
  basketContainer: {
    display: "initial",
    position: "relative",
  },
  productAmountIndicator: {
    position: "absolute",
    left: "1px",
    right: 0,
    top: "1px",
    bottom: 0,
    textAlign: "center",
    color: props => props.color,
  },
  icon: {
    fill: props => props.color,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      transform: "scale(1.2)",
    },
  },
}));

const checkIfZero = number => {
  if (number === 0) return "";
  else return number;
};

const Basket = ({ productAmount, color }) => {
  const theme = useTheme();
  const classes = useStyles({ theme, color });
  const controls = useAnimation();
  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    controls.start({ scale: [1, 1.4, 1] });
  }, [productAmount, controls]);

  return (
    <motion.div initial={{ scale: 1 }} animate={controls} transition={{ duration: 0.25 }}>
      <div className={classes.basketContainer}>
        <span className={classes.productAmountIndicator}>{checkIfZero(productAmount)}</span>
        <svg className={classes.icon} width="16" height="20" viewBox="0 0 16 20">
          <g className="a">
            <motion.path
              d="M21.164,5.122h-3.7a3.944,3.944,0,0,0-7.737,0h-3.7a.435.435,0,0,0-.435.435V20.687a.435.435,0,0,0,.435.435h15.13a.435.435,0,0,0,.435-.435V5.556a.435.435,0,0,0-.435-.435ZM13.6,2.813a3.078,3.078,0,0,1,2.974,2.308H10.624A3.078,3.078,0,0,1,13.6,2.813Zm7.13,17.439H6.468V5.991H9.654V7.786a.435.435,0,0,0,.87,0V5.991h6.15V7.786a.435.435,0,0,0,.87,0V5.991h3.186Z"
              transform="translate(-5.599 -1.944)"
            />
          </g>
        </svg>
      </div>
    </motion.div>
  );
};

export default Basket;
