import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTheme } from "react-jss";

import {
  AspectRatio,
  Button,
  Container,
  Link,
  ProductSkeleton,
  TextField,
  TextSkeleton,
  Typography,
} from "@Components/atoms";
import { XIcon } from "@Components/atoms/Icons";
import SearchProduct from "@Components/molecules/SearchProduct";
import { SessionDataContext } from "@Context/sessionData";
import { useKeyPress } from "@Hooks/index";
import { ATTRAQT_TOKEN, SEARCH_TRENDING_RECOMMENDATIONS_WIDGET_ID } from "@Services/index";
import { ThemeType } from "@Theme/theme";
import { gtmEnhancedEcommerce, xoWidget } from "@Utilities/gtm";
import { manageRecentlySearched } from "@Utilities/manageRecentlySearched";
import { useDebouncedEffect } from "@Utilities/useDebouncedEffect";
import useFetchXORecommendations from "@Utilities/useFetchXORecommendations";
import { getAttraqtPrices } from "@Utilities/utils";

import { useSuggestions } from "./api/fetchSuggestions";
import { useStyles } from "./style";

interface SearchModuleProps {
  toggleOpen: () => void;
}

function SearchModule({ toggleOpen }: SearchModuleProps) {
  const theme = useTheme<ThemeType>();
  const classes = useStyles({ theme });

  const { isLoading: loadingSuggestions, data: dataSuggestions } = useSuggestions();

  const suggestionsListName = dataSuggestions?.contentContainerCollection?.items[0]?.externalName;
  const suggestions =
    dataSuggestions?.contentContainerCollection?.items[0]?.contentCollection?.items;

  const productAspectRatio = theme.aspectRatio.defaultImageAspectRatio;

  const { register, watch, setFocus } = useForm();
  const searchSlug = watch("search");

  const escPress = useKeyPress("Escape");
  const enterPress = useKeyPress("Enter");

  const [products, setProducts] = useState<any[] | null>();
  const [trendingTitle, setTrendingTitle] = useState<string>("");
  const [productsAmount, setProductsAmount] = useState<number>(0);
  const storedSearch = localStorage.getItem("LAST_SEARCHED");

  const [debouncedSearch, setDebouncedSearch] = useState("");

  const lengthLimit = 30;
  const isSearchEmpty = debouncedSearch?.length === 0;
  const isSearchLengthCorrect = !isSearchEmpty && debouncedSearch?.length < lengthLimit;

  const handleSearch = () => {
    if (isSearchLengthCorrect) {
      manageRecentlySearched(debouncedSearch);
      window.location.href = `/search/?q=${debouncedSearch}`;
    }
  };

  useDebouncedEffect(
    () => {
      setDebouncedSearch(searchSlug);
    },
    200,
    [searchSlug],
  );

  const [sessionData] = useContext(SessionDataContext);

  const { recommendations, xoWidgetMetadata } = useFetchXORecommendations({
    userId: sessionData?.user_id || "",
    setTrendingTitle,
    recommendationId: SEARCH_TRENDING_RECOMMENDATIONS_WIDGET_ID,
    enable: true,
  });

  useEffect(() => {
    async function fetchMyAPI() {
      const options = {
        limit: 4,
        offset: 0,
        disable: [],
        facets: [],
        sortBy: [],
        filter: "",
      };
      try {
        let response = await fetch("https://api-eu.attraqt.io/search", {
          method: "POST",
          body: JSON.stringify({
            token: ATTRAQT_TOKEN,
            query: debouncedSearch || "",
            options,
          }),
        });
        response = await response.json();

        const items = (response as any)?.items;
        setProductsAmount((response as any)?.metadata.count);

        if (items?.length) {
          setProducts(items.map((item: any) => item.product));
        } else {
          setProducts(items);
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (debouncedSearch?.length < lengthLimit) {
      setProducts(null);
      setProductsAmount(0);
      fetchMyAPI();
    }
  }, [debouncedSearch]);

  useEffect(() => {
    escPress && toggleOpen();
  }, [toggleOpen, escPress]);

  useEffect(() => {
    if (enterPress && searchSlug.length && productsAmount) handleSearch();
  }, [searchSlug, enterPress, productsAmount]);

  useEffect(() => {
    // FIXME: WEB-2725
    setTimeout(() => {
      setFocus("search");
    }, 20);
  }, [setFocus]);

  const resultTitle = debouncedSearch?.length ? "RESULTS" : trendingTitle;

  const handleTracking = (product: any, id: string, xoWidgetMeta?: xoWidget) => {
    const [retailPrice, price] = getAttraqtPrices(product?.retailPrice, product?.price);
    const category = product?.mainCategory?.toLowerCase();
    const subcategory = product?.subCategory?.toLowerCase();
    const fullCategory = subcategory ? `${category}/${subcategory}` : category;
    gtmEnhancedEcommerce(
      "impressionClick",
      [
        {
          id,
          name: product?.title,
          category: fullCategory,
          price: price?.toFixed(2)?.toString(),
          colour: product?.color,
          size: product?.sizes[0],
          url: product?.url,
          image: product?.photo,
        },
      ],
      "SEARCH SUGGESTIONS",
      xoWidgetMeta,
    );
  };

  return (
    <Container disableGutters className={classes.modalContainer}>
      <div className={classes.modalContent}>
        <TextField
          underline
          className={classes.customInput}
          placeholder="SEARCH"
          autoFocus
          {...register("search")}
        />
        <div className={classes.modalNavigation}>
          <XIcon onClick={toggleOpen} />
        </div>
        <div className={classes.suggestion}>
          <div className={classes.suggestionMenu}>
            {loadingSuggestions ? (
              <TextSkeleton />
            ) : (
              <>
                <p className={classes.title}>{suggestionsListName}</p>
                <ul className={classes.styledList}>
                  {suggestions?.map((subcategory) => (
                    <Link key={subcategory?.url} href={subcategory?.url}>
                      <li>{subcategory?.externalName}</li>
                    </Link>
                  ))}
                </ul>
              </>
            )}
            {storedSearch ? (
              <>
                <p className={classes.title}>RECENTLY SEARCHED</p>
                <ul className={classes.styledList}>
                  {JSON.parse(storedSearch).map((searched: string) => (
                    <Link key={searched} href={`/search/?q=${searched}`}>
                      <li>{searched}</li>
                    </Link>
                  ))}
                </ul>
              </>
            ) : null}
          </div>
          <div className={classes.suggestionProducts}>
            <p className={classes.title}>{resultTitle}</p>
            <div className={classes.productContainer}>
              {recommendations?.length && isSearchEmpty ? (
                recommendations.map(({ product, id }) => (
                  <SearchProduct
                    product={product}
                    productAspectRatio={productAspectRatio}
                    handleClick={() => handleTracking(product, id, xoWidgetMetadata)}
                  />
                ))
              ) : products ? (
                products.length ? (
                  products.map((product, id) => (
                    <SearchProduct
                      product={product}
                      productAspectRatio={productAspectRatio}
                      handleClick={() => handleTracking(product, String(id))}
                    />
                  ))
                ) : (
                  <div className={classes.noProductContainer}>
                    <p className={classes.noProductHeader}>SORRY, NO RESULTS FOUND</p>
                    <p className={classes.noProductSubtitle}>Try a different search</p>
                  </div>
                )
              ) : (
                [...Array(4)].map((_, i) => (
                  <div className={classes.productBox} key={i}>
                    <AspectRatio ratio={productAspectRatio}>
                      <ProductSkeleton />
                    </AspectRatio>
                    <Typography align="center" gutterBottom={false}>
                      <TextSkeleton height={60} style={{ marginTop: "8px" }} />
                    </Typography>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
        <div
          onClick={handleSearch}
          className={classNames(classes.buttonContainer, {
            [classes.show]: products?.length && isSearchLengthCorrect && productsAmount,
          })}
        >
          <Button color="secondary" className={classes.button}>
            View all ({productsAmount})
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default SearchModule;
