import React from "react";
import ContentLoader from "react-content-loader";

export const DescriptionSkeleton = () => {
  return (
    <ContentLoader width={"100%"} height={100} preserveAspectRatio={"xMinYMin slice"}>
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="100" />
    </ContentLoader>
  );
};
