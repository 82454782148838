import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useTheme } from "react-jss";
import { useLocation } from "react-router-dom";

import { Container, Divider } from "@Components/atoms";
import Copyright from "@Components/molecules/Copyright";
import Newsletter from "@Components/molecules/Newsletter";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useMediaQuery } from "@Hooks/index";
import { ThemeType } from "@Theme/theme";

import useFooter from "./api/fetchFooter";
import FooterNavigation from "./FooterNavigation";
import useStyles from "./style";

export function Footer() {
  const theme = useTheme<ThemeType>();
  const classes = useStyles();

  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const location = useLocation();
  const isNewsletterPage = location.pathname === "/newsletter";

  const { isLoading, error, data } = useFooter();

  // defuck
  useEffect(() => {
    document.documentElement.style.backgroundColor = "#f0f0f0";
  }, []);

  if (isLoading) return <div />;
  if (error) return <div />;

  const newsletterData = data?.contentContainerCollection?.items?.find(
    (item) => item?.internalName === "Newsletter Subscription",
  );

  const policyMessage = newsletterData?.contentCollection?.items.find(
    (item) => item?.__typename === "InputField",
  );
  const newsletterTitle = newsletterData?.externalName;
  const newsletterSubtitle = newsletterData?.richText?.json;
  const buttonText = newsletterData?.contentCollection?.items?.find(
    (item) => item?.__typename === "Button",
  )?.externalName;
  const emailPlaceholder = newsletterData?.placeholderText;
  const footerNavData = data?.contentContainerCollection?.items.find(
    (item) => item?.internalName === "Footer",
  );

  return ReactDOM.createPortal(
    <Container disableGutters backgroundColor={theme.color.lightGray} maxWidth={false}>
      <Container className={matches ? classes.footerContainer : classes.mobileFooterContainer}>
        {!isNewsletterPage && (
          <Newsletter
            newsletterTitle={newsletterTitle}
            newsletterSubtitle={documentToReactComponents(newsletterSubtitle)}
            policyMessage={documentToReactComponents(policyMessage.description.json)}
            emailPlaceholder={emailPlaceholder}
            defaultButtonText={buttonText}
            formWidth="70%"
          />
        )}
        {matches && !isNewsletterPage && <Divider />}
        <FooterNavigation data={footerNavData} />
        <Copyright />
      </Container>
    </Container>,
    document.getElementById("footer"),
  );
}

export default Footer;
