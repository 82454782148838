import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles(theme => ({
  modalNavigation: {
    position: "absolute",
    top: "10px",
    right: "0",
    marginLeft: 0,
    cursor: "pointer",
  },
  title: {
    color: "#ADADAD",
    cursor: "default",
    fontSize: "12.5px",
    margin: 0,
    "&:hover": {
      color: "#ADADAD",
    },
  },
  customInput: {
    fontSize: "18px",
    display: "flex",
    "@global": {
      input: {
        marginRight: "auto",
        padding: 0,
      },
    },
  },
  modalContent: {
    width: "100%",
    margin: "0 auto",
    position: "relative",

    [theme.breakpoints.down("xs")]: {
      height: "100%",
    },
  },
  productBox: {
    boxSizing: "border-box",
    width: "25%",
    padding: 0,
    marginRight: "2rem",
    whiteSpace: "normal",

    "&:last-child": {
      marginRight: 0,
    },

    [theme.breakpoints.down("xs")]: {
      width: "50%",
      margin: "1rem 0",

      "&:not(:nth-child(2n))": {
        paddingRight: "1rem",
      },
      "&:nth-child(2n)": {
        paddingLeft: "1rem",
      },
    },
  },
  modalContainer: {
    [theme.breakpoints.down("xs")]: {
      height: "min-content",
      minHeight: "100vh",
    },
  },
  icon: {
    cursor: "pointer",
    backgroundColor: "pink",
  },
  suggestion: {
    color: "#000",
    display: "flex",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      height: "min-content",
    },
  },
  suggestionMenu: {
    width: "20%",
    marginRight: "2rem",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  suggestionProducts: {
    width: "80%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      flex: "1",
      display: "flex",
      flexDirection: "column",
    },
  },
  productContainer: {
    display: "flex",
    height: "100%",

    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  productBoxSkeleton: {
    height: "90%",
  },
  styledList: {
    listStyleType: "none",
    margin: "0 3rem 0 0",
    minWidth: "100%",
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@global": {
      li: {
        margin: "1.2rem 0",
        cursor: "pointer",
        fontSize: "12.5px",
        whiteSpace: "normal",
        maxWidth: "200px",
      },
    },
  },
  buttonContainer: {
    display: "block",
    margin: "2rem auto 0 auto",
    width: "max-content",
    opacity: 0,
    transition: "opacity 0.5s ease",

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  show: {
    opacity: 1,

    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  button: {
    width: "200px",
  },
  noProductContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",

    [theme.breakpoints.down("xs")]: {
      marginTop: "10rem",
    },
  },
  noProductHeader: {
    fontSize: "2rem",
    fontFamily: '"nimbus-roman", serif',
    color: "#1a1a1a",
  },
  noProductSubtitle: {
    marginBottom: "8rem",
    fontSize: "1.1rem",
    color: "#7c7c7c",

    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
    },
  },
}));
