import React from "react";

import { Carousel } from "@mantine/carousel";
import { Anchor, Image } from "@mantine/core";

import type { CarouselProduct } from "../utils";
import { calculateSlideSize } from "../utils";

interface SimpleCarouselProps {
  fractionOfFullScreen: number;
  products: CarouselProduct[];
}

function SimpleCarouselUI({ products, fractionOfFullScreen }: SimpleCarouselProps) {
  return (
    <Carousel
      slideGap="md"
      loop
      withControls={false}
      breakpoints={[
        { maxWidth: "xs", slideSize: calculateSlideSize(1 / 2, fractionOfFullScreen) },
        { maxWidth: "sm", slideSize: calculateSlideSize(1 / 3, fractionOfFullScreen) },
        { maxWidth: "md", slideSize: calculateSlideSize(1 / 4, fractionOfFullScreen) },
        { maxWidth: "lg", slideSize: calculateSlideSize(1 / 5, fractionOfFullScreen) },
        { maxWidth: "xl", slideSize: calculateSlideSize(1 / 5, fractionOfFullScreen) },
        { minWidth: "xl", slideSize: calculateSlideSize(1 / 6, fractionOfFullScreen) },
      ]}
    >
      {products.map((product) => (
        <Carousel.Slide>
          <Anchor href={product.externaLink} target="_blank">
            <Image src={product.imageUrl} alt={product.title} />
          </Anchor>
        </Carousel.Slide>
      ))}
    </Carousel>
  );
}

export default SimpleCarouselUI;
