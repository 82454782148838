import React from "react";

import { RenderPropType } from "@Components/organisms/Header/Navigation/Navigation";
import { Grid } from "@mantine/core";

import { ReturnReference } from "../../types";
import Order from "../Order/Order";

export const OrderListContext = React.createContext<ReturnReference[] | undefined>(undefined);

interface ReturnProps {
  productVariantId: string;
  quantity: number;
}
interface VariantListProps {
  children: RenderPropType<ReturnProps> | React.ReactElement[] | React.ReactElement;
  header: React.ReactNode;
  variants: ReturnReference[];
}

function OrderList({ header, variants, children }: VariantListProps) {
  return (
    <OrderListContext.Provider value={variants}>
      <Grid>
        <Grid.Col span={12}>{header}</Grid.Col>
      </Grid>
      {variants.map((line) => {
        const { productVariantId } = line.productVariant;
        const { quantity, reason } = line;

        return (
          <Order order={line.productVariant} media={<Order.Photo />} reason={{ quantity, reason }}>
            {typeof children === "function" ? children({ productVariantId, quantity }) : children}
          </Order>
        );
      })}
    </OrderListContext.Provider>
  );
}

OrderList.Order = Order;

export default OrderList;
