import classNames from "classnames";
import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { space, SpaceProps } from "styled-system";

interface TagProps extends SpaceProps {
  children: React.ReactNode;
  className?: string;
  color?: string;
}

const useStyles = createUseStyles(() => {
  const tagHeight = "20px";

  return {
    system: space,
    tagRoot: {
      textAlign: "center",
      display: "inline-block",
      height: "auto",
      lineHeight: tagHeight,
      minWidth: "65px",
      padding: "0 4px",
      backgroundColor: (props: Partial<TagProps>) => props.color,
      fontWeight: "normal",
      fontSize: "12px",
    },
  };
});

export function Tag(props: TagProps) {
  const { children, color = "#fff", className, ...system } = props;
  const theme = useTheme() as any;
  const extendedTheme = {
    theme,
    color,
    ...system,
  };

  const classes = useStyles(extendedTheme);
  const styledClasses = classNames([className, classes.tagRoot, classes.system]);

  return <span className={styledClasses}>{children}</span>;
}

export default Tag;
