import urlJoin from "url-join";

let RETURN_APP_ENDPOINT = "";

if (window.location.hostname === "www.prettygreen.com") {
  RETURN_APP_ENDPOINT = "https://return-prod.app.prettygreen.com/graphql";
} else if (window.location.hostname === "www.stage.prettygreen.com") {
  RETURN_APP_ENDPOINT = "https://return-dev.app.prettygreen.com/graphql";
} else if (window.location.hostname === "www.qa.prettygreen.com") {
  RETURN_APP_ENDPOINT = "https://return-dev.app.prettygreen.com/graphql";
} else {
  RETURN_APP_ENDPOINT = "https://return-dev.app.prettygreen.com/graphql";
}

export function returnFetcher<TData, TVariables>(query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(RETURN_APP_ENDPOINT, {
      method: "POST",
      ...{
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ query, variables }),
      },
    });

    const json = await res.json();
    const returnData = json.data;

    if (returnData[Object.keys(returnData)[0]] && returnData[Object.keys(returnData)[0]].error) {
      const message = returnData[Object.keys(returnData)[0]].error;
      throw new Error(message);
    }

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return returnData;
  };
}

export default returnFetcher;
