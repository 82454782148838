import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";

import FooterLinks from "./FooterLinks";
import SocialLinks from "./SocialLinks";

const useStyles = createUseStyles((theme) => ({
  footerNavigationContainer: {
    width: "100%",

    justifyContent: "space-between",
    marginTop: "1.5rem",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexWrap: "no-wrap",
    },
  },
}));

const FooterNavigation = ({ data }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const mobileLinksContent = data.contentCollection.items.find(
    (item) => item.internalName === "Footer Navigation Mobile",
  ).navigationItemsCollection;
  const desktopLinksContent = data.contentCollection.items.find(
    (item) => item.internalName === "Footer Navigation Desktop",
  ).navigationItemsCollection;

  const media = data.contentCollection.items.filter((item) => item.__typename === "Media");

  return (
    <div className={classes.footerNavigationContainer}>
      <FooterLinks mobileLinks={mobileLinksContent} desktopLinks={desktopLinksContent} />
      <SocialLinks media={media} />
    </div>
  );
};

export default FooterNavigation;

FooterNavigation.propTypes = {
  data: PropTypes.object,
};
