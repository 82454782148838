import * as React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

const fadeVariants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

const slideVariants = from => {
  const slideHiddenState = {
    left: {
      x: "-100vw",
    },
    right: {
      x: "100vw",
    },
  };

  return {
    visible: {
      x: 0,
      transition: { type: "spring", damping: 18 },
    },
    hidden: slideHiddenState[from],
  };
};

export const VisibilityAnimation = ({ children, delay = 0, duration = 1, className, variants }) => (
  <motion.div
    className={className}
    initial="hidden"
    animate="visible"
    exit="hidden"
    variants={variants}
    transition={{ duration, delay }}
  >
    {children}
  </motion.div>
);

VisibilityAnimation.propTypes = {
  children: PropTypes.node,
  delay: PropTypes.number,
  duration: PropTypes.number,
  className: PropTypes.string,
  variants: PropTypes.shape({ visible: PropTypes.object, hidden: PropTypes.object }),
};

export const Fade = props => <VisibilityAnimation variants={fadeVariants} {...props} />;

export const SlideFromLeft = props => <VisibilityAnimation variants={slideVariants("left")} {...props} />;

export const SlideFromRight = props => <VisibilityAnimation variants={slideVariants("right")} {...props} />;

export const FullScreen = props => <VisibilityAnimation variants={fadeVariants} {...props} />;

export default { FullScreen, Fade, SlideFromLeft, SlideFromRight };
