import React from "react";
import { useRouteMatch } from "react-router-dom";

import ContentfulPageTemplate from "@Components/templates/ContentfulPageTemplate/ContentfulPageTemplate";

import useBlogPost from "./api/fetchCustomPage";

interface BlogPageMatchParams {
  dd: string;
  mm: string;
  name: string;
  yyyy: string;
}
export function BlogPage() {
  const match = useRouteMatch<BlogPageMatchParams>();

  const { data, isLoading } = useBlogPost({
    pageSlug: `/news/${match.params.yyyy}/${match.params.mm}/${match.params.dd}/${match.params.name}`,
  });

  if (isLoading) return <div />;

  if (data?.blogPostCollection?.items.length === 0) return <div />;

  const customPageBlocks = data?.blogPostCollection?.items[0];

  return <ContentfulPageTemplate data={customPageBlocks} />;
}

export default BlogPage;
