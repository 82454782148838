import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import Swiper from "swiper";

import {
  AspectRatio,
  Box,
  Carousel,
  Image,
  Link,
  ProductSkeleton,
  Typography,
} from "@Components/atoms";
import CarouselColorPicker from "@Components/molecules/CarouselColorPicker";
import Price from "@Components/molecules/Price";
import Product from "@Components/molecules/Product";
import Status from "@Components/molecules/Status";
import { useMediaQuery } from "@Hooks/index";
import { ThemeType } from "@Theme/theme";
import { Variant } from "@Types/API/variants";
import { gtmEnhancedEcommerce } from "@Utilities/gtm";
import { ConditionalWrapper } from "@Utilities/utilities";
import { capitalize } from "@Utilities/utils";

import useStyles from "./style";

const MAIN_CAROUSEL_SETTINGS = {
  autoplay: {
    delay: 1,
  },
  freeMode: true,
  loop: true,
  slidesPerView: "auto" as const,
  loopPreventsSlide: false,
  speed: 18000,
  freeModeMomentumBounce: false,
  breakpoints: {
    200: {
      spaceBetween: 5,
      slidesPerView: 1.4,
    },
    768: {
      spaceBetween: 5,
    },
    1024: {
      spaceBetween: 10,
    },
    2560: {
      spaceBetween: 10,
    },
  },
};

const NAVIGATION_CAROUSE_SETTINGS = {
  slideToClickedSlide: true,
  slidesPerView: "auto" as const,
};

interface HomepageCarouselProps {
  variants: Variant[];
}

export function HomepageCarousel({ variants }: HomepageCarouselProps) {
  const theme = useTheme<ThemeType>();
  const classes = useStyles({ theme });
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [activeCollection, setActiveCollection] = useState(0);
  const [mainCarousel, setMainCarousel] = useState<Swiper | null>(null);
  const [navigationCarousel, setNavigationCarousel] = useState<Swiper | null>(null);
  const [isNavigation, setNavigation] = useState(false);

  useEffect(() => {
    if (mainCarousel) {
      mainCarousel.on("slideChange", handleSlideChange);
      mainCarousel.on("autoplayStop", () => setNavigation(true));
    }
  }, [mainCarousel, navigationCarousel]);

  const collections = [...new Set(variants.map((variant) => variant.contentful_collection_name))];

  function handleSlideChange(this: Swiper) {
    const activeProductCollectionName = variants[this.realIndex].contentful_collection_name;
    const activeProductCollectionIndex = collections.indexOf(activeProductCollectionName);

    if (navigationCarousel) {
      navigationCarousel.slideTo(activeProductCollectionIndex);
    }

    setActiveCollection(activeProductCollectionIndex);
  }

  function handleCollectionClick(i: number, collectionName: string) {
    setActiveCollection(i);
    const nextProductIndex = variants.findIndex(
      (variant) => variant.contentful_collection_name === collectionName,
    );
    if (mainCarousel) {
      mainCarousel.slideToLoop(nextProductIndex, 1000);
    }
  }

  return (
    <div className={classes.carouselContainer}>
      <div className={classes.carouselNavigation}>
        <ConditionalWrapper
          condition={isMobile}
          wrapper={(children) => (
            <Carousel
              className={classes.customCarousel}
              onSwiper={setNavigationCarousel}
              {...NAVIGATION_CAROUSE_SETTINGS}
            >
              {children}
            </Carousel>
          )}
        >
          {collections.map((collectionName, i) => {
            const isActive = activeCollection === i;
            return (
              <h2
                key={collectionName}
                className={classNames(
                  classes.navigationListElement,
                  isActive && classes.elementActive,
                )}
                onClick={() => handleCollectionClick(i, collectionName)}
              >
                {collectionName}
              </h2>
            );
          })}
        </ConditionalWrapper>
      </div>
      {variants.length && (
        <Carousel
          {...MAIN_CAROUSEL_SETTINGS}
          className={classes.productsCarousel}
          onSwiper={setMainCarousel}
          customNavigation={
            isNavigation ? (
              <>
                <div onClick={() => mainCarousel?.slidePrev(1000)} className="swiper-button-prev" />
                <div onClick={() => mainCarousel?.slideNext(1000)} className="swiper-button-next" />
              </>
            ) : undefined
          }
        >
          {variants.map((variant, i) => {
            const isOnSale =
              variant.stock_info_per_segment.default.retail_price >
              variant.stock_info_per_segment.default.price;
            const { colors } = variant;
            const [link] = variant.url!.split("?");
            const isComingSoon = variant.status === "comingsoon";
            const isNew = !!variant.collectionsList?.includes("new-arrivals");
            const isNewSeason = !!variant.collectionsList?.includes("new-season");
            const actualColor = variant.color?.raw_value;
            const actualDisplayColor = variant.color?.display_value;

            const productGTMEvent = (colourRaw?: string, displayColour?: string) =>
              gtmEnhancedEcommerce(
                "impressionClick",
                [
                  {
                    id: `${variant?.sku}-${colourRaw?.toUpperCase()}`,
                    name: `${capitalize(displayColour)} ${variant?.product_name}`,
                    category: variant?.category_slug,
                    price: variant?.stock_info_per_segment?.default?.price.toString(),
                    url: `${window.location.hostname}${variant?.url?.substring(
                      0,
                      variant?.url?.lastIndexOf("=") + 1,
                    )}${colourRaw}`,
                    position: i,
                    colour: colourRaw,
                  },
                ],
                "PRODUCT CAROUSEL",
              );

            return (
              <>
                <Link
                  key={variant.id}
                  onClick={() => productGTMEvent(actualColor, actualDisplayColor)}
                  href={variant.url}
                >
                  <Product>
                    <AspectRatio ratio={theme.aspectRatio.defaultImageAspectRatio}>
                      <Image
                        alt={variant.name}
                        src={variant.product_images[0].image_1010x1333}
                        srcSet={`${variant.product_images[0].image_1010x1333}, ${variant.product_images[0].image_1010x1333} 2x, `}
                        fallback={<ProductSkeleton />}
                      />
                      <Status
                        isOnSale={isOnSale}
                        isComingSoon={isComingSoon}
                        isNew={isNew}
                        isNewSeason={isNewSeason}
                      >
                        {(statusTag) =>
                          statusTag ? (
                            <Box position="absolute" bottom="12px" left="12px">
                              {statusTag}
                            </Box>
                          ) : null
                        }
                      </Status>
                    </AspectRatio>
                    <Box m="8px" textAlign="center">
                      <Typography fontSize={isMobile ? "12px" : "14px"} data-test="product-name">
                        {variant.name}
                      </Typography>
                      <Typography variant="caption" fontSize={isMobile ? "12px" : "14px"}>
                        <Price
                          retailPrice={variant.stock_info_per_segment.default.retail_price}
                          price={variant.stock_info_per_segment.default.price}
                        />
                      </Typography>
                    </Box>
                  </Product>
                </Link>
                <CarouselColorPicker
                  colors={colors!}
                  link={link!}
                  actualColor={actualColor!}
                  productGTMEvent={productGTMEvent}
                />
              </>
            );
          })}
        </Carousel>
      )}
    </div>
  );
}

export default HomepageCarousel;
