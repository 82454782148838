import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  icon: {
    cursor: "pointer",
    transform: "scale(1.3)",
  },
  styledIcon: {
    fillRule: "evenodd",
  },
});

const XIcon = ({ onClick }) => {
  const classes = useStyles();

  return (
    <svg className={classes.icon} onClick={onClick} width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(-38.243 -94)">
        <path
          className={classes.styledIcon}
          d="M54,54.737,61.285,62,54,69.285l.737.715L62,62.737,69.285,70,70,69.285,62.737,62,70,54.737,69.285,54,62,61.285,54.737,54Z"
          transform="translate(-15.757 40)"
        />
      </g>
    </svg>
  );
};

export default XIcon;
