import React from "react";
import SVG from "react-inlinesvg";

import Link from "@Components/atoms/Link";

import useStyles, { HeaderLogoStyleProps } from "./style";

interface HeaderLogoProps extends HeaderLogoStyleProps {
  color: string;
  imageURL: string;
  url: string;
}

function HeaderLogo({ url, imageURL, color }: HeaderLogoProps) {
  const classes = useStyles({ color });
  return (
    <Link href={url} className={classes.logoContainer} color={color}>
      <SVG src={imageURL} />
    </Link>
  );
}

export default HeaderLogo;
