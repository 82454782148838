import React, { PropsWithChildren } from "react";

import { Title } from "@mantine/core";

interface OrderLayoutProps {
  orderId: string;
}

function OrderLayout({ children, orderId }: PropsWithChildren<OrderLayoutProps>) {
  return (
    <div>
      <Title order={1} size="h2" my="2rem" align="center">
        ORDER #{orderId}
      </Title>
      {children}
    </div>
  );
}
export default OrderLayout;
