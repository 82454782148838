import React from "react";

import { ActionIcon, Group, NumberInput, NumberInputHandlers } from "@mantine/core";
import { IconMinus, IconPlus } from "@tabler/icons";

interface QuantityCounterProps {
  count: number;
  handleChange: (value: number) => void;
  max: number;
  min: number;
}

function QuantityCounter({ count, min, max, handleChange }: QuantityCounterProps) {
  const handlers = React.useRef<NumberInputHandlers>();
  return (
    <Group spacing={5}>
      <ActionIcon size={42} onClick={() => handlers.current?.decrement()}>
        <IconMinus />
      </ActionIcon>
      <NumberInput
        onChange={(value) => handleChange(Number(value))}
        value={count}
        hideControls
        handlersRef={handlers}
        max={max}
        min={min}
        styles={{
          input: {
            height: "10px !important",
            width: "30px !important",
            textAlign: "center",
            marginBottom: "0 !important",
          },
        }}
      />
      <ActionIcon size={42} onClick={() => handlers.current?.increment()}>
        <IconPlus />
      </ActionIcon>
    </Group>
  );
}
export default QuantityCounter;
