import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles(theme => ({
    productContainer: {
      padding: "16px",
      position: "relative",
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid #F0F0F0",
    },
    details: {
      "& div": {
        marginBottom: "8px",
        padding: 0,
      },
      "& div span:first-child": {
        margin: 0,
      },
    },
    customButton: {
      marginBottom: theme.spacing(2),
    },
    navigationContainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "20px",
    },
    productList: {
      [theme.breakpoints.up("sm")]: {
        maxHeight: "60%",
        overflow: "scroll",
        scrollbarWidth: "none", // Firefox
        "&::-webkit-scrollbar": {
          display: "none", // Chrome, Safari, Opera
        },
        "&:-ms-overflow-style": "none", // IE, Edge
      },
    },
    customImage: {
      marginRight: theme.spacing(2),
      width: 100,
      paddingBottom: 132,
    },
    summaryContainer: {
      position: "absolute",
      bottom: 156,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      padding: 20,
      borderTop: `solid 1px ${theme.color.lightGray}`,
      borderBottom: `solid 1px ${theme.color.lightGray}`,
      backgroundColor: theme.color.white,
      [theme.breakpoints.up("sm")]: {
        position: "static",
      },
    },
    contentPlug: {
      height: "63px",
      [theme.breakpoints.up("sm")]: {
        visibility: "hidden",
        display: "none",
      },
    },
  }));