import React from "react";
import { useForm } from "react-hook-form";
import { createUseStyles, useTheme } from "react-jss";

import { Button, Container, TextField, Typography } from "@Components/atoms";
import { useMediaQuery, usePostData } from "@Hooks/index";
import { EMAIL_PATTERN } from "@Utilities/patterns";

interface NewsletterProps {
  defaultButtonText?: string;
  emailPlaceholder?: string;
  formWidth?: string;
  newsletterSubtitle?: string;
  newsletterTitle?: string;
  policyMessage?: React.ReactNode;
}

const useStyles = createUseStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: "1.5rem auto 1.5rem",
    [theme.breakpoints.up("md")]: {
      width: (props: NewsletterProps) => props.formWidth,
      justifyContent: "space-between",
    },
  },
  inputContainer: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      flexBasis: "66%",
      paddingRight: "8px",
    },
  },
  customInput: {
    marginBottom: 0,
    borderWidth: ".5px",
    fontSize: "14px",
    overflow: "hidden",
  },
  customButton: {
    borderWidth: ".5px",
    fontSize: "14px",
    [theme.breakpoints.up("sm")]: {
      flexBasis: "33%",
    },
    [theme.breakpoints.down("xs")]: {
      height: "52px",
    },
  },
  successButton: {
    extend: "customButton",
    color: theme.color.white,
    backgroundColor: theme.color.darkGray,
  },
  mobilePolicyMessage: {
    flexBasis: "100%",
    "& a": {
      color: theme.color.black,
      textDecoration: "underline",
    },
  },
  desktopPolicyMessage: {
    flexBasis: "100%",
    marginTop: "-6px",
    "& a": {
      color: theme.color.black,
      textDecoration: "underline",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

export function Newsletter({
  newsletterTitle,
  newsletterSubtitle,
  policyMessage,
  emailPlaceholder = "Your email",
  defaultButtonText = "Subscribe",
  formWidth = "100%",
}: NewsletterProps) {
  const theme = useTheme() as any;
  const classes = useStyles({ formWidth });
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm();

  const watchUserEmail = watch("userEmail", false);

  const error = !!Object.keys(errors).length;

  const { res: resSubscribe, callAPI: postSubscribe } = usePostData({
    url: "/api/newsletter/subscribe",
    payload: JSON.stringify({
      email: watchUserEmail,
    }),
    contentType: "application/json",
  });

  const buttonText = resSubscribe.success ? "Thanks! You’ll Be Updated" : defaultButtonText;
  const buttonDisabled = !watchUserEmail.length || resSubscribe.success;

  return (
    <Container>
      <Typography variant="h2" fontWeight="bold" textTransform="uppercase" align="center" gutterTop>
        {newsletterTitle}
      </Typography>
      <Typography align="center">{newsletterSubtitle}</Typography>
      <form autoComplete="off" onSubmit={handleSubmit(postSubscribe)}>
        <div className={classes.container}>
          <div className={classes.inputContainer}>
            <TextField
              type="email"
              outline
              placeholder={emailPlaceholder}
              className={classes.customInput}
              error={error}
              errorText={errors.userEmail && errors.userEmail.message}
              backgroundColor={theme.color.white}
              {...register("userEmail", {
                required: {
                  value: true,
                  message: "Please provide your email address",
                },
                pattern: {
                  value: EMAIL_PATTERN,
                  message: "Provided email address is not valid ",
                },
              })}
            />
            <Typography className={classes.desktopPolicyMessage}>{policyMessage}</Typography>
          </div>
          <Button
            color="tertiary"
            className={!resSubscribe.success ? classes.customButton : classes.successButton}
            disabled={buttonDisabled}
            fluid
            type="submit"
          >
            {buttonText}
          </Button>
          {!matches && (
            <Typography className={classes.mobilePolicyMessage} align="center">
              {policyMessage}
            </Typography>
          )}
        </div>
      </form>
    </Container>
  );
}

export default Newsletter;
