import { Line, ReasonWithType, ReturnData } from "../types";

const useOrderData = (
  returnData: ReturnData[],
  lines: Line[],
  selectedReasons: ReasonWithType[],
) => {
  const orderLinesDatabase = new Map(lines?.map((line) => [line.productVariantId, line]));

  const mergetReturnsDatabase = returnData.reduce((acc, returnLine) => {
    const mergedReturn = acc.get(returnLine.productVariantId);
    if (mergedReturn) {
      const { quantity } = mergedReturn;
      return acc.set(returnLine.productVariantId, {
        quantity: quantity + returnLine.quantity,
      });
    }
    return acc.set(returnLine.productVariantId, { quantity: returnLine.quantity });
  }, new Map<string, { quantity: number }>());

  const returnedLines = returnData.flatMap((returnedVariant) => {
    const productVariant = orderLinesDatabase.get(returnedVariant.productVariantId);
    if (productVariant) {
      return [
        {
          productVariant,
          quantity: returnedVariant.quantity,
          reason: returnedVariant?.reason || "",
        },
      ];
    }
    return [];
  });

  const toReturnLines = lines.flatMap((variant) => {
    const { isEligibleForReturn, productVariantId } = variant;
    const productVariant = orderLinesDatabase.get(productVariantId);
    const mergedReturnData = mergetReturnsDatabase.get(productVariantId);
    const isDirty = !!mergedReturnData;
    const numberOfReturned = isDirty ? mergedReturnData.quantity : 0;
    const remainingVariants = variant.quantity - numberOfReturned;
    if (productVariant && remainingVariants > 0 && isEligibleForReturn) {
      return [
        {
          productVariant,
          quantity: remainingVariants,
          reason: "",
        },
      ];
    }
    return [];
  });

  const productsNotSuitableForReturn = lines.flatMap((variant) => {
    const productVariant = orderLinesDatabase.get(variant.productVariantId);
    const mergedReturnData = mergetReturnsDatabase.get(variant.productVariantId);
    const isDirty = !!mergedReturnData;
    const numberOfReturned = isDirty ? mergedReturnData.quantity : 0;
    const remainingVariants = variant.quantity - numberOfReturned;
    if (!variant.isEligibleForReturn && productVariant) {
      return [
        {
          productVariant,
          quantity: remainingVariants,
          reason: "This product type is not eligible for return or exchange",
        },
      ];
    }
    return [];
  });

  const returnedProducts = selectedReasons.flatMap((reason) => {
    const productVariant = orderLinesDatabase.get(reason.productVariantId);
    if (productVariant) {
      return [
        {
          productVariant,
          quantity: reason.quantity,
          reason: reason.reason + reason.subReason,
        },
      ];
    }
    return [];
  });

  return [returnedLines, toReturnLines, productsNotSuitableForReturn, returnedProducts] as const;
};

export default useOrderData;
