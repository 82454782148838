import { ModalState } from "./ErrorModalContext";

interface ModalsState {
  /**
   * Modal that is currently open or was the last open one.
   * Keeping the last one is necessary for providing a clean exit transition.
   */
  current: ModalState | null;

  modals: ModalState[];
}

interface OpenAction {
  modal: ModalState;
  type: "OPEN";
}

interface CloseAction {
  canceled?: boolean;
  modalId: string;
  type: "CLOSE";
}

type Actions = OpenAction | CloseAction;

function handleCloseModal(modal: ModalState, canceled?: boolean) {
  // if (canceled && modal.type === "confirm") {
  //   modal.props.onCancel?.();
  // }

  modal.props.onClose?.();
}

function reducer(state: ModalsState, action: Actions) {
  switch (action.type) {
    case "OPEN": {
      return {
        current: action.modal,
        modals: [...state.modals, action.modal],
      };
    }
    case "CLOSE": {
      const modal = state.modals.find((m) => m.id === action.modalId);
      if (!modal) {
        return state;
      }

      handleCloseModal(modal);

      const remainingModals = state.modals.filter((m) => m.id !== action.modalId);

      return {
        current: remainingModals[remainingModals.length - 1] || state.current,
        modals: remainingModals,
      };
    }
    default: {
      return state;
    }
  }
}

export default reducer;
