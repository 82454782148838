import React from "react";
import { DefaultValues, useForm } from "react-hook-form";
import { useQuery, UseQueryOptions } from "react-query";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";

import { useFetchOrdersQuery } from "../api/graphql/schema.return.generated";

export const STALE_QUERY_OPTIONS = {
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchIntervalInBackground: false,
  refetchOnMount: false,
  staleTime: 0,
  cacheTime: 0,
  refetchInterval: 0,
  keepPreviousData: true,
  retry: false,
  retryOnMount: false,
} as const;

type FormQuery<TFormData extends Record<string, any>, TError> = {
  defaultValues: DefaultValues<TFormData>;
  formSchema: z.Schema;
  onError: (err: TError) => void;
  onSuccess: () => void;
  useQueryHook: (
    variables: Record<string, any>,
    options: UseQueryOptions,
  ) => ReturnType<typeof useQuery>;
};

const useLazyFormQuery = <TFormData extends Record<string, any>, TError extends unknown>({
  defaultValues,
  onSuccess,
  onError,
  formSchema,
  useQueryHook,
}: FormQuery<TFormData, TError>) => {
  // const queryVariable = React.useRef<TFormData>();
  const [parameters, setParameters] = React.useState<TFormData>();

  const {
    handleSubmit,
    register,
    formState: { errors: formErrors },
  } = useForm({
    defaultValues,
    resolver: zodResolver(formSchema),
  });

  // const {} = useQueryHook()

  const { data, refetch, isLoading, isError } = useQueryHook(parameters, {
    onSuccess,
    onError,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
    refetchInterval: 0,
    keepPreviousData: true,
    retry: false,
    retryOnMount: false,
  });

  const handleForm = () => {
    handleSubmit((formData: TFormData) => {
      setParameters(formData);
    })();
  };

  React.useEffect(() => {
    if (parameters) {
      refetch();
    }
  }, [parameters]);

  return { handleForm, register, isLoading, data, isError, formErrors };
};

export default useLazyFormQuery;
