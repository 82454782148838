import { createUseStyles, createTheming } from "react-jss";

function createBreakpoints() {
  const values = {
    xs: 0,
    sm: 768,
    md: 1024,
    lg: 1440,
    xl: 1920,
    mobileMin: 320,
    mobileMax: 767,
    tabletPortraitMin: 768,
    tabletPortraitMax: 1023,
    tabletLandscapeMin: 1024,
    tabletLandscapeMax: 1279,
    desktopMin: 1280,
    fiveK: 2560,
  };
  const keys = ["xs", "sm", "md", "lg", "xl", "fiveK"];
  const unit = "px";
  const step = 5;

  function up(key) {
    const value = typeof values[key] === "number" ? values[key] : key;
    return `@media (min-width:${value}${unit})`;
  }

  function down(key) {
    const endIndex = keys.indexOf(key) + 1;
    const upperbound = values[keys[endIndex]];

    if (endIndex === keys.length) {
      // xl down applies to all sizes
      return up("xs");
    }

    const value = typeof upperbound === "number" && endIndex > 0 ? upperbound : key;
    return `@media (max-width:${value - step / 100}${unit})`;
  }

  function between(start, end) {
    const endIndex = keys.indexOf(end);

    if (endIndex === keys.length - 1) {
      return up(start);
    }

    return (
      `@media (min-width:${typeof values[start] === "number" ? values[start] : start}${unit}) and ` +
      `(max-width:${(endIndex !== -1 && typeof values[keys[endIndex + 1]] === "number" ? values[keys[endIndex + 1]] : end) -
      step / 100
      }${unit})`
    );
  }

  function only(key) {
    return between(key, key);
  }

  function width(key) {
    return values[key];
  }

  return {
    keys,
    values,
    up,
    down,
    between,
    only,
    width,
  };
}

export function createSpacing(spacingInput) {
  let transform;

  if (typeof spacingInput === "function") {
    transform = spacingInput;
  } else {
    if (typeof spacingInput !== "number") {
      console.error(
        [
          `PG-UI: the \`theme.spacing\` value (${spacingInput}) is invalid.`,
          "It should be a number or a function.",
        ].join("\n"),
      );
    }

    transform = (factor = 1) => {
      if (typeof factor !== "number") {
        console.error(`Expected spacing argument to be a number, got ${factor}`);
      }
      return spacingInput * factor;
    };
  }

  const spacing = (...args) => {
    if (!(args.length <= 4)) {
      console.error(`PG-UI: Too many arguments provided, expected between 0 and 4, got ${args.length}`);
    }

    if (args.length === 0) {
      return transform(1);
    }

    return args
      .map(factor => {
        const output = transform(factor);
        return typeof output === "number" ? `${output}px` : output;
      })
      .join(" ");
  };

  return spacing;
}

const aspectRatio = {
  defaultImageAspectRatio: 1560 / 2059,
};

const spacingUnit = 8;
const color = {
  darkGray: "#727272",
  charcoalGray: "#393938",
  white: "#ffffff",
  lightGray: "#F0F0F0",
  inactiveGray: "#8E8E8D",
  grey: "#0000029",
  black: "#000000",
  success: "#b2bb1c"
};

const typography = {
  secondaryFontFamily: '"nimbus-roman", serif',
  primaryFontFamily: '"acumin-pro", sans-serif',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
};

const palette = {
  primary: {
    main: color.black,
  },
  secondary: {
    main: color.darkGray,
  },
  tertiary: {
    main: color.white,
  },
  error: {
    main: "#F25858",
  },
};

const zIndexValues = {
  theLowest: 5,
  lower: 6,
  low: 35,
  medium: 50,
  high: 65,
  higher: 80,
  theHighest: 100,
};

export const Theme = {
  color,
  palette,
  typography,
  breakpoints: createBreakpoints(),
  spacing: createSpacing(spacingUnit),
  zIndexValues,
  aspectRatio,
};

export type ThemeType = typeof Theme;

export default Theme;
