import { useBannersQuery } from "./graphql/operation.generated";

interface UseBannersProps {
  category: string;
  trigger: boolean;
}

const useBanners = ({ category, trigger }: UseBannersProps) =>
  useBannersQuery({ category }, { refetchOnWindowFocus: false, enabled: trigger });

export default useBanners;
