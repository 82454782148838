import React, { useState, useEffect, useContext, useRef } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useLocation } from "react-router-dom";
import "array-flat-polyfill";

import { gtmEnhancedEcommerce } from "@Utilities/gtm";
import {
  VariantGallery,
  Price,
  ProductDescription,
  NotifyMeButtonModule,
} from "@Components/molecules";
import { VariantPicker, Recommendations } from "@Components/organisms";
import { Typography, Container, Button } from "@Components/atoms";
import { getParams, getSKU, getFullSKU } from "./utils";
import { usePostData, useMediaQuery } from "@Hooks/index";
import { ModalsContext, SessionDataContext } from "@Context/index";
import { trackProductPage } from "@Utilities/criteo";
import useFetchXORecommendations from "@Utilities/useFetchXORecommendations";
import {
  PDP_RECOMMENDATIONS_WIDGET_ID,
  PDP_RECOMMENDATIONS_YMAL_WIDGET_ID,
} from "@Services/attraqt";
// TODO
// Add useFPViewContent, useGTMProductDetails on this page

const useStyles = createUseStyles((theme) => ({
  flexContainer: {
    display: "flex",
    minHeight: "500px",
    position: "relative",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  price: {
    height: "24px",
  },
  productPanel: {
    position: "relative",
    flex: 5,
    [theme.breakpoints.up("sm")]: {
      flexBasis: "50%",
    },
  },
  productGallery: {
    flex: 5,
    marginBottom: "2rem",
    [theme.breakpoints.up("sm")]: {
      flexBasis: "50%",
    },
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "350px",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("md")]: {
      width: "400px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20%",
    },
  },
  action: {
    width: "100%",
    maxWidth: "350px",
    margin: "0 1rem 1rem 1rem",
    "& button:first-child": {
      marginTop: "8px",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "34px",
    },
  },
}));

const ProductDetails = () => {
  let location = useLocation();
  const variantParams = getParams(location);
  const productID = variantParams.id;
  const initColor = variantParams.color;
  const initSize = variantParams.size;

  const [sessionData] = useContext(SessionDataContext);

  const theme = useTheme();
  const classes = useStyles(theme);
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const productPanelRef = useRef();

  const [loading, setLoading] = useState(true);
  const [panelHeight, setPanelHeight] = useState(0);
  const [activeElement, setActiveElement] = useState([0]);

  const { openModal } = useContext(ModalsContext);

  const [product, setProduct] = useState(null);
  const [returnPolicy, setReturnPolicy] = useState(null);

  const [currentVariantID, setCurrentVariantID] = useState(null);

  const fetchData = () =>
    // eslint-disable-next-line compat/compat
    fetch(`/api/products/${productID}/`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((body) => {
        // Add arr of all variantID to each variantGroup
        body.variants.forEach((element) => {
          const variantGroupIDArr = element.sizes.map((size) => size.variant_id);
          element["variant_group"] = variantGroupIDArr;
        });
        setProduct(body);
        const firstVariant = body.variants.find((variant) => variant.color.raw_value === initColor);
        const firstVariantID = firstVariant.sizes.find((x) => x.raw_value === initSize).variant_id;
        setCurrentVariantID(firstVariantID);

        setLoading(false);
      });

  useEffect(() => {
    fetchData();
  }, []);

  // variant data
  const currentVariant = product?.variants?.find((variant) =>
    variant?.variant_group?.includes(currentVariantID),
  );
  const currentVariantPrice = currentVariant?.stock_info_per_segment.default;
  const currentVariantSizes = currentVariant?.sizes;
  const currentVariantSize = currentVariantSizes?.find(
    (size) => size?.variant_id === currentVariantID,
  );
  const currentVariantColor = currentVariant?.color.raw_value;
  const currentVariantGallery = currentVariant?.product_images;
  const variantStatus = currentVariant?.status;
  const currentSKU = getSKU(product?.sku, currentVariantColor);
  const currentFullSKU = getFullSKU(
    product?.sku,
    currentVariantColor,
    currentVariantSize?.raw_value,
  );
  const isSale =
    currentVariant?.stock_info_per_segment?.default.retail_price >
    currentVariant?.stock_info_per_segment?.default.price;
  const isComingSoon = variantStatus === "comingsoon";
  const isNewArrivals = currentVariant?.collectionsList?.includes("new-arrivals");
  const isNewSeason = currentVariant?.collectionsList?.includes("new-season");

  const currentVariantTag = {
    sale: isSale,
    comingSoon: isComingSoon,
    new: isNewArrivals,
    newSeason: isNewSeason,
  };

  // outOfStock
  const outOfStock = currentVariantSizes?.find(
    (item) => item.variant_id === currentVariantID,
  )?.in_stock;
  const isOutOfStock = variantStatus === "out_of_stock" || !outOfStock;
  const outOfStockStatus = variantStatus === "out_of_stock";

  //voucher
  const voucher = currentVariant?.category === "Voucher";

  // user data
  const isAuth = !!sessionData.is_authenticated;

  const {
    loading: recommendationsLoading,
    xoWidgetMetadata,
    recommendations,
  } = useFetchXORecommendations({
    userId: sessionData?.user_id,
    recommendationId: PDP_RECOMMENDATIONS_WIDGET_ID,
    enable: currentSKU && product?.category_ancestors_list[0]?.slug,
    variables: {
      $productId: currentSKU,
      $mainCategory: product?.category_ancestors_list[0]?.slug,
    },
  });

  const {
    loading: YMALrecommendationsLoading,
    xoWidgetMetadata: YMALxoWidgetMetadata,
    recommendations: YMALrecommendations,
  } = useFetchXORecommendations({
    userId: sessionData?.user_id,
    recommendationId: PDP_RECOMMENDATIONS_YMAL_WIDGET_ID,
    enable: currentSKU && product?.category_ancestors_list[0]?.slug,
    variables: {
      $productId: currentSKU,
      $mainCategory: product?.category_ancestors_list[0]?.slug,
    },
  });

  useEffect(() => {
    if (currentVariant) {
      gtmEnhancedEcommerce(
        "productDetailsView",
        [
          {
            id: currentVariant.sku_color,
            name: currentVariant.name,
            category: product.category_slug,
            variant: currentVariantSize.raw_value,
          },
        ],
        "PRODUCT DETAILS PAGE",
      );
    }
  }, [loading]);

  useEffect(() => {
    // eslint-disable-next-line compat/compat
    fetch(`/api/products/return-policy/`)
      .then((res) => res.json())
      .then((returnPolicy) => {
        setReturnPolicy(returnPolicy);
      });
  }, []);

  useEffect(() => {
    if (!loading) {
      setPanelHeight(productPanelRef.current.clientHeight);
    }
  }, [loading]);

  const { res: resBag, callAPI: addToBag } = usePostData({
    url: "/api/cart/add/",
    payload: JSON.stringify({
      variant: currentVariantID,
    }),
    contentType: "application/json",
  });

  useEffect(() => {
    if (resBag.success) {
      openModal("bagWidgetModal");
      gtmEnhancedEcommerce(
        "addToCart",
        [
          {
            id: currentVariant.sku_color,
            name: currentVariant.name,
            category: product.category_slug,
            variant: currentVariantSize.raw_value,
            quantity: 1,
          },
        ],
        "PRODUCT DETAILS PAGE",
      );
    }
  }, [resBag]);

  useEffect(() => {
    if (sessionData.csrf_token && currentFullSKU) {
      trackProductPage(sessionData.hashed_email, currentFullSKU);
    }
  }, [JSON.stringify(sessionData), currentFullSKU]);

  return (
    <div>
      <div className="hidden" id="criteo-track-product" data-product-id={currentSKU} />
      <Container disableGutters>
        <div className={classes.flexContainer}>
          <div className={classes.productGallery}>
            <VariantGallery
              images={currentVariantGallery}
              panelHeight={panelHeight}
              currentVariantTag={currentVariantTag}
            />
          </div>
          <div ref={productPanelRef} className={classes.productPanel}>
            <div className={classes.box}>
              <Typography
                align="center"
                variant="h1"
                fontSize="18px"
                gutterBottom={false}
                loading={loading}
              >
                {product?.name}
              </Typography>
              {!loading && isOutOfStock && !isComingSoon && (
                <Typography
                  color="secondary"
                  fontSize={matches ? "16px" : "18px"}
                  gutterBottom={false}
                >
                  Out of Stock
                </Typography>
              )}
              <Typography fontSize={matches ? "16px" : "18px"} gutterBottom={false}>
                {!loading && (
                  <Price
                    price={currentVariantPrice?.price}
                    retailPrice={currentVariantPrice?.retail_price}
                  />
                )}
              </Typography>
              {!voucher && (
                <VariantPicker
                  loading={loading}
                  variants={product?.variants}
                  sizes={currentVariantSizes}
                  setCurrentVariantID={setCurrentVariantID}
                  currentVariantID={currentVariantID}
                  outOfStock={outOfStockStatus}
                />
              )}
              <div className={classes.action}>
                {isOutOfStock || isComingSoon ? (
                  <NotifyMeButtonModule
                    isAuth={isAuth}
                    pageLoading={loading}
                    currentVariantID={currentVariantID}
                  />
                ) : (
                  <Button
                    fluid
                    color="primary"
                    loading={loading && !sessionData}
                    disabled={resBag.isLoading}
                    onClick={addToBag}
                    data-test="add"
                  >
                    Add to bag
                  </Button>
                )}
              </div>
              <ProductDescription
                loading={loading}
                description={product?.description}
                returnPolicy={returnPolicy}
                sku={currentSKU}
                activeElement={activeElement}
                setActiveElement={setActiveElement}
              />
              {/* {productCategories && (
                <Breadcrumbs>
                  {productCategories.map(category => (
                    <Link key={category.title} href={`/category/${category.path}`}>
                      {capitalize(category.title)}
                    </Link>
                  ))}
                </Breadcrumbs>
              )} */}
            </div>
          </div>
        </div>
      </Container>
      {!recommendationsLoading && recommendations.length > 0 && (
        <>
          <Recommendations
            loading={recommendationsLoading}
            recommendations={recommendations}
            xoWidgetMetadata={xoWidgetMetadata}
            tracking="YOU MIGHT ALSO LIKE"
          />
        </>
      )}
      {!YMALrecommendationsLoading && YMALrecommendations.length > 0 && (
        <>
          <Recommendations
            loading={YMALrecommendationsLoading}
            recommendations={YMALrecommendations}
            xoWidgetMetadata={YMALxoWidgetMetadata}
            tracking={YMALxoWidgetMetadata.name}
          />
        </>
      )}
    </div>
  );
};

ProductDetails.propTypes = {
  location,
  history,
};

export default ProductDetails;
