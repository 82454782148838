/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import root from "react-shadow/emotion";

import { createEmotionCache, EmotionCache, MantineProvider } from "@mantine/core";

import MantineTheme from "../theme/mantine/theme";

export function IsolatedMantineProvider({ children, id }: React.PropsWithChildren<{ id: string }>) {
  const emotionStyleRoot = React.useRef<HTMLDivElement>();
  const createEmotionCacheRef = React.useRef<EmotionCache>();

  const emotionRoot = document.createElement("div");

  createEmotionCacheRef.current = createEmotionCache({
    container: emotionRoot,
    key: id,
  });

  useEffect(() => {
    emotionStyleRoot?.current?.shadowRoot?.appendChild(emotionRoot);
  }, []);

  return (
    <MantineProvider
      withNormalizeCSS
      theme={MantineTheme}
      emotionCache={createEmotionCacheRef.current}
    >
      <root.div id="shadow" ref={emotionStyleRoot} mode="open">
        <style id={`${id}-styles`} />
        <div id={id}>{children}</div>
      </root.div>
    </MantineProvider>
  );
}

export default IsolatedMantineProvider;
