import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";

interface TextFieldOptions {
  backgroundColor?: string;
  defaultValue?: string;
  error?: boolean;
  errorText?: string;
  isRequired?: boolean;
  label?: string;
  outline?: boolean;
  underline?: boolean;
}

interface TextFieldProps
  extends TextFieldOptions,
  Omit<React.ComponentPropsWithoutRef<"input">, keyof TextFieldOptions> {}

const useStyles = createUseStyles((theme) => {
  const placeholder = {
    color: "currentColor",
    opacity: 0.5,
  };

  const placeholderHidden = {
    opacity: "0 !important",
  };

  const placeholderVisible = {
    opacity: 0.5,
  };

  return {
    root: {
      height: "50px",
      position: "relative",
      width: "100%",
      minWidth: "100%",
      fontSize: "14px",
      color: "black",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "50ch",
      display: "inline-flex",
      flexDirection: "column",
      "@global": {
        input: {
          width: "90%",
          alignSelf: "center",
          padding: "10px 0",
          margin: "0 10px",
        },
      },
    },
    label: {
      position: "absolute",
    },
    outline: {
      border: `1px solid ${theme.color.darkGray}`,
    },
    error: {
      color: theme.palette.error.main,
      border: `1px solid ${theme.palette.error.main}`,
    },
    errorText: {
      color: theme.palette.error.main,
      height: "17px",
      marginBottom: 0,
      visibility: (props: TextFieldProps) => (props.error ? "visible" : "hidden"),
    },
    underline: {
      "&:after": {
        borderBottom: `2px solid ${theme.color.black}`,
        left: 0,
        bottom: 0,
        content: '""',
        position: "absolute",
        right: 0,
        transform: "scaleX(0)",
        pointerEvents: "none",
      },
      "&:before": {
        borderBottom: `1px solid ${theme.color.black}`,
        left: 0,
        bottom: 0,
        content: '"\\00a0"',
        position: "absolute",
        right: 0,
        pointerEvents: "none",
      },
    },
    backgroundColor: {
      backgroundColor: (props: TextFieldProps) => props.backgroundColor,
    },

    styledInput: {
      font: "inherit",
      letterSpacing: "inherit",
      color: "currentColor",
      padding: "4px 0 5px",
      border: 0,
      boxSizing: "content-box",
      background: "none",
      boxShadow: "none",
      margin: 0,
      WebkitTapHighlightColor: "transparent",
      display: "block",

      minWidth: 0,
      width: "100%",
      "&::-webkit-input-placeholder": placeholder,
      "&::-moz-placeholder": placeholder,
      "&:-ms-input-placeholder": placeholder,
      "&::-ms-input-placeholder": placeholder,
      "&:focus": {
        outline: "none",
        boxShadow: "none",
        border: "none",
      },
      "&:invalid": {
        boxShadow: "none",
      },
      "&::-webkit-search-decoration": {
        "-webkit-appearance": "none",
      },
      "label[data-shrink=false] + $formControl &": {
        "&::-webkit-input-placeholder": placeholderHidden,
        "&::-moz-placeholder": placeholderHidden, // Firefox 19+
        "&:-ms-input-placeholder": placeholderHidden, // IE 11
        "&::-ms-input-placeholder": placeholderHidden, // Edge
        "&:focus::-webkit-input-placeholder": placeholderVisible,
        "&:focus::-moz-placeholder": placeholderVisible, // Firefox 19+
        "&:focus:-ms-input-placeholder": placeholderVisible, // IE 11
        "&:focus::-ms-input-placeholder": placeholderVisible, // Edge
      },
      "&$disabled": {
        opacity: 1, // Reset iOS opacity
      },
      "&:-webkit-autofill": {
        animationDuration: "5000s",
        animationName: "mui-auto-fill",
      },
    },
  };
});

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(function TextField(
  {
    outline,
    underline,
    label,
    isRequired,
    defaultValue,
    error,
    errorText,
    backgroundColor = "transparent",
    ...attributes
  },
  ref,
) {
  const { className } = attributes;

  const classes = useStyles({ backgroundColor, error });
  const styledClasses = classNames(
    classes.root,
    className,
    underline && classes.underline,
    outline && classes.outline,
    error && classes.error,
    backgroundColor && classes.backgroundColor,
  );

  function Label() {
    return (
      <label className={classes.label}>
        {label}
        {isRequired && <span>*</span>}
      </label>
    );
  }

  function Error({ children = "" }) {
    return <p className={classes.errorText}>{children}</p>;
  }

  return (
    <div>
      <div className={styledClasses}>
        {label ? <Label /> : null}
        <input
          defaultValue={defaultValue}
          type="text"
          ref={ref}
          {...attributes}
          className={classes.styledInput}
        />
      </div>
      <Error>{errorText}</Error>
    </div>
  );
});

export default TextField;
