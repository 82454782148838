const CONTENTFUL_SPACE_ID = process.env.CONTENTFUL_SPACE_ID || "qh4760agmiux";
const CONTENTFUL_TOKEN =
  process.env.CONTENTFUL_TOKEN || "QCnDhXQLtp-LCH6lebrYxRQxSt-yDSKXP7i6W15iyjw";
let CONTENTFUL_ENVIRONMENT = "dev";

if (window.location.hostname === "www.prettygreen.com") {
  CONTENTFUL_ENVIRONMENT = "master";
} else if (window.location.hostname === "www.stage.prettygreen.com") {
  CONTENTFUL_ENVIRONMENT = "stage";
} else if (window.location.hostname === "www.qa.prettygreen.com") {
  CONTENTFUL_ENVIRONMENT = "qa";
} else {
  CONTENTFUL_ENVIRONMENT = "stage";
}

const CONTENTFUL_URL = `https://graphql.contentful.com/content/v1/spaces/${CONTENTFUL_SPACE_ID}/environments/${CONTENTFUL_ENVIRONMENT}`;

export function fetcher<TData, TVariables>(query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(CONTENTFUL_URL, {
      method: "POST",
      ...{
        headers: {
          authorization: `Bearer ${CONTENTFUL_TOKEN}`,
          "content-type": "application/json",
        },
        body: JSON.stringify({ query, variables }),
      },
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  };
}

export default fetcher;
