import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  imgContainer: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  rightSideImg: {
    [theme.breakpoints.up("sm")]: {
      extend: "imgContainer",
      flexDirection: "row-reverse",
    },
  },
  sideImg: {
    flex: "1",
    "& img": {
      width: "100%",
    },
  },
  sideImgContentContainer: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    [theme.breakpoints.up("md")]: {
      flex: "1",
    },
  },
  sideImgContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1.5rem",
    [theme.breakpoints.up("sm")]: {
      flexBasis: "80%",
    },
    [theme.breakpoints.up("md")]: {
      flexBasis: "60%",
    },
  },
}));

export default useStyles;
