import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  colorPickerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "1rem"
  },
});
