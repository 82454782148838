import { createUseStyles } from "react-jss";

export interface MediaBlockStyleProps {
  space?: number;
}

const useStyles = createUseStyles({
  buttonPositionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "2rem",
  },
});

export default useStyles;
