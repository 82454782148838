import { createUseStyles } from "react-jss";

export interface MediaBlockStyleProps {
  space?: number;
}

const useStyles = createUseStyles((theme) => ({
  shopTheLook: {},
  shopTheLook__text: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    color: "#C4C4C4",
    cursor: "pointer",
    lineHeight: 0,
  },
  shopTheLook__link: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    color: "#C4C4C4 !important",
    cursor: "pointer",
    lineHeight: "18px",
  },
  shopTheLook__icon: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "22px",
    color: "#C4C4C4",
    cursor: "pointer",
    lineHeight: 0,
  },
  customDropdown: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "transparent",
    margin: 0,
    padding: 0,
    width: "auto",
    [theme.breakpoints.down("xs")]: {
      overflowY: "scroll",
      height: "auto !important",
    },
  },
}));

export default useStyles;
