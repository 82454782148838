import React from "react";

import { Box, Link, ProductThumbnail, Typography } from "@Components/atoms";
import Price from "@Components/molecules/Price";
import Status from "@Components/molecules/Status";

import { useStyles } from "./style";

type VariantStatus = "available" | "comingsoon" | "outofstock";

interface VariantProps {
  alt: string;
  coverImage: string;
  inStock: boolean;
  isNew: boolean;
  isNewSeason: boolean;
  mainImage: string;
  onClick?: () => void;
  price: number;
  retailPrice: number;
  status: VariantStatus;
  title: string;
  url: string;
  variantOnSale: boolean;
}

const defaultProps = {
  onClick: () => undefined,
};

export function Variant({
  url,
  mainImage,
  coverImage,
  alt,
  title,
  price,
  retailPrice,
  inStock,
  variantOnSale,
  status,
  isNew,
  isNewSeason,
  onClick,
}: VariantProps) {
  const classes = useStyles();
  const isComingSoon = status === "comingsoon";
  return (
    <Link href={url} className={classes.productImage} onClick={onClick}>
      <ProductThumbnail mainImg={mainImage} coverImg={coverImage} alt={alt}>
        <Status
          isOnSale={variantOnSale}
          isComingSoon={isComingSoon}
          isNew={isNew}
          isNewSeason={isNewSeason}
        >
          {(statusTag) =>
            statusTag ? (
              <Box position="absolute" bottom="12px" left="12px">
                {statusTag}
              </Box>
            ) : null
          }
        </Status>
      </ProductThumbnail>
      <Typography component="h2" variant="newParagraph" gutterSide data-test="product-name">
        {title}
      </Typography>
      {inStock || isComingSoon ? (
        <Typography component="span" variant="newParagraph" display="inline">
          <Price retailPrice={retailPrice} price={price} data-test="PLP-price" />
        </Typography>
      ) : (
        <Typography
          component="span"
          variant="newParagraph"
          color="secondary"
          gutterBottom={false}
          data-test="out-of-stock"
          display="block"
        >
          Out of Stock
        </Typography>
      )}
    </Link>
  );
}

Variant.defaultProps = defaultProps;

export default Variant;
