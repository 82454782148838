import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";

import { returnFetcher } from "../../../../../globals/returnFetcher";
import * as Types from "../../../../../globals/returnTypes";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  Boolean: boolean;
  Datetime: any;
  Float: number;
  ID: string;
  Int: number;
  String: string;
  Upload: any;
};

export type OverwriteReturnDaysWithFixedDate = {
  dateToSet: Scalars["Datetime"];
  fromDate: Scalars["Datetime"];
  to: Scalars["Datetime"];
};

export type ReturnOrderedItem = {
  productVariantId: Scalars["String"];
  quantity: Scalars["Int"];
  reason: Scalars["String"];
};

export type SetOrderReturnDataInput = {
  daysToExtend: Scalars["Int"];
  orderId: Scalars["String"];
};

export type SetReturnMethodInput = {
  isActive: Scalars["Boolean"];
  optionName: Scalars["String"];
  price: Scalars["Float"];
};

export type UpdateReturnSettingsDataInput = {
  daysToReturnOverwrite: InputMaybe<Scalars["Int"]>;
  overwriteReturnDaysWithFixedDate: InputMaybe<OverwriteReturnDaysWithFixedDate>;
};

export type FetchOrdersQueryVariables = Types.Exact<{
  email: Types.Scalars["String"];
  id: Types.Scalars["String"];
}>;

export type FetchOrdersQuery = {
  __typename: "Query";
  getOrder: {
    __typename: "GetOrderResponse";
    error: string | null;
    order: {
      __typename: "Order";
      created: any | null;
      currencyCode: string;
      email: string;
      id: string;
      lines: Array<{
        __typename: "OrderedLine";
        isEligibleForReturn: boolean;
        price: number;
        productColor: string;
        productImageUrl: string;
        productName: string;
        productSize: string;
        productVariantId: string;
        quantity: number;
      }>;
      returnData: Array<{
        __typename: "ReturnData";
        category: string | null;
        created: any | null;
        packageReference: string;
        productVariantId: string;
        quantity: number;
        reason: string | null;
        status: string | null;
        trackingUrl: string | null;
      }>;
      returnPossibleUntil: any | null;
      total: number;
    };
  };
};

export type ReturnOptionsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ReturnOptionsQuery = {
  __typename: "Query";
  reasonOptions: Array<
    | {
        __typename: "MultiReasonOption";
        name: string | null;
        reasons: Array<{ __typename: "SingleReasonOption"; name: string | null } | null> | null;
      }
    | { __typename: "SingleReasonOption"; name: string | null }
    | null
  >;
};

export type ReturnOrderMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
  id: Types.Scalars["String"];
  returnItem: Array<Types.ReturnOrderedItem> | Types.ReturnOrderedItem;
  returnMethod: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type ReturnOrderMutation = {
  __typename: "Mutation";
  returnOrder: {
    __typename: "ReturnOrderResponse";
    error: string | null;
    order: {
      __typename: "Order";
      returnData: Array<{
        __typename: "ReturnData";
        packageReference: string;
        productVariantId: string;
      }> | null;
    } | null;
  } | null;
};

export type ReturngMethodsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ReturngMethodsQuery = {
  __typename: "Query";
  getReturningMethods: Array<{
    __typename: "ReturnMethodResponse";
    optionName: string;
    price: number | null;
  } | null>;
};

export const FetchOrdersDocument = `
    query fetchOrders($email: String!, $id: String!) {
  getOrder(email: $email, orderId: $id) {
    order {
      id
      email
      total
      currencyCode
      created
      returnPossibleUntil
      returnData {
        quantity
        productVariantId
        packageReference
        trackingUrl
        category
        status
        reason
        created
        __typename
      }
      lines {
        __typename
        productColor
        productSize
        productName
        quantity
        isEligibleForReturn
        productVariantId
        productImageUrl
        price
      }
    }
    error
  }
}
    `;
export const useFetchOrdersQuery = <TData = FetchOrdersQuery, TError = unknown>(
  variables: FetchOrdersQueryVariables,
  options?: UseQueryOptions<FetchOrdersQuery, TError, TData>,
) =>
  useQuery<FetchOrdersQuery, TError, TData>(
    ["fetchOrders", variables],
    returnFetcher<FetchOrdersQuery, FetchOrdersQueryVariables>(FetchOrdersDocument, variables),
    options,
  );
export const ReturnOptionsDocument = `
    query returnOptions {
  reasonOptions {
    __typename
    ... on MultiReasonOption {
      name
      reasons {
        name
      }
    }
    ... on SingleReasonOption {
      name
    }
  }
}
    `;
export const useReturnOptionsQuery = <TData = ReturnOptionsQuery, TError = unknown>(
  variables?: ReturnOptionsQueryVariables,
  options?: UseQueryOptions<ReturnOptionsQuery, TError, TData>,
) =>
  useQuery<ReturnOptionsQuery, TError, TData>(
    variables === undefined ? ["returnOptions"] : ["returnOptions", variables],
    returnFetcher<ReturnOptionsQuery, ReturnOptionsQueryVariables>(
      ReturnOptionsDocument,
      variables,
    ),
    options,
  );
export const ReturnOrderDocument = `
    mutation returnOrder($email: String!, $id: String!, $returnItem: [ReturnOrderedItem!]!, $returnMethod: String) {
  returnOrder(
    orderId: $id
    email: $email
    returnedItems: $returnItem
    returnMethod: $returnMethod
  ) {
    error
    order {
      returnData {
        packageReference
        productVariantId
      }
    }
  }
}
    `;
export const useReturnOrderMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<ReturnOrderMutation, TError, ReturnOrderMutationVariables, TContext>,
) =>
  useMutation<ReturnOrderMutation, TError, ReturnOrderMutationVariables, TContext>(
    ["returnOrder"],
    (variables?: ReturnOrderMutationVariables) =>
      returnFetcher<ReturnOrderMutation, ReturnOrderMutationVariables>(
        ReturnOrderDocument,
        variables,
      )(),
    options,
  );
export const ReturngMethodsDocument = `
    query ReturngMethods {
  getReturningMethods {
    optionName
    price
  }
}
    `;
export const useReturngMethodsQuery = <TData = ReturngMethodsQuery, TError = unknown>(
  variables?: ReturngMethodsQueryVariables,
  options?: UseQueryOptions<ReturngMethodsQuery, TError, TData>,
) =>
  useQuery<ReturngMethodsQuery, TError, TData>(
    variables === undefined ? ["ReturngMethods"] : ["ReturngMethods", variables],
    returnFetcher<ReturngMethodsQuery, ReturngMethodsQueryVariables>(
      ReturngMethodsDocument,
      variables,
    ),
    options,
  );
