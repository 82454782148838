import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  footerContainer: {
    width: "100%",
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    color: "#1a1a1a",
  },
  mobileFooterContainer: {
    extend: "footerContainer",
    backgroundColor: `${theme.color.lightGray}`,
    paddingLeft: 0,
    paddingRight: 0,
  },
  formContainer: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "70%",
      justifyContent: "space-between",
    },
  },
}));

export default useStyles;
