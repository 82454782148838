import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  bannerBox: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  fullWidthBanner: {
    marginBottom: "11px",
    gridColumn: "1 / -1",
    gridRow: "span 1",
  },
  fourByFourBanner: {
    marginBottom: "11px",
    gridColumn: "span 2",
    gridRow: "span 2",
    [theme.breakpoints.up("md")]: {
      "& $descriptionBox": {
        minHeight: 50,
      },
    },
  },
  bannerImg: {
    marginBottom: "8px",
    "& > img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  rootAspectRatio: {
    position: "relative",
    "&:before": {
      height: 0,
      content: '""',
      display: "block",
      paddingBottom: "calc(132% + 56px)",
    },
    "& > :first-child": {
      position: "absolute",
      top: "0",
      right: "0",
      bottom: "0",
      left: "0",
      width: "100%",
    },
    "& > img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  descriptionBox: {},
}));

export default useStyles;
