import React from "react";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";

import {
  Anchor,
  Button,
  Container,
  createStyles,
  Space,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { IconExclamationCircle } from "@tabler/icons";

const useStyles = createStyles({
  fluidForm: {
    width: "100%",
  },
});

interface FormProps {
  disabled: boolean;
  formErrors: FieldErrors<FieldValues>;
  handleForm: () => void;
  registerInput: UseFormRegister<FieldValues>;
}

function ReturnPageLogin({ handleForm, registerInput, disabled, formErrors }: FormProps) {
  const { classes } = useStyles();

  return (
    <Container py="xl" size="xs">
      <Stack>
        <Title order={1} size="h2" mb="xl" align="center">
          CREATE A RETURN
        </Title>
        <Text size="sm">
          You can create a return and send back your purchase within 30 days of receipt.
        </Text>
        <Text size="sm">
          Items should be returned new, unused and with all Pretty Green tags still attached. For
          more information, view our{" "}
          <Anchor target="blank" href="/uk-delivery-and-returns/" underline inherit>
            Returns Policy
          </Anchor>
        </Text>
        <Text size="sm">
          To return any orders from outside of the UK please visit our partners{" "}
          <Anchor target="blank" href="https://web.global-e.com/Returns/portal" underline inherit>
            returns portal
          </Anchor>
          .
        </Text>
        <div className={classes.fluidForm}>
          <TextInput
            label="Email address"
            {...registerInput("email")}
            error={formErrors?.email?.message as string}
            disabled={disabled}
            rightSection={
              formErrors?.email?.message ? <IconExclamationCircle width="80%" color="red" /> : null
            }
          />
          <TextInput
            label="Order number"
            {...registerInput("id")}
            error={formErrors?.id?.message as string}
            disabled={disabled}
            rightSection={
              formErrors?.id?.message ? <IconExclamationCircle width="80%" color="red" /> : null
            }
          />
          <Space h="md" />
          <Button onClick={handleForm} type="submit" fullWidth uppercase disabled={disabled}>
            Start return
          </Button>
        </div>
      </Stack>
    </Container>
  );
}
export default ReturnPageLogin;
