import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  mediaBlock: {
    position: "relative",
    height: "100%",
    width: "100%",
  },
  mediaBlock__shopTheLook: {
    zIndex: 0,
    position: "absolute",
    top: 20,
    left: 20,
  },
});

export default useStyles;
