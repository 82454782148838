import React, { useState, isValidElement, Children, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ModalsContext } from "@Context/index";

import { useStylesFilterContainer as useStyles } from './style'
import { clearQS } from "@Utilities/queryStringHelper";
import { Button, Typography, Accordion, ModalTemplate } from "@Components/atoms";
interface FilterContainerProps {
  children: React.ReactNode;
  currentProductAmount: number;
  currentFilterAmount: number;
}

export const FilterContainer = ({ children, currentProductAmount, currentFilterAmount }: FilterContainerProps) => {
  const [isOpen, setOpen] = useState([0]);
  const { closeModal } = useContext(ModalsContext);
  const classes = useStyles();
  let history = useHistory();

  const clearFilters = () => {
    clearQS(history);
  };

  const closeFilters = () => {
    closeModal("Filters");
  };

  return (
    <ModalTemplate>
      {{
        header: (
          <Typography fontWeight="bold" variant="caption" gutterBottom={false} textTransform="uppercase">
            Filters
          </Typography>
        ),
        content: (
          <Accordion activeElementKey={isOpen} onToggle={setOpen} type="dropdown">
            {Children.map(children, (child, i) => {
              if (isValidElement(child)) {
                return React.cloneElement(child, { elementKey: i });
              }
            })}
          </Accordion>
        ),
        navigation: (
          <div className={classes.modalNavigation}>
            {currentFilterAmount > 0 ? (
              <Button fluid color="secondary" onClick={clearFilters} className={classes.customButton}>
                Clear All ({currentFilterAmount})
              </Button>
            ) : null}
            <Button fluid color="primary" onClick={closeFilters}>
              {`View Products (${currentProductAmount})`}
            </Button>
          </div>
        ),
      }}
    </ModalTemplate>
  );
};

export default FilterContainer;
