import { Dispatch, SetStateAction, useEffect, useState } from "react";
import urlJoin from "url-join";

import { ATTRAQT_URI } from "@Services/index";

import { xoWidget } from "./gtm";

interface XORecommendations {
  enable: boolean;
  recommendationId?: string;
  setTrendingTitle?: Dispatch<SetStateAction<string>>;
  userId?: string;
  variables?: { [key: string]: string };
}

const useFetchXORecommendations = ({
  userId,
  setTrendingTitle,
  recommendationId,
  variables,
  enable,
}: XORecommendations) => {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [recommendations, setRecommendations] = useState([]);
  const [xoWidgetMetadata, setXoWidgetMetadata] = useState<xoWidget>();
  const isLoggedIn = !!userId;
  const attraqtSessionID = window.localStorage.getItem("attraqtsessionid") || "";
  useEffect(() => {
    if (enable) {
      const options = JSON.stringify(variables) ? `?variables=${JSON.stringify(variables)}` : "";
      const fetchXORecommendations = async () => {
        setLoading(true);

        const userIDURL = userId ? `profileid/${userId}` : "";
        const sessionIDURL = attraqtSessionID ? `sessionid/${attraqtSessionID}` : "";

        const trackingID = isLoggedIn ? userIDURL : sessionIDURL;

        const recommendationURL = urlJoin(
          ATTRAQT_URI,
          "widget",
          recommendationId || "",
          "recommendations",
          trackingID,
          options,
          "?metadata=widgetId",
        );

        // eslint-disable-next-line compat/compat
        let response = await fetch(recommendationURL);
        response = await response.json();
        const items = (response as any)?.recommendations;
        window.ebRecoIds = window.ebRecoIds || [];
        window.ebRecoIds.push(response.id);

        if (items?.length) {
          setRecommendations(items);
        }

        if (response) {
          setXoWidgetMetadata({
            id: (response as any)?.metadata?.widgetId,
            sourceId: (response as any)?.id,
            name: (response as any)?.widget?.title,
          });
        }

        const trendingTitle = (response as any)?.widget?.title;
        if (setTrendingTitle && trendingTitle) {
          setTrendingTitle(trendingTitle);
        }

        setLoading(false);
      };

      try {
        fetchXORecommendations();
      } catch (error) {
        setError((error as Error).message);
      }
    }
  }, [JSON.stringify(variables), enable]);

  return { error, loading, xoWidgetMetadata, recommendations };
};

export default useFetchXORecommendations;
