import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import ContentLoader from "react-content-loader";

import { useQueryString } from "@Hooks/index";
import { Typography, ColorBox } from "@Components/atoms";

const useStyles = createUseStyles(() => {
  const childWidth = `32px`;
  return {
    colorPicker: {
      display: "grid",
      flexWrap: "wrap",
      gridGap: "2px",
      justifyContent: "center",
      justifyItems: "center",
      gridTemplateColumns: `repeat(auto-fit, minmax(min(100%/5, max(${childWidth}, 100%/10)), ${childWidth}))`,
    },
    colorVariant: {
      cursor: "pointer",
    },
    container: {
      padding: "1rem",
    },
  };
});

const ColorPickerSkeleton = () => {
  return (
    <div style={{ width: `100%`, display: "flex", justifyContent: "center", alignItems: "center" }}>
      <ContentLoader width={146} height={32} viewBox="0 0 146 32">
        <rect x="0" y="0" rx="0" ry="0" width="32" height="32" />
        <rect x="38" y="0" rx="0" ry="0" width="32" height="32" />
        <rect x="76" y="0" rx="0" ry="0" width="32" height="32" />
        <rect x="114" y="0" rx="0" ry="0" width="32" height="32" />
      </ContentLoader>
    </div>
  );
};

const TextSkeleton = () => {
  return (
    <ContentLoader width={96} height={12} viewBox="0 0 96 12">
      <rect x="0" y="0" rx="0" ry="0" width="96" height="12" />
    </ContentLoader>
  );
};

export const ColorPicker = ({ variants, currentVariantID, setCurrentVariantID, loading }) => {
  const classes = useStyles();
  const [updateQS] = useQueryString();

  const currentVariant = variants?.find((variant) =>
    variant.variant_group.includes(currentVariantID),
  );
  const currentVariantSizeName = currentVariant?.sizes.find(
    (size) => size.variant_id === currentVariantID,
  ).raw_value;

  return (
    <div className={classes.container}>
      <Typography textTransform="capitalize" display="block" align="center">
        {loading ? <TextSkeleton /> : `Colour - ${currentVariant.color.display_value}`}
      </Typography>
      <div>
        {loading ? (
          <ColorPickerSkeleton />
        ) : (
          <div className={classes.colorPicker}>
            {variants.map((variant) => {
              const selected = variant.variant_group.includes(currentVariantID);
              const hexColor = variant.color.hexcolor;
              const colorRawValue = variant.color.raw_value;

              const variantIDByProperSize = variant.sizes.find(
                (size) => size.raw_value === currentVariantSizeName,
              )?.variant_id;

              return (
                <ColorBox
                  key={variant?.id}
                  selected={selected}
                  fill={hexColor}
                  handleClick={() => {
                    setCurrentVariantID(variantIDByProperSize.toString());
                    updateQS("color", colorRawValue);
                  }}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

ColorPicker.propTypes = {
  variants: PropTypes.arrayOf(PropTypes.object),
  setCurrentVariantID: PropTypes.func,
  currentVariantID: PropTypes.string,
  loading: PropTypes.bool,
};

export default ColorPicker;
