import * as Types from '../../../../../globals/types';

import { useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from '../../../../../globals/fetcher';
export type InlineLinkQueryVariables = Types.Exact<{
  linkId: Types.Scalars['String'];
}>;


export type InlineLinkQuery = { __typename?: 'Query', link: { __typename?: 'Link', url: string | null, externalName: string | null, openInNewWindow: boolean | null } | null };


export const InlineLinkDocument = `
    query InlineLink($linkId: String!) {
  link(id: $linkId) {
    url
    externalName
    openInNewWindow
  }
}
    `;
export const useInlineLinkQuery = <
      TData = InlineLinkQuery,
      TError = unknown
    >(
      variables: InlineLinkQueryVariables,
      options?: UseQueryOptions<InlineLinkQuery, TError, TData>
    ) =>
    useQuery<InlineLinkQuery, TError, TData>(
      ['InlineLink', variables],
      fetcher<InlineLinkQuery, InlineLinkQueryVariables>(InlineLinkDocument, variables),
      options
    );