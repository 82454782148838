import { DeepPartial } from "@Components/pages/ReturnApp/types";
import {
  ButtonProps,
  DividerProps,
  DrawerProps,
  MantineTheme,
  MantineThemeOverride,
  NumberInputProps,
  ProgressProps,
  TextInputProps,
} from "@mantine/core";

const DRAWER_GUTTERS = 24;
const DrawerDefaultProps: Partial<DrawerProps> = {
  closeOnEscape: true,
  closeOnClickOutside: true,
  withCloseButton: false,
  padding: "xl",
  size: "lg",
  position: "right",
  styles: (theme) => ({
    header: {
      textAlign: "center",
      marginRight: -DRAWER_GUTTERS,
      marginLeft: -DRAWER_GUTTERS,
    },
    title: {
      width: "100%",
      marginRight: 0,
    },
    drawer: { overflow: "scroll", backgroundColor: "#F7F7F7" },
  }),
};

const ButtonDefaultProps: Partial<ButtonProps> = {
  color: "dark.3",
  radius: "xs",
  size: "lg",
  uppercase: true,
  styles: (theme, params) => ({
    root: {
      fontSize: 14,
      backgroundColor: params.variant === "outline" ? "#fff" : undefined,
    },
  }),
};

const ProgressDefaultProps: Partial<ProgressProps> = {
  color: "dark.3",
  size: "sm",
  styles: () => ({
    root: {
      borderRadius: 0,
    },
  }),
};

const TEXT_INPUT_ERROR_SPACE = 20;
const TextInputDefaultProps: Partial<TextInputProps> = {
  radius: "xs",
  size: "lg",
  styles: (theme) => ({
    root: {
      fontSize: 14,
      position: "relative",
      // disable focus outline
      "& :focus": {
        borderColor: "unset !important",
      },
    },
    label: {
      fontSize: 14,
      fontWeight: "normal",
    },
    rightSection: {
      width: 52,
    },
    invalid: {
      borderWidth: 2,
    },
    wrapper: {
      marginBottom: TEXT_INPUT_ERROR_SPACE,
    },
    input: {
      boxShadow: "none",
      borderColor: theme.colors.dark[3],
    },
    error: {
      position: "absolute",
      bottom: -TEXT_INPUT_ERROR_SPACE + 4,
      fontSize: 12,
    },
  }),
};

const NumberInputDefaultProps: Partial<NumberInputProps> = {
  radius: "xs",
  size: "xs",
  styles: () => ({
    input: {
      marginBottom: "0 !important",
    },
  }),
};

const DividerDefaultProps: Partial<DividerProps> = {
  color: "black",
};

const RadioDefaultProps: Partial<NumberInputProps> = {
  color: "dark.3",
  styles: (theme) => ({
    icon: { color: theme.colors.dark[3] },
    radio: {
      background: theme.white,
      "&:checked": {
        backgroundColor: theme.white,
      },
    },
    inner: {
      height: "31px",
      display: "flex",
      alignItems: "center",
    },
  }),
};

const headings: DeepPartial<MantineTheme["headings"]> = {
  sizes: {
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.5rem",
    },
  },
};

const theme: MantineThemeOverride = {
  fontFamily: "acumin-pro, sans-serif",
  headings,
  components: {
    Button: { defaultProps: ButtonDefaultProps },
    NumberInput: { defaultProps: NumberInputDefaultProps },
    TextInput: { defaultProps: TextInputDefaultProps },
    Drawer: { defaultProps: DrawerDefaultProps },
    Progress: { defaultProps: ProgressDefaultProps },
    Radio: { defaultProps: RadioDefaultProps },
    Divider: { defaultProps: DividerDefaultProps },
  },
};

export default theme;
