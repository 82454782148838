import { SortTypes } from "./useCategoryParams";

type FilterType = {
  [key: string]: string[];
};

type VariantType = {
  product: {
    [key: string]: string | string[];
  };
};

const createFilters = (variants: VariantType[], filterParams: FilterType, sortBy: SortTypes) => {
  for (const filter in filterParams) {
    if (variants[0]?.product[filter] === undefined) {
      continue;
    }
    variants = variants.filter((variant) =>
      filterParams[filter].some((el) => variant.product[filter]?.includes(el)),
    );
    variants = variants.filter((variant) => variant.product.stock > 0);
  }

  switch (sortBy) {
    case "low":
      variants?.sort((a, b) => a.product.price - b.product.price);
      break;
    case "high":
      variants?.sort((a, b) => b.product.price - a.product.price);
      break;
    case "latest":
      variants?.sort((a, b) => {
        const date1 = new Date(a.product.productActiveSince);
        const date2 = new Date(b.product.productActiveSince);
        return date2 - date1;
      });
      break;
    case "popular":
      variants?.sort((a, b) => {
        const byDistribution = b?.distribution < a?.distribution;
        const byScore = b?.score - a?.score;
        return byDistribution || byScore;
      });
      break;
    default:
      return variants;
  }

  return variants;
};

export default createFilters;
