import React from "react";
import { createUseStyles, useTheme } from "react-jss";

import { ProductSkeleton, TextSkeleton, Typography, AspectRatio } from "@Components/atoms";
import { useMediaQuery } from "@Hooks/index";

const useStyles = createUseStyles((theme) => ({
  carousel: {
    display: "flex",
    "& > div": {
      flex: "1",
      margin: "0 5px",
      textAlign: "center",
    },
  },
  bannerLoader: {
    height: "100vh",
  },
  sideImg: {
    display: "flex",
    justifyContent: "space-around",
    "& div": {
      flexBasis: "98%",
      [theme.breakpoints.up("sm")]: {
        flexBasis: "49%",
      },
    },
  },
}));

const HomepageSkeleton = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const matchesTablet = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const carouselElements = matchesDesktop ? 4 : matchesTablet ? 3 : 2;
  const sideImgElements = matchesTablet ? 2 : 1;

  return (
    <>
      <div className={classes.bannerLoader}>
        <ProductSkeleton />
      </div>
      <div className={classes.carousel}>
        {[...Array(carouselElements)].map((_, i) => (
          <div key={i}>
            <AspectRatio ratio={theme.aspectRatio.defaultImageAspectRatio}>
              <ProductSkeleton />
            </AspectRatio>
            <Typography loading={true} />
          </div>
        ))}
      </div>
      <div className={classes.sideImg}>
        {[...Array(sideImgElements)].map((_, i) => (
          <div key={i}>
            <AspectRatio ratio={theme.aspectRatio.defaultImageAspectRatio}>
              <ProductSkeleton />
            </AspectRatio>
            <Typography>
              <TextSkeleton height={83} style={{ marginTop: "8px" }} />
            </Typography>
          </div>
        ))}
      </div>
    </>
  );
};

export default HomepageSkeleton;
