export const useFPSearch = (data, currency) => {
  const pixelData = {
    content_ids: [data.sku],
    content_name: data.name,
    search_string: data.search_string,
    currency,
    value: data.price,
    content_category: data.category,
  };
  fbq("track", "Search", pixelData);
};

export default useFPSearch;
