import { stringify } from "qs";

export const snakeToCapitalize = (text: string) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const addCurrencyToPrice = (value: number, currency = "GBP") => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency,
  }).format(value);
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const getReadableDataFormat = (date: Date) => {
  const formatedDate = new Date(date).toLocaleString("default", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  return formatedDate;
};

export const generateLabelLink = (orderId: string, packageReference: string, email: string) => {
  const returnAppEndoint =
    window.location.hostname === "www.prettygreen.com"
      ? "https://return-prod.app.prettygreen.com"
      : "https://return-dev.app.prettygreen.com";

  return `${returnAppEndoint}/order_label/?${stringify({
    order_id: orderId,
    package_order_reference: packageReference,
    email,
  })}`;
};
