export const DJANGO_ROUTES = [
  "/account",
  "/bag",
  "/checkout",
  "/dashboard",
  "/dpd",
  "/integrations",
  "/newsletter",
  "/order",
  "/page",
  "/search",
];

export default { DJANGO_ROUTES };
