import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { Infobar } from "@Components/molecules";
import { NotiStack } from "@Components/organisms";

import { useHeader } from "./api/fetchHeader";
import Navigation from "./Navigation";
import useStyles from "./style";

export function Header() {
  const classes = useStyles();
  const location = useLocation();
  const [activeElement, setActiveElement] = useState(null);

  const { isLoading, error, data } = useHeader();

  if (error) return null;
  const isHomaPage = location.pathname === "/";

  const infobarUk = data?.header?.infobarUkCollection;
  const infobarNonUk = data?.header?.infobarNonUkCollection;


  const dataDesktop = data?.header?.desktopNavigation
  const dataMobile = data?.header?.mobileNavigation
  const logo = data?.header?.logo
  console.log(infobarUk)

  return (
    <div id="header" className={isHomaPage ? classes.headerWrapperFixed : classes.headerWrapper}>
      {infobarUk?.items && infobarUk?.items?.length > 0 ? <Infobar data={infobarUk.items} type="uk" /> : null}
      {infobarNonUk?.items && infobarNonUk?.items?.length > 0 ? <Infobar data={infobarNonUk.items} type="nonUk" /> : null}
      <Navigation
        loading={isLoading}
        dataDesktop={dataDesktop}
        dataMobile={dataMobile}
        logo={logo}
        activeElementKey={activeElement}
        onToggle={setActiveElement}
      />
      <NotiStack />
    </div>
  );
}


export default Header;
