import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import classNames from "classnames";

const useStyles = createUseStyles(theme => ({
  dividerRoot: {
    margin: 0,
    minWidth: "100%",
    flexShrink: 0,
    borderWidth: 0,
    borderStyle: "solid",
    borderColor: props => (props.color === "dark" ? theme.color.darkGray : theme.color.lightGray),
    borderBottomWidth: "thin",
  },
  dividerAbsolute: {
    position: "absolute",
    left: 0,
    width: "100vw",
  },
  dividerInset: {
    marginLeft: 72,
  },
  dividerMiddle: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  dividerVertical: {
    height: "100%",
    borderBottomWidth: 0,
    borderRightWidth: "thin",
  },
}));

export const Divider = ({ absolute, className, variant, orientation, margin, color }) => {
  const theme = useTheme();
  const classes = useStyles({ color, theme });

  const styledClasses = classNames(classes.dividerRoot, className, {
    [classes.dividerAbsolute]: absolute,
    [classes.dividerMiddle]: variant === "middle",
    [classes.dividerInset]: variant === "inset",
    [classes.dividerVertical]: orientation === "vertical",
    [classes.dividerMargin]: margin,
  });

  return <hr className={styledClasses} />;
};

Divider.defaultProps = {
  absolute: false,
  className: null,
  variant: "fullWidth",
  orientation: "horizontal",
  margin: 2,
  color: "dark",
};

Divider.propTypes = {
  absolute: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["inset", "middle", "fullWidth"]),
  orientation: PropTypes.oneOf(["vertical", "horizontal"]),
  color: PropTypes.oneOf(["dark", "light"]),
  margin: PropTypes.number,
};

export default Divider;
