
import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles(theme => ({
    modalOverlay: {
      position: "fixed",
      left: 0,
      bottom: 0,
      right: 0,
      top: 0,
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      zIndex: theme.zIndexValues.higher,
    },
    modalOpacity: {
      opacity: "0.95",
    },
    modal: {
      color: "black",
      zIndex: theme.zIndexValues.higher,
      position: "fixed",
      backgroundColor: theme.color.white,
    },
    modalContent: {
      height: "100%",
      position: "relative",
    },
    SlideFromRight: {
      top: 0,
      right: 0,
      height: "100%",
    },
    SlideFromLeft: {
      top: 0,
      left: 0,
      height: "100%",
    },
    Fade: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    buttonPositionLeft: {
      right: "-65px",
    },
    buttonPositionRight: {
      left: "-65px",
    },
    customPosition: props => props.customPosition,
    FullScreen: {},
  }));