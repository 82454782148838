import * as Types from '../types';

export type AdvanceImageFormatFragment = { __typename?: 'Asset', url: string | null, urlJpg: string | null, urlLowQuality: string | null };

export type CoreLinkFragment = { __typename?: 'Link', externalName: string | null, internalName: string | null, url: string | null };

export type CoreMediaFragment = { __typename?: 'Media', externalName: string | null, internalName: string | null, altText: string | null, link: { __typename?: 'Link', externalName: string | null, internalName: string | null, url: string | null } | null, mediaDesktop: { __typename?: 'Asset', url: string | null, urlJpg: string | null, urlLowQuality: string | null } | null, mediaMobile: { __typename?: 'Asset', url: string | null, urlJpg: string | null, urlLowQuality: string | null } | null };

export type CoreNavigationItemFragment = { __typename?: 'NavigationItem', externalName: string | null, internalName: string | null, mainLink: { __typename?: 'Link', externalName: string | null, internalName: string | null, url: string | null } | null, navigationLinksCollection: { __typename?: 'NavigationItemNavigationLinksCollection', total: number, items: Array<{ __typename?: 'Link', externalName: string | null, internalName: string | null, url: string | null } | null> } | null, mediaCollection: { __typename?: 'NavigationItemMediaCollection', total: number, items: Array<{ __typename?: 'Media', externalName: string | null, internalName: string | null, altText: string | null, link: { __typename?: 'Link', externalName: string | null, internalName: string | null, url: string | null } | null, mediaDesktop: { __typename?: 'Asset', url: string | null, urlJpg: string | null, urlLowQuality: string | null } | null, mediaMobile: { __typename?: 'Asset', url: string | null, urlJpg: string | null, urlLowQuality: string | null } | null } | null> } | null, colour: { __typename?: 'TextColour', internalName: string | null, colourHex: string | null } | null };

export type DefaultStaticMediaFragment = { __typename: 'StaticMedia', internalName: string | null, externalName: string | null, altText: string | null, shopTheLook: Array<string | null> | null, sys: { __typename?: 'Sys', id: string }, imageDesktop: { __typename?: 'Asset', url: string | null, urlJpg: string | null, urlLowQuality: string | null } | null, imageMobile: { __typename?: 'Asset', url: string | null, urlJpg: string | null, urlLowQuality: string | null } | null, link: { __typename?: 'Link', url: string | null } | null };

export type DefaultButtonFragment = { __typename: 'Button', internalName: string | null, externalName: string | null, sys: { __typename?: 'Sys', id: string }, link: { __typename?: 'Link', url: string | null, openInNewWindow: boolean | null } | null };

export type DefaultVideoMediaFragment = { __typename: 'VideoMedia', altText: string | null, externalName: string | null, internalName: string | null, autoplay: boolean | null, loop: boolean | null, sys: { __typename?: 'Sys', id: string }, mediaDesktop: { __typename?: 'Asset', url: string | null } | null, mediaMobile: { __typename?: 'Asset', url: string | null } | null, link: { __typename?: 'Link', url: string | null, openInNewWindow: boolean | null } | null, posterDesktop: { __typename?: 'Asset', url: string | null, url_jpg: string | null, url_low_quality: string | null } | null, posterMobile: { __typename?: 'Asset', url: string | null, url_jpg: string | null, url_low_quality: string | null } | null };

export type DefaultFullWidthImageFragment = { __typename?: 'FullWidthImage', internalName: string | null, textColour: { __typename?: 'TextColour', colourHex: string | null } | null, content: { __typename?: 'ContentContainer', externalName: string | null, internalName: string | null, shortText: string | null, contentCollection: { __typename?: 'ContentContainerContentCollection', items: Array<{ __typename: 'Button', externalName: string | null, link: { __typename?: 'Link', url: string | null } | null } | { __typename?: 'InputField' } | { __typename?: 'Link' } | { __typename: 'Media', internalName: string | null, altText: string | null, mediaDesktop: { __typename?: 'Asset', contentType: string | null, url: string | null, urlJpg: string | null, urlLowQuality: string | null } | null, mediaMobile: { __typename?: 'Asset', contentType: string | null, url: string | null, urlJpg: string | null, urlLowQuality: string | null } | null, link: { __typename?: 'Link', url: string | null } | null } | { __typename?: 'Navigation' } | { __typename?: 'Product' } | null> } | null } | null };

export type DefaultImageWithTextFragment = { __typename?: 'ImageWithText', internalName: string | null, imagePosition: boolean | null, content: { __typename?: 'ContentContainer', externalName: string | null, internalName: string | null, shortText: string | null, richText: { __typename?: 'ContentContainerRichText', json: any } | null, contentCollection: { __typename?: 'ContentContainerContentCollection', items: Array<{ __typename: 'Button', externalName: string | null, link: { __typename?: 'Link', url: string | null } | null } | { __typename: 'InputField' } | { __typename: 'Link' } | { __typename: 'Media', altText: string | null, internalName: string | null, mediaDesktop: { __typename?: 'Asset', url: string | null, urlJpg: string | null, urlLowQuality: string | null } | null, link: { __typename?: 'Link', url: string | null } | null } | { __typename: 'Navigation' } | { __typename: 'Product' } | null> } | null } | null };

export type DefaultMultiColumnContentFragment = { __typename?: 'MultiColumnContent', internalName: string | null, contentCollection: { __typename?: 'MultiColumnContentContentCollection', items: Array<{ __typename?: 'ContentContainer', internalName: string | null, externalName: string | null, shortText: string | null, richText: { __typename?: 'ContentContainerRichText', json: any } | null, contentCollection: { __typename?: 'ContentContainerContentCollection', items: Array<{ __typename: 'Button' } | { __typename: 'InputField' } | { __typename: 'Link' } | { __typename: 'Media', internalName: string | null, altText: string | null, mediaDesktop: { __typename?: 'Asset', url: string | null, urlJpg: string | null, urlLowQuality: string | null } | null, link: { __typename?: 'Link', url: string | null } | null } | { __typename: 'Navigation' } | { __typename: 'Product' } | null> } | null } | null> } | null };

export type DefaultTextFragment = { __typename: 'Text', longText: { __typename?: 'TextLongText', json: any } | null, sys: { __typename?: 'Sys', id: string } };

export type DefaultSimpleCarouselFragment = { __typename: 'SimpleCarousel', internalName: string | null, externalName: string | null, products: Array<string | null> | null, xoWidget: { __typename?: 'XoWidget', internalName: string | null, externalName: string | null, widgetId: string | null } | null };

export const CoreLinkFragmentDoc = `
    fragment CoreLink on Link {
  externalName
  internalName
  url
}
    `;
export const AdvanceImageFormatFragmentDoc = `
    fragment AdvanceImageFormat on Asset {
  url(transform: {quality: 85, format: WEBP})
  urlJpg: url(transform: {quality: 85, format: JPG})
  urlLowQuality: url(transform: {quality: 1, format: JPG})
}
    `;
export const CoreMediaFragmentDoc = `
    fragment CoreMedia on Media {
  externalName
  internalName
  link {
    ...CoreLink
  }
  mediaDesktop {
    ...AdvanceImageFormat
  }
  mediaMobile {
    ...AdvanceImageFormat
  }
  altText
}
    `;
export const CoreNavigationItemFragmentDoc = `
    fragment CoreNavigationItem on NavigationItem {
  externalName
  internalName
  mainLink {
    ...CoreLink
  }
  navigationLinksCollection(limit: 9) {
    total
    items {
      ...CoreLink
    }
  }
  mediaCollection(limit: 9) {
    total
    items {
      ...CoreMedia
    }
  }
  colour {
    internalName
    colourHex
  }
}
    `;
export const DefaultStaticMediaFragmentDoc = `
    fragment DefaultStaticMedia on StaticMedia {
  __typename
  sys {
    id
  }
  internalName
  externalName
  altText
  imageDesktop {
    ...AdvanceImageFormat
  }
  imageMobile {
    ...AdvanceImageFormat
  }
  link {
    url
  }
  shopTheLook
}
    `;
export const DefaultButtonFragmentDoc = `
    fragment DefaultButton on Button {
  __typename
  sys {
    id
  }
  internalName
  externalName
  link {
    url
    openInNewWindow
  }
}
    `;
export const DefaultVideoMediaFragmentDoc = `
    fragment DefaultVideoMedia on VideoMedia {
  __typename
  sys {
    id
  }
  altText
  externalName
  internalName
  mediaDesktop {
    url
  }
  mediaMobile {
    url
  }
  link {
    url
    openInNewWindow
  }
  posterDesktop {
    url(transform: {quality: 85, format: WEBP})
    url_jpg: url(transform: {quality: 85, format: JPG})
    url_low_quality: url(transform: {quality: 5, format: JPG})
  }
  posterMobile {
    url(transform: {quality: 85, format: WEBP})
    url_jpg: url(transform: {quality: 85, format: JPG})
    url_low_quality: url(transform: {quality: 5, format: JPG})
  }
  autoplay
  loop
}
    `;
export const DefaultFullWidthImageFragmentDoc = `
    fragment DefaultFullWidthImage on FullWidthImage {
  internalName
  textColour {
    colourHex
  }
  content {
    externalName
    internalName
    shortText
    contentCollection {
      items {
        ... on Media {
          __typename
          internalName
          mediaDesktop {
            ...AdvanceImageFormat
            contentType
          }
          mediaMobile {
            ...AdvanceImageFormat
            contentType
          }
          altText
          link {
            url
          }
        }
        ... on Button {
          __typename
          externalName
          link {
            url
          }
        }
      }
    }
  }
}
    `;
export const DefaultImageWithTextFragmentDoc = `
    fragment DefaultImageWithText on ImageWithText {
  internalName
  imagePosition
  content {
    externalName
    internalName
    shortText
    richText {
      json
    }
    contentCollection(limit: 2) {
      items {
        __typename
        ... on Media {
          altText
          internalName
          mediaDesktop {
            ...AdvanceImageFormat
          }
          link {
            url
          }
        }
        ... on Button {
          externalName
          link {
            url
          }
        }
      }
    }
  }
}
    `;
export const DefaultMultiColumnContentFragmentDoc = `
    fragment DefaultMultiColumnContent on MultiColumnContent {
  internalName
  internalName
  contentCollection {
    items {
      internalName
      externalName
      shortText
      richText {
        json
      }
      contentCollection(limit: 2) {
        items {
          __typename
          ... on Media {
            internalName
            altText
            mediaDesktop {
              ...AdvanceImageFormat
            }
            link {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export const DefaultTextFragmentDoc = `
    fragment DefaultText on Text {
  __typename
  longText {
    json
  }
  sys {
    id
  }
}
    `;
export const DefaultSimpleCarouselFragmentDoc = `
    fragment DefaultSimpleCarousel on SimpleCarousel {
  __typename
  internalName
  externalName
  products
  xoWidget {
    internalName
    externalName
    widgetId
  }
}
    `;