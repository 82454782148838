import React from "react";
import { createUseStyles, useTheme } from "react-jss";

import {
  Carousel,
  ProductSkeleton,
  TextSkeleton,
  AspectRatio,
  Typography,
} from "@Components/atoms";
import { ConditionalWrapper } from "@Utilities/utils";
import { useMediaQuery } from "@Hooks/index";

const useStyles = createUseStyles((theme) => ({
  carousel: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "48px",
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
      paddingLeft: "48px",
    },
  },
  collectionList: {
    paddingLeft: "10px",
    [theme.breakpoints.up("sm")]: {
      width: "220px",
      padding: "0 10px 0 0",
    },
    "& .swiper-slide": {
      width: "auto",
    },
  },
  productsCarousel: {
    display: "flex",
    flexGrow: 1,
    marginTop: "-22px",
  },
  products: {
    flexGrow: 1,
    margin: "0 10px",
  },
}));

const CAROUSEL_LIST_SETTINGS = {
  loop: false,
  slidesPerView: "auto",
  spaceBetween: 30,
};
const CAROUSEL_SETTINGS = {
  loop: true,
  slidesPerView: 1.4,
  breakpoints: {
    200: {
      slidesPerView: 1.4,
      spaceBetween: 18,
    },
    768: {
      slidesPerView: 1.8,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 2.2,
      spaceBetween: 20,
    },
    2560: {
      slidesPerView: 4.4,
      spaceBetween: 20,
    },
  },
};

const IMAGE_PLACEHOLDER_RATIO = 1560 / 2059;

const HomepageCarouselSkeleton = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const matchesTablet = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const matchesFiveK = useMediaQuery(theme.breakpoints.up("fiveK"));

  const carouselElements = matchesFiveK ? 4 : matchesDesktop ? 3 : 1;

  return (
    <div className={classes.carousel}>
      <div className={classes.collectionList}>
        <ConditionalWrapper
          condition={!matchesTablet}
          wrapper={(children) => <Carousel {...CAROUSEL_LIST_SETTINGS}>{children}</Carousel>}
        >
          {[...Array(5)].map((_, i) => (
            <Typography key={i}>
              <TextSkeleton height="50" />
            </Typography>
          ))}
        </ConditionalWrapper>
      </div>
      <Carousel {...CAROUSEL_SETTINGS} className={classes.productsCarousel}>
        {[...Array(carouselElements)].map((_, i) => (
          <div key={i} className={classes.products}>
            <AspectRatio ratio={IMAGE_PLACEHOLDER_RATIO}>
              <ProductSkeleton />
            </AspectRatio>
            <Typography align="center" gutterBottom={false}>
              <TextSkeleton height={60} style={{ marginTop: "8px" }} />
            </Typography>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default HomepageCarouselSkeleton;
