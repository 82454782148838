import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  root: {
    display: "inline-block",
    color: theme.color.darkGray,
    fontSize: "inherit",
    [theme.breakpoints.up("md")]: {
      marginTop: "0",
    },
  },
}));

export default useStyles;
