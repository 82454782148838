import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles(theme => ({
    alarmRoot: {
      display: "flex",
      width: "100%",
      padding: ".5rem 30px",
      textAlign: "center",
      zIndex: theme.zIndexValues.higher,
    },
    alarmError: {
      backgroundColor: theme.color.lightGray,
    },
    alarmInfo: {
      backgroundColor: theme.color.charcoalGray,
    },
    alarmSuccess: {
      backgroundColor: theme.color.success,
    },
    alarmWarning: {
      backgroundColor: theme.color.lightGray,
    },
    closeButton: {
      width: "30px",
      height: "auto",
      cursor: "pointer",
      border: "none",
      margin: "0 -30px",
      "& svg": {
        height: "10px",
        width: "10px",
        stroke: theme.color.white,
      },
    },
    message: {
      color: theme.color.white,
      flex: 1
    }
  }));