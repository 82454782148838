import React from "react";
import { useTheme } from "react-jss";

import { Box, Carousel, Container, Link, ProductThumbnail, Typography } from "@Components/atoms";
import Price from "@Components/molecules/Price";
import Product from "@Components/molecules/Product";
import Status from "@Components/molecules/Status";
import { useMediaQuery } from "@Hooks/index";
import { gtmEnhancedEcommerce } from "@Utilities/gtm";
import { getAttraqtPrices } from "@Utilities/utils";

import { useStyles } from "./style";

const CAROUSEL_SETTINGS = {
  loop: true,
  loopedSlides: 3,
  loopAdditionalSlides: 3,
  breakpoints: {
    200: {
      slidesPerView: 2,
      spaceBetween: 5,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 10,
    },
  },
};

type RecommendationsProps = {
  loading: boolean;
  recommendations: [];
  tracking: string;
  xoWidgetMetadata: any;
};

const defaultProps = {
  userId: undefined,
};

export function Recommendations({
  recommendations,
  loading,
  xoWidgetMetadata,
  tracking,
}: RecommendationsProps) {
  const classes = useStyles();
  const theme = useTheme() as any;

  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const fontSize = isSmUp ? "14px" : "12px";

  return (
    <Container id="recommendations" disableGutters className={classes.container}>
      <Typography
        variant="h1"
        align="center"
        display="block"
        color="colorTextPrimary"
        fontSize="18px"
        paragraph
      >
        {xoWidgetMetadata?.name}
      </Typography>
      {recommendations ? (
        <Carousel {...CAROUSEL_SETTINGS}>
          {recommendations?.map(({ product, id }, i) => {
            const [retailPrice, price] = getAttraqtPrices(product?.retailPrice, product?.price);
            const isOnSale = product?.isOnSale === "sale";
            const isComingSoon = product?.status === "comingsoon";
            const isInStock = product?.stock !== 0;
            const isPriceVisible = isComingSoon || isInStock;
            const isNew = product?.collectionsList?.includes("new-arrivals");
            const isNewSeason = product?.collectionsList?.includes("new-season");

            return (
              <Link
                href={product?.url}
                key={id}
                onClick={() => {
                  gtmEnhancedEcommerce(
                    "impressionClick",
                    [
                      {
                        id,
                        name: product.title,
                        category: product.mainCategory,
                        price: price.toString(),
                        colour: product.colour,
                        size: product.sizes[0],
                        url: product.url,
                        image: product.photo,
                        position: i,
                      },
                    ],
                    tracking,
                    xoWidgetMetadata,
                  );
                }}
              >
                <Product>
                  <ProductThumbnail
                    mainImg={product?.photo}
                    coverImg={product?.secondImage}
                    alt={product?.title}
                    holdAspectRatio
                  >
                    <Status
                      isOnSale={isOnSale}
                      isComingSoon={isComingSoon}
                      isNew={isNew}
                      isNewSeason={isNewSeason}
                    >
                      {(statusTag) =>
                        statusTag ? (
                          <Box position="absolute" bottom="12px" left="12px">
                            {statusTag}
                          </Box>
                        ) : null
                      }
                    </Status>
                  </ProductThumbnail>
                  <Typography
                    align="center"
                    fontSize={fontSize}
                    gutterSide
                    data-test="product-name"
                  >
                    {product?.webname}
                  </Typography>
                  {isPriceVisible ? (
                    <Typography align="center" variant="caption" fontSize={fontSize}>
                      {!loading && <Price retailPrice={retailPrice} price={price} />}
                    </Typography>
                  ) : (
                    <Typography
                      align="center"
                      component="span"
                      variant="newParagraph"
                      color="secondary"
                      gutterBottom={false}
                      data-test="out-of-stock"
                      display="block"
                    >
                      Out of Stock
                    </Typography>
                  )}
                </Product>
              </Link>
            );
          })}
        </Carousel>
      ) : null}
    </Container>
  );
}

Recommendations.defaultProps = defaultProps;

export default Recommendations;
