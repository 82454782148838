import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles(theme => ({
    mainImage: {
      position: "absolute",
      height: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      objectFit: 'cover'
    },
    coverImage: {
      position: "absolute",
      height: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition: "0.2s",
      opacity: 0,
      objectFit: 'cover'
    },
    imageContainer: {
      marginBottom: theme.spacing(1),
      position: "relative",
      "&:hover $coverImage": {
        opacity: 1,
      },
    },
    aspectRatioHolder: {
      paddingBottom: "132%",
      height: 0,
    },
    invisible: {
      display: "none",
    },
  }));