import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  bannerContainer: {
    position: "relative",
    marginBottom: "8px",
  },
  bannerContent: {
    position: "absolute",
    bottom: "93px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "285px",
    minHeight: "116px",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.color.lightGray,
    opacity: "95%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      minWidth: "285px",
      maxWidth: "428px",
    },
  },
  videoPlayer: {
    "& video": {
      objectFit: "cover",
    },
  },
  imageBanner: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
}));

export default useStyles;
