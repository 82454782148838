import React from "react";

import { Modal } from "@mantine/core";

import ErrorModalContext, { ModalsContextProps, ModalSettings } from "./ErrorModalContext";
import { useModalsEvents } from "./events";
import modalsReducer from "./reducer";

export interface ModalsProviderProps {
  /** Your app */
  children: React.ReactNode;

  /** Confirm modal labels */
  // labels?: ConfirmLabels;

  /** Shared Modal component props, applied for every modal */
  // eslint-disable-next-line react/require-default-props
  modalProps?: ModalSettings;

  /** Predefined modals */
  // modals?: Record<string, React.FC<ContextModalProps<any>>>;
}

function ErrorModalProvider({ children, modalProps }: ModalsProviderProps) {
  const [state, dispatch] = React.useReducer(modalsReducer, { modals: [], current: null });
  // check if it generates random om use
  const randomId = React.useId();
  const stateRef = React.useRef(state);
  stateRef.current = state;

  const openModal = React.useCallback(
    ({ modalId, ...props }: ModalSettings) => {
      const id = modalId || randomId;
      dispatch({
        type: "OPEN",
        modal: {
          id,
          type: "content",
          props,
        },
      });
      return id;
    },
    [dispatch],
  );

  const closeModal = React.useCallback(
    (id: string, canceled?: boolean) => {
      dispatch({ type: "CLOSE", modalId: id, canceled });
    },
    [stateRef, dispatch],
  );

  useModalsEvents({ openModal, closeModal });

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const ctx: ModalsContextProps = {
    openModal,
    closeModal,
  };

  const getCurrentModal = () => {
    const currentModal = stateRef.current.current;
    switch (currentModal?.type) {
      case "content": {
        const { children: currentModalChildren, ...rest } = currentModal.props;

        return {
          modalProps: rest,
          // eslint-disable-next-line react/jsx-no-useless-fragment
          content: <>{currentModalChildren}</>,
        };
      }
      default: {
        return {
          modalProps: {},
          content: null,
        };
      }
    }
  };

  const { modalProps: currentModalProps, content } = getCurrentModal();

  return (
    <ErrorModalContext.Provider value={ctx}>
      <Modal
        opened={state.modals.length > 0}
        {...modalProps}
        {...currentModalProps}
        onClose={() => closeModal(state?.current?.id || "")}
      >
        {content}
      </Modal>
      {children}
    </ErrorModalContext.Provider>
  );
}

export default ErrorModalProvider;
