import * as Types from '../../../../../globals/types';

import { AdvanceImageFormatFragmentDoc } from '../../../../../globals/graphql/fragments.generated';
import { useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from '../../../../../globals/fetcher';
export type BannersQueryVariables = Types.Exact<{
  category: Types.Scalars['String'];
}>;


export type BannersQuery = { __typename?: 'Query', listingPageBanerCollection: { __typename?: 'ListingPageBanerCollection', items: Array<{ __typename?: 'ListingPageBaner', internalName: string | null, type: string | null, positionRow: string | null, positionAlign: string | null, content: { __typename?: 'ContentContainer', internalName: string | null, externalName: string | null, shortText: string | null, contentCollection: { __typename?: 'ContentContainerContentCollection', items: Array<{ __typename: 'Button' } | { __typename: 'InputField' } | { __typename: 'Link', url: string | null, openInNewWindow: boolean | null } | { __typename: 'Media', internalName: string | null, altText: string | null, mediaDesktop: { __typename?: 'Asset', url: string | null, urlJpg: string | null, urlLowQuality: string | null } | null, mediaMobile: { __typename?: 'Asset', url: string | null, urlJpg: string | null, urlLowQuality: string | null } | null, link: { __typename?: 'Link', url: string | null } | null } | { __typename: 'Navigation' } | { __typename: 'Product' } | null> } | null } | null, categoryId: { __typename?: 'CategoryId', categoryId: string | null } | null } | null> } | null };

export type QuickLinksQueryVariables = Types.Exact<{
  category: Types.Scalars['String'];
}>;


export type QuickLinksQuery = { __typename?: 'Query', quickLinksCollection: { __typename?: 'QuickLinksCollection', items: Array<{ __typename?: 'QuickLinks', externalName: string | null, internalName: string | null, categoryId: { __typename?: 'CategoryId', categoryId: string | null } | null, linkCollection: { __typename?: 'QuickLinksLinkCollection', items: Array<{ __typename?: 'Link', externalName: string | null, url: string | null, openInNewWindow: boolean | null } | null> } | null } | null> } | null };


export const BannersDocument = `
    query Banners($category: String!) {
  listingPageBanerCollection(where: {categoryId: {categoryId: $category}}) {
    items {
      internalName
      content {
        internalName
        externalName
        shortText
        contentCollection(limit: 2) {
          items {
            __typename
            ... on Media {
              internalName
              altText
              mediaDesktop {
                ...AdvanceImageFormat
              }
              mediaMobile {
                ...AdvanceImageFormat
              }
              link {
                url
              }
            }
            ... on Link {
              url
              openInNewWindow
            }
          }
        }
      }
      categoryId {
        categoryId
      }
      type
      positionRow
      positionAlign
    }
  }
}
    ${AdvanceImageFormatFragmentDoc}`;
export const useBannersQuery = <
      TData = BannersQuery,
      TError = unknown
    >(
      variables: BannersQueryVariables,
      options?: UseQueryOptions<BannersQuery, TError, TData>
    ) =>
    useQuery<BannersQuery, TError, TData>(
      ['Banners', variables],
      fetcher<BannersQuery, BannersQueryVariables>(BannersDocument, variables),
      options
    );
export const QuickLinksDocument = `
    query QuickLinks($category: String!) {
  quickLinksCollection(where: {categoryId: {categoryId: $category}}) {
    items {
      externalName
      internalName
      categoryId {
        categoryId
      }
      linkCollection {
        items {
          externalName
          url
          openInNewWindow
        }
      }
    }
  }
}
    `;
export const useQuickLinksQuery = <
      TData = QuickLinksQuery,
      TError = unknown
    >(
      variables: QuickLinksQueryVariables,
      options?: UseQueryOptions<QuickLinksQuery, TError, TData>
    ) =>
    useQuery<QuickLinksQuery, TError, TData>(
      ['QuickLinks', variables],
      fetcher<QuickLinksQuery, QuickLinksQueryVariables>(QuickLinksDocument, variables),
      options
    );