import { useCycle } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useTheme } from "react-jss";

import { Accordion, Container, Link, Modal, Typography } from "@Components/atoms/";
import { Burger } from "@Components/atoms/Icons";
import Dropdown from "@Components/molecules/Dropdown";
import { useMediaQuery } from "@Hooks/index";
import { ThemeType } from "@Theme/theme";

import { Navigation } from "../../../globals/types";
import CategoryCollection from "./CategoryCollection";
import MenuHeader from "./MenuHeader/MenuHeader";
import { useNavigationContext } from "./NavigationContext";
import NavigationItem from "./NavigationItem/NavigationItem";
import StaticAccordions from "./StaticAccordions/StaticAccordions";
import { useMenuStyles } from "./style";

interface MenuProps {
  collectiveColor: string;
  handleOpenMenu: (el: boolean) => void;
  menuDesktop: Navigation;
  menuMobile: Navigation;
}

export function Menu({ menuDesktop, menuMobile, handleOpenMenu, collectiveColor }: MenuProps) {
  const { onToggle, activeElementKey } = useNavigationContext();

  const theme = useTheme<ThemeType>();
  const classes = useMenuStyles();

  const navigationRoot = document.getElementById("navigation");

  const [isOpen, toggleOpen] = useCycle(false, true);

  const calculatedPosition = { top: "65px" };
  const [activeElement, setActiveElement] = useState(null);

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const activeMenu =
    activeElementKey === null
      ? null
      : menuDesktop?.navigationItemsCollection?.items[activeElementKey];
  const isDropdownActive = activeElementKey !== null;

  useEffect(() => {
    handleOpenMenu(isOpen);
  }, [isOpen]);

  return (
    <div className={classes.navMenuContainer}>
      {matches ? (
        <>
          {menuDesktop?.navigationItemsCollection?.items
            .filter((item) => item !== null)
            .map((menu, index) => {
              const uniqueColor = menu?.colour?.colourHex;
              const headerColor = uniqueColor || collectiveColor;
              return (
                <MenuHeader
                  key={menu?.internalName}
                  color={headerColor}
                  onToggle={() => onToggle(index)}
                >
                  {menu?.externalName}
                </MenuHeader>
              );
            })}
          <Dropdown
            isActive={isDropdownActive}
            className={classes.dropdownContainer}
            onMouseEnter={() => onToggle(null)}
          >
            <Container disableGutters>
              {activeMenu ? <CategoryCollection categories={activeMenu} /> : null}
            </Container>
          </Dropdown>
        </>
      ) : (
        <div>
          <Burger isOpen={isOpen} toggle={toggleOpen} color={collectiveColor} />
          <Modal
            isOpen={isOpen}
            hide={toggleOpen}
            animation="SlideFromLeft"
            overlay={false}
            customRoot={navigationRoot}
            opacity={false}
            customPosition={calculatedPosition}
          >
            <div className={classes.modal}>
              <div>
                <Accordion activeElementKey={activeElement} onToggle={setActiveElement}>
                  {menuMobile?.navigationItemsCollection?.items
                    .filter((item) => item !== null)
                    .map((navigationItem, index) => {
                      return (
                        <NavigationItem
                          key={navigationItem?.externalName}
                          colour={navigationItem?.colour}
                          externalName={navigationItem?.externalName}
                          internalName={navigationItem?.internalName}
                          mainLink={navigationItem?.mainLink}
                          mediaCollection={navigationItem?.mediaCollection}
                          navigationLinksCollection={navigationItem?.navigationLinksCollection}
                          navigationItemsCollection={navigationItem?.navigationItemsCollection}
                        >
                          {({ itemTitle, links, navigationItems, media, itemColor }) => (
                            <>
                              <Accordion.Toggle elementKey={index}>
                                <Typography
                                  variant="h2"
                                  fontFamily="primaryFont"
                                  fontSize="14px"
                                  gutterBottom={false}
                                  customColor={itemColor}
                                >
                                  {itemTitle}
                                </Typography>
                              </Accordion.Toggle>
                              <Accordion.Collapse elementKey={index}>
                                <ul className={classes.styledList}>
                                  {navigationItems.map((nestedNavigationItem) => (
                                    <NavigationItem
                                      key={nestedNavigationItem.externalName}
                                      colour={nestedNavigationItem.colour}
                                      externalName={nestedNavigationItem.externalName}
                                      internalName={nestedNavigationItem.internalName}
                                      mainLink={nestedNavigationItem.mainLink}
                                      mediaCollection={nestedNavigationItem.mediaCollection}
                                      navigationLinksCollection={
                                        nestedNavigationItem.navigationLinksCollection
                                      }
                                      navigationItemsCollection={
                                        nestedNavigationItem.navigationItemsCollection
                                      }
                                    >
                                      {({
                                        itemTitle: secondaryItemTitle,
                                        media: secondaryMedia,
                                        links: secondaryLinks,
                                      }) => (
                                        <>
                                          <li className={classes.listTitle}>
                                            {secondaryItemTitle}
                                          </li>
                                          {secondaryLinks.map((secondaryLink) => (
                                            <Link
                                              href={secondaryLink.url}
                                              key={secondaryLink.externalName}
                                            >
                                              <li>{secondaryLink.externalName}</li>
                                            </Link>
                                          ))}
                                          {secondaryMedia.map((secondaryMediaItem) => (
                                            <Link
                                              href={secondaryMediaItem?.link?.url}
                                              key={secondaryMediaItem.externalName}
                                            >
                                              {secondaryMediaItem?.mediaMobile?.url ? (
                                                <picture>
                                                  <source
                                                    srcSet={secondaryMediaItem.mediaMobile.url}
                                                    type="image/webp"
                                                  />
                                                  <img
                                                    className={classes.navigationItem}
                                                    src={secondaryMediaItem.mediaMobile.urlJpg}
                                                    alt={secondaryMediaItem.altText}
                                                  />
                                                </picture>
                                              ) : (
                                                <li>{secondaryMediaItem.externalName}</li>
                                              )}
                                            </Link>
                                          ))}
                                        </>
                                      )}
                                    </NavigationItem>
                                  ))}
                                  {links.map((link) => (
                                    <Link href={link.url} key={link.externalName}>
                                      <li>{link.externalName}</li>
                                    </Link>
                                  ))}
                                  {media.map((mediaItem) => (
                                    <Link href={mediaItem?.link?.url} key={mediaItem.internalName}>
                                      {mediaItem?.mediaMobile?.url ? (
                                        <picture>
                                          <source
                                            srcSet={mediaItem.mediaMobile.url}
                                            type="image/webp"
                                          />
                                          <img
                                            className={classes.navigationItem}
                                            src={mediaItem.mediaMobile.urlJpg}
                                            alt={mediaItem.altText}
                                          />
                                        </picture>
                                      ) : (
                                        <li>{mediaItem.externalName}</li>
                                      )}
                                    </Link>
                                  ))}
                                </ul>
                              </Accordion.Collapse>
                            </>
                          )}
                        </NavigationItem>
                      );
                    })}
                </Accordion>
                <StaticAccordions />
              </div>
              <div className={classes.countryPicker} id="country_picker_mobile">
                <div className="country-currency-selector header-selector" />
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default Menu;
