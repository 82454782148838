import React, { useMemo, useState, useLayoutEffect, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useCycle } from "framer-motion";
import classNames from "classnames";

import { Search } from "@Components/atoms/Icons";
import { Link, Container } from "@Components/atoms";
import { BasketModule, Dropdown } from "@Components/molecules";
import { SearchModule } from "@Components/organisms";
import { Menu } from "./Menu";
import NavigationContext from "./NavigationContext";
import { SessionDataContext } from "@Context/index";
import { useMediaQuery, useScrollPosition } from "@Hooks/index";
import HeaderLogo from "./HeaderLogo/HeaderLogo";

const useStyles = createUseStyles((theme) => ({
  colorHeaderContainer: {
    height: "65px",
    backgroundColor: theme.color.white,
    color: "#1a1a1a",
    zIndex: theme.zIndexValues.medium,
    width: "100%",
    transition: ".3s",
  },
  transparentHeaderContainer: {
    extend: "headerContainer",
    backgroundColor: "transparent",
  },
  headerAnchor: {
    position: "relative",
    borderBottom: `1px solid ${theme.color.lightGray}`,
  },
  headerAnchorWithoutBorder: {
    extend: "headerAnchor",
    borderBottom: "1px solid transparent",
  },
  navigation: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    padding: "0 16px",
    height: "100%",
    "@media (min-width: 1441px)": {
      padding: "0 21px 0 0",
    },
  },
  toolbar: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "flex-end",
    color: (props) => props?.navElementsColor,
    "@global": {
      "> *": {
        marginLeft: "2rem",
      },
    },
  },
  bodyStyle: {
    position: "relative",
    marginRight: "0 !important",
  },
  dropdownContainer: {
    margin: "0 !important",
    marginLeft: "0 !important",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "10rem",
    },
  },
  cartIcon: {
    position: "relative",
    "@global": {
      span: {
        position: "absolute",
        fontSize: "12px",
        top: "10%",
        left: 0,
        right: 0,
        textAlign: "center",
      },
    },
  },
}));

const useElementKey = (elementKey, onToggle) => {
  const [activeElementKey, setActiveElementKey] = useState(elementKey);

  useLayoutEffect(() => {
    setActiveElementKey(elementKey);
  }, [elementKey, onToggle]);

  return [activeElementKey, setActiveElementKey];
};

export const Navigation = ({ dataDesktop, dataMobile, logo, activeElementKey, onToggle, loading }) => {
  const [sessionData] = useContext(SessionDataContext);
  const isAuth = sessionData.is_authenticated;
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const menuElementsColorDesktop = dataDesktop?.navColour?.colourHex;
  const menuElementsColorMobile = dataMobile?.navColour?.colourHex;

  const menuElementsColor = matches ? menuElementsColorMobile : menuElementsColorDesktop;
  let location = useLocation();
  const [elementKey, setElementKey] = useElementKey(activeElementKey, onToggle);
  const [isSearchOpen, toggleSearchOpen] = useCycle(false, true);

  const [isScrolling, setIsScrolling] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [navElementsColor, setNavElementsColor] = useState("black");
  const { topScrollPosition } = useScrollPosition();

  const handleOpenMenu = (value) => {
    setIsMobileMenuOpen(value);
  };

  const classes = useStyles({ theme, elementKey, isMobileMenuOpen, navElementsColor });
  const isHomepageUrl = location.pathname === "/";
  const isMenuOpen = elementKey || elementKey === 0 || isMobileMenuOpen;

  useEffect(() => {
    document.body.classList.add(classes.bodyStyle);
    setIsScrolling(topScrollPosition !== 0);

    isHomepageUrl && !isScrolling && !elementKey && elementKey !== 0 && !isMobileMenuOpen
      ? setNavElementsColor(menuElementsColor)
      : setNavElementsColor("black");
  }, [
    classes.bodyStyle,
    menuElementsColor,
    elementKey,
    isHomepageUrl,
    isScrolling,
    isMobileMenuOpen,
    topScrollPosition,
  ]);

  const context = useMemo(() => ({ activeElementKey: elementKey, onToggle: setElementKey }), [
    elementKey,
  ]);

  return (
    <NavigationContext.Provider value={context}>
      <nav
        className={classNames(
          (isMenuOpen && !isScrolling) || isScrolling || !isHomepageUrl
            ? classes.colorHeaderContainer
            : classes.transparentHeaderContainer,
        )}
        onMouseLeave={() => setElementKey(null)}
      >
        <div
          id="navigation"
          className={
            isHomepageUrl && elementKey === null
              ? classes.headerAnchorWithoutBorder
              : classes.headerAnchor
          }
        >
          {!loading ? (
            <Container maxWidth="lg" className={classes.navigation}>
              <Menu
                menuDesktop={dataDesktop}
                menuMobile={dataMobile}
                collectiveColor={navElementsColor}
                handleOpenMenu={handleOpenMenu}
              />
              <HeaderLogo
                url={logo?.link?.url}
                imageURL={logo?.mediaDesktop?.url}
                color={navElementsColor}
              />
              <div className={classes.toolbar}>
                {matches ? null : (
                  <>
                    <Link href={!isAuth ? "/account/login/" : "/profile/orders/"}>Account</Link>
                    <div id="country_picker">
                      <div className="header-selector" />
                    </div>
                  </>
                )}
                <Search handleClick={toggleSearchOpen} color={navElementsColor} />
                <Dropdown
                  isActive={isSearchOpen}
                  toggle={toggleSearchOpen}
                  className={classes.dropdownContainer}
                >
                  <SearchModule isSearchOpen={isSearchOpen} toggleOpen={toggleSearchOpen} />
                </Dropdown>
                <BasketModule cartCounter={sessionData.cart_counter} color={navElementsColor} />
              </div>
            </Container>
          ) : null}
        </div>
      </nav>
    </NavigationContext.Provider>
  );
};

export default Navigation;

Navigation.propTypes = {
  data: PropTypes.object,
  activeElementKey: PropTypes.number,
  onToggle: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
