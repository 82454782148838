/* eslint-disable prefer-destructuring */
/* eslint-disable prettier/prettier */
import { useQuery } from "react-query";
import urlJoin from "url-join";

const EARLY_BIRDS_API_URI = process.env.EARLY_BIRDS_API_URI || "http://api.early-birds.io/";

export const ATTRAQT_URI = EARLY_BIRDS_API_URI;

const ATTRAQT_SEARCH_STAGE_TOKEN =
  process.env.ATTRAQT_SEARCH_STAGE_TOKEN || "610154714fd0a7cd1b3cc7d9";
const ATTRAQT_SEARCH_PROD_TOKEN = process.env.ATTRAQT_SEARCH_PROD_TOKEN;

const ATTRAQT_DPD_WIDGET_YMAL_STAGE_ID =
  process.env.ATTRAQT_DPD_WIDGET_YMAL_STAGE_ID || "636bb76ca914af832e922767";
const ATTRAQT_DPD_WIDGET_YMAL_PROD_ID = process.env.ATTRAQT_DPD_WIDGET_YMAL_PROD_ID;

const ATTRAQT_DPD_WIDGET_STAGE_ID =
  process.env.ATTRAQT_DPD_WIDGET_STAGE_ID || "6140bfb410c13e5ef600cc8d";
const ATTRAQT_DPD_WIDGET_PROD_ID = process.env.ATTRAQT_DPD_WIDGET_PROD_ID;

const ATTRAQT_SEARCH_TRENDING_WIDGET_STAGE_ID =
  process.env.ATTRAQT_SEARCH_TRENDING_WIDGET_STAGE_ID || "614311ec31b209e81da867d0";
const ATTRAQT_SEARCH_TRENDING_WIDGET_PROD_ID = process.env.ATTRAQT_SEARCH_TRENDING_WIDGET_PROD_ID;

const ATTRAQT_PLP_WIDGET_STAGE_ID =
  process.env.ATTRAQT_PLP_WIDGET_STAGE_ID || "6151c7643e7e86e709875d74";
const ATTRAQT_PLP_WIDGET_PROD_ID = process.env.ATTRAQT_PLP_WIDGET_PROD_ID;
const ATTRAQT_PLP_ALL_WIDGET_STAGE_ID =
  process.env.ATTRAQT_PLP_ALL_WIDGET_STAGE_ID || "64130944c4f342d3d186f8d5";
const ATTRAQT_PLP_ALL_WIDGET_PROD_ID = process.env.ATTRAQT_PLP_ALL_WIDGET_PROD_ID;

let ATTRAQT_SEARCH_TOKEN;
let RECOMMENDATIONS_WIDGET_ID;
let RECOMMENDATIONS_YMAL_WIDGET_ID;
let SEARCH_TRENDING_RECOMMENDATIONS_WIDGET;
let PLP_WIDGET_ID;
let PLP_ALL_WIDGET_ID;

if (window.location.hostname === "www.prettygreen.com") {
  ATTRAQT_SEARCH_TOKEN = ATTRAQT_SEARCH_PROD_TOKEN;
  RECOMMENDATIONS_WIDGET_ID = ATTRAQT_DPD_WIDGET_PROD_ID;
  RECOMMENDATIONS_YMAL_WIDGET_ID = ATTRAQT_DPD_WIDGET_YMAL_PROD_ID;
  SEARCH_TRENDING_RECOMMENDATIONS_WIDGET = ATTRAQT_SEARCH_TRENDING_WIDGET_PROD_ID;
  PLP_WIDGET_ID = ATTRAQT_PLP_WIDGET_PROD_ID;
  PLP_ALL_WIDGET_ID = ATTRAQT_PLP_ALL_WIDGET_PROD_ID;
} else if (window.location.hostname === "www.stage.prettygreen.com") {
  ATTRAQT_SEARCH_TOKEN = ATTRAQT_SEARCH_STAGE_TOKEN;
  RECOMMENDATIONS_WIDGET_ID = ATTRAQT_DPD_WIDGET_STAGE_ID;
  RECOMMENDATIONS_YMAL_WIDGET_ID = ATTRAQT_DPD_WIDGET_YMAL_STAGE_ID;
  SEARCH_TRENDING_RECOMMENDATIONS_WIDGET = ATTRAQT_SEARCH_TRENDING_WIDGET_STAGE_ID;
  PLP_WIDGET_ID = ATTRAQT_PLP_WIDGET_STAGE_ID;
  PLP_ALL_WIDGET_ID = ATTRAQT_PLP_ALL_WIDGET_STAGE_ID;
} else {
  ATTRAQT_SEARCH_TOKEN = ATTRAQT_SEARCH_STAGE_TOKEN;
  RECOMMENDATIONS_WIDGET_ID = ATTRAQT_DPD_WIDGET_STAGE_ID;
  RECOMMENDATIONS_YMAL_WIDGET_ID = ATTRAQT_DPD_WIDGET_YMAL_STAGE_ID;
  SEARCH_TRENDING_RECOMMENDATIONS_WIDGET = ATTRAQT_SEARCH_TRENDING_WIDGET_STAGE_ID;
  PLP_WIDGET_ID = ATTRAQT_PLP_WIDGET_STAGE_ID;
  PLP_ALL_WIDGET_ID = ATTRAQT_PLP_ALL_WIDGET_STAGE_ID;
}

export const PDP_RECOMMENDATIONS_WIDGET_ID = RECOMMENDATIONS_WIDGET_ID;
export const PDP_RECOMMENDATIONS_YMAL_WIDGET_ID = RECOMMENDATIONS_YMAL_WIDGET_ID;
export const ATTRAQT_TOKEN = ATTRAQT_SEARCH_TOKEN;
export const SEARCH_TRENDING_RECOMMENDATIONS_WIDGET_ID = SEARCH_TRENDING_RECOMMENDATIONS_WIDGET;
export const ATTRAQT_PLP_WIDGET_ID = PLP_WIDGET_ID;
export const ATTRAQT_PLP_ALL_WIDGET_ID = PLP_ALL_WIDGET_ID;

export const createXOUrl = ({
  xoWidgetId,
  trackingID,
  options,
}: {
  options: string;
  trackingID: string;
  xoWidgetId: string;
}) =>
  urlJoin(
    ATTRAQT_URI,
    "widget",
    xoWidgetId || "",
    "recommendations",
    trackingID,
    options,
    "?limit=1000&metadata=widgetId",
  );

interface XORecommendationsFetchOptions {
  isAuthenticated: boolean;
  trigger: boolean;
  userId: string;
  variables?: string;
  xoWidgetId: string;
}

interface Id {
  kind: string;
  original_id: string;
  tenant: string;
}

interface Scores {
  DiscoutPercantage: number;
  LowStock: number;
  Newness: number;
}

interface Metadata {
  updated: string;
}

interface StockBySize {
  inStock: number;
  size: string;
}

interface Product {
  _id: Id;
  allCategories: string[];
  availableDate: string;
  availableSizes: string[];
  booster: number;
  brand: string;
  categories: string[];
  categoryIds: String[];
  collectionsList: string[];
  color: string;
  colorGroup: string;
  colorGroupHEX: string;
  colorHEX: string;
  colors: string[];
  context: string;
  discountedAmount: number;
  discountedPercentage: number;
  gender: string;
  mainCategory: string;
  parentId: string;
  photo: string;
  price: number;
  productActiveSince: string;
  recommended: boolean;
  retailPrice?: number;
  scores: Scores;
  secondImage: string;
  sizes: string[];
  status: string;
  stock: number;
  stockBySize: StockBySize[];
  subCategory: string;
  title: string;
  type: string;
  url: string;
  version: number;
  webname: string;
}

interface Recommendation {
  distribution: number;
  id: string;
  product: Product;
  score: number;
  strategy: string;
}

interface Metadata {
  widgetId: string;
}

interface Pagination {
  total: number;
}

interface Widget {
  attributes: unknown;
  minResults: number;
  title: string;
}

export interface XORecommendationsPayload {
  id: string;
  metadata: Metadata;
  pagination: Pagination;
  recommendations: Recommendation[];
  widget: Widget;
}

export const useXO = ({
  userId,
  isAuthenticated,
  trigger,
  variables,
  xoWidgetId,
}: XORecommendationsFetchOptions) => {
  const options = variables ? `?variables=${variables}` : "";
  const attraqtSessionID = window.localStorage.getItem("attraqtsessionid") || "";
  const userIdURL = userId ? `profileid/${userId}` : "";
  const sessionIdURL = attraqtSessionID ? `sessionid/${attraqtSessionID}` : "";

  const trackingID = isAuthenticated ? userIdURL : sessionIdURL;

  const variantXOUrl = createXOUrl({ xoWidgetId, trackingID, options });

  return useQuery(
    "xoVariants",
    () =>
      fetch(variantXOUrl).then((res) => {
        if (!res.ok) {
          const err = new Error("Not 2xx response");
          throw err;
        }
        return res.json() as Promise<XORecommendationsPayload>;
      }),
    {
      refetchOnWindowFocus: false,
      enabled: trigger,
      retry: false,
    },
  );
};
