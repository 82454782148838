import { useQuery } from "react-query";

import { ATTRAQT_PLP_ALL_WIDGET_ID, ATTRAQT_PLP_WIDGET_ID, useXO } from "@Services/attraqt";

interface UseWidgetOptions {
  category: string;
  isAuthenticated: boolean;
  subcategory?: string;
  trigger: boolean;
  userId: string;
}

const useWidget = ({
  category,
  subcategory,
  userId,
  isAuthenticated,
  trigger,
}: UseWidgetOptions) => {
  const isSubcategoryAvailable = !!subcategory;
  const variables = JSON.stringify({
    ...(isSubcategoryAvailable ? { $subCategory: subcategory } : { $mainCategory: category }),
  });

  const fullCategorySlug = subcategory ? `${category}/${subcategory}` : category;

  const variantESUrl = `/api/product-variant/category/${fullCategorySlug}/`;
  const widgetId = category === "all" ? ATTRAQT_PLP_ALL_WIDGET_ID : ATTRAQT_PLP_WIDGET_ID;
  const xoQuery = useXO({
    userId,
    isAuthenticated,
    trigger,
    variables,
    xoWidgetId: widgetId || "",
  });

  const isUserUsingAdBlock = xoQuery.failureCount > 0;

  const esQuery = useQuery("esVariants", () => fetch(variantESUrl).then((res) => res.json()), {
    refetchOnWindowFocus: false,
    enabled: trigger && isUserUsingAdBlock,
    retry: false,
  });

  const properQuery = isUserUsingAdBlock ? esQuery : xoQuery;

  return properQuery;
};

export default useWidget;
