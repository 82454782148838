import React from "react";

import { createStyles, Radio, Stack, Text } from "@mantine/core";

type ReturnMethod = {
  optionName: string;
  price: number;
};

interface RadioListProps {
  methods: ReturnMethod[];
  setReturnMethod: (optionName: string) => void;
}

const useStyles = createStyles({
  fieldsetStyle: {
    border: 0,
  },
});

function RadioList({ methods, setReturnMethod }: RadioListProps) {
  const { classes } = useStyles();
  const returnMethodRef = React.useRef<HTMLFieldSetElement>(null);

  return (
    <fieldset ref={returnMethodRef} className={classes.fieldsetStyle}>
      <Radio.Group
        label={<Text fz="xl">Return method:</Text>}
        description="Please choose your return method."
        onChange={setReturnMethod}
      >
        <Stack>
          {methods.map((method) => {
            return (
              <Radio
                value={method.optionName}
                label={
                  <Text fz="xl">
                    {method.optionName} {method.price ? `(${method.price}£ fee)` : null}
                  </Text>
                }
              />
            );
          })}
        </Stack>
      </Radio.Group>
    </fieldset>
  );
}
export default RadioList;
