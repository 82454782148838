import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles(theme => ({
  modalOverlay: {
    zIndex: theme.zIndexValues.low,
    position: "fixed",
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    color: "black",
  },
}));
