import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles(theme => ({
    container: {
      overflow: "hidden",
      paddingLeft: "3rem",
      paddingRight: "3rem",
      marginBottom: "4rem",
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "6rem",
        paddingRight: "6rem",
      },
      "& > .swiper-container": {
        overflow: "visible",
      },
    },
    variantTitle: {
      fontSize: "12px",
      [theme.breakpoints.up("md")]: {
        fontSize: "14px",
      },
      "& > span": {
        display: "inline-block",
        marginBottom: "4px",
      },
    },
  }));

  export default useStyles;