import React, { memo } from "react";
import classNames from "classnames";

import { Typography, Accordion, Grid } from "@Components/atoms";
import { useStyles } from './style'
import { capitalize } from "@Utilities/utils";

type FilterOption = {
  [key: string]: {
    attribute: string; 
    order: string; 
    title: string
  }
}

interface SortFilterProps {
  options: FilterOption;
  activeOption: string;
  setFilter: (key: string) => void;
}

export const SortFilter = memo(({ options, activeOption, setFilter }: SortFilterProps) => {
  const classes = useStyles();

  return (
    <>
      <Accordion.Toggle elementKey={0} className={classes.customToggle}>
        <div className={classes.customHeader}>
          <Typography variant="caption" gutterBottom={false} textTransform="uppercase">
            Order
          </Typography>
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse elementKey={0} className={classes.customCollapse}>
        <Grid container>
          {Object.keys(options).map((optionSlug, index) => {
            const option = options[optionSlug]
            const isSelected = activeOption === optionSlug
            const styledButton = classNames(classes.buttonOption, isSelected && classes.buttonOptionSelected);
            return (
              <div key={index} onClick={() => setFilter(optionSlug)} className={styledButton}>
                {capitalize(option.title)}
              </div>
            );
          })}
        </Grid>
      </Accordion.Collapse>
    </>
  );
});

SortFilter.displayName = "Filter";

export default SortFilter;
