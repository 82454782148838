import React, { useContext } from "react";

const defaultValue = {
  activeElementKey: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onToggle: (el: number | null) => undefined,
};

const NavigationContext = React.createContext(defaultValue);

export const useNavigationContext = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error("Navigation Context must be used inside Accordion.");
  }
  return context;
};

export default NavigationContext;
