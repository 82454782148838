import React from "react";
import { useTheme } from "react-jss";

import ButtonBlock from "@Components/organisms/Contentful/ButtonBlock";
import CustomGrid from "@Components/organisms/Contentful/CustomGrid";
import Hero from "@Components/organisms/Contentful/Hero/Hero";
import SimpleCarousel from "@Components/organisms/Contentful/SimpleCarousel";
import StaticMedia from "@Components/organisms/Contentful/StaticMedia";
import TextBlock from "@Components/organisms/Contentful/TextBlock";
import VideoMedia from "@Components/organisms/Contentful/VideoMedia";
import { FetchCustomPageQuery } from "@Components/pages/CustomPage/api/graphql/operation.generated";
import { SessionDataContext } from "@Context/sessionData";
import { useMediaQuery } from "@Hooks/index";
import { ThemeType } from "@Theme/theme";
import { Typename } from "@Utilities/types";

interface Layout {
  h: number;
  i: string;
  w: number;
  x: number;
  y: number;
}

type AppLayout = {
  desktop: Layout[];
  mobile: Layout[];
  tablet: Layout[];
};

type EntryStyle = {
  [key: string]: {
    margin: [number, number, number, number];
  };
};

type Stylesheet = {
  children: EntryStyle;
  global: {
    columns: any;
    fullWidth: any;
    gap: any;
  };
};

type AppStylesheet = {
  desktop: Stylesheet;
  mobile: Stylesheet;
  tablet: Stylesheet;
};

interface ContentfulPageTemplateProps {
  data: Typename<
    FetchCustomPageQuery["customPageCollection"],
    "CustomPageCollection"
  >["items"][number];
}

const isMarginArray = (arr: any) => {
  if (Array.isArray(arr) && arr.length === 4) {
    return true;
  }
  return false;
};

export function ContentfulPageTemplate({ data }: ContentfulPageTemplateProps) {
  const theme = useTheme<ThemeType>();
  const [sessionData] = React.useContext(SessionDataContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  // eslint-disable-next-line no-nested-ternary
  const currentResolution = isMobile ? "mobile" : "desktop";

  const layout = data?.layout as AppLayout;
  const blockStyles = data?.style as AppStylesheet;
  const content = data?.contentCollection?.items;
  const hero = data?.hero;
  const gridLayout = layout[currentResolution];
  const stylesheet = blockStyles[currentResolution];
  const heroContentType = hero?.__typename;

  return (
    <div style={{ marginTop: 10 }}>
      {hero ? (
        <Hero marginBottom={stylesheet.global.gap}>
          {heroContentType === "VideoMedia" ? <VideoMedia data={hero} isMobile={isMobile} /> : null}
          {heroContentType === "StaticMedia" ? (
            <StaticMedia data={hero} isMobile={isMobile} />
          ) : null}
        </Hero>
      ) : null}
      <CustomGrid
        isFullWidth={stylesheet.global.fullWidth}
        gridGap={stylesheet.global?.gap}
        columns={stylesheet.global.columns}
      >
        {gridLayout?.map((layoutElement) => {
          const itemContent = content?.find(
            (contentElement) => contentElement?.sys?.id === layoutElement.i,
          );
          const itemStyle = stylesheet.children?.[layoutElement.i];
          const blockMargin = isMarginArray(itemStyle?.margin) ? itemStyle?.margin : [0, 0, 0, 0];
          return (
            <CustomGrid.Item
              columnLength={layoutElement.w}
              rowLength={layoutElement.h}
              columnStart={layoutElement.x}
              rowStart={layoutElement.y}
              gutter={blockMargin}
            >
              {itemContent?.__typename === "StaticMedia" ? (
                <StaticMedia data={itemContent} isMobile={isMobile} />
              ) : null}
              {itemContent?.__typename === "Button" ? <ButtonBlock data={itemContent} /> : null}
              {itemContent?.__typename === "VideoMedia" ? (
                <VideoMedia data={itemContent} isMobile={isMobile} />
              ) : null}
              {itemContent?.__typename === "SimpleCarousel" ? (
                <SimpleCarousel
                  initData={itemContent}
                  fractionOfFullScreen={layoutElement.w / stylesheet.global.columns}
                  userId={sessionData.user_id}
                  isAuthenticated={sessionData.is_authenticated}
                  isSessionLoaded={sessionData.loaded}
                />
              ) : null}
              {itemContent?.__typename === "Text" ? <TextBlock data={itemContent} /> : null}
            </CustomGrid.Item>
          );
        })}
      </CustomGrid>
    </div>
  );
}

export default ContentfulPageTemplate;
