import classNames from "classnames";
import React from "react";
import SVG from "react-inlinesvg";

import loaderBars from "@Images/loader-bars.svg";

import useStyles from "./style";

export const BUTTON_VARIANTS = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
};

export enum ButtonSizes {
  Small = 2,
  Medium = 3,
  Large = 4,
}

export enum ButtonTypes {
  Button = "button",
  Reset = "reset",
  Submit = "submit",
}

interface ButtonOptions {
  color?: string;
  fluid?: boolean;
  fontSize?: string;
  icon?: string;
  loading?: boolean;
  size?: ButtonSizes;
  withMargin?: string;
}

interface ButtonProps
  extends ButtonOptions,
    Omit<React.ComponentPropsWithoutRef<"button">, keyof ButtonOptions> {}

export function Button({
  children,
  color = "primary",
  icon,
  onClick,
  loading,
  size = ButtonSizes.Medium,
  fluid,
  withMargin = "0",
  fontSize = "14px",
  ...attributes
}: ButtonProps) {
  const { disabled, type = ButtonTypes.Button, className } = attributes;

  const classes = useStyles({ loading, size, withMargin, fontSize });

  const styledClasses = classNames(
    className,
    classes.root,
    classes[color],
    (disabled || loading) && classes.disabled,
    fluid && classes.fluid,
  );

  return (
    <button
      {...attributes}
      type={type}
      disabled={disabled || loading}
      className={styledClasses}
      onClick={onClick}
    >
      {loading && <SVG className={classes.loader} src={loaderBars} />}
      <span className={classes.content}>
        {icon && <span className={classes.iconWrapper}>{icon}</span>}
        {children}
      </span>
    </button>
  );
}

export default Button;
