import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";

export const useInfobar = (richText: string, type: string) => {
  const cookieName = type + "showInfobar"
  const [isInfobarVisible, setInfobarVisibility] = useState(false);
  const [cookies, setCookie] = useCookies([cookieName]);

  const closeInfobar = () => {
    setInfobarVisibility(false);
    setCookie(cookieName, richText, { path: "/", maxAge: 86400 });
    document.body.style.transition = "margin-top .5s";
  };

  // check if infobar should be shown
  useEffect(() => {
    if (richText !== undefined) {
      JSON.stringify(cookies[cookieName]) !== richText
        ? setInfobarVisibility(true)
        : setInfobarVisibility(false);
    }
  }, [richText, document.body.style.marginTop, location.pathname, cookies.showInfobar]);

  return { closeInfobar, isInfobarVisible }
}