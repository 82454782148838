import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import classNames from "classnames";

// import { Link } from "@Components/atoms";

const useStyles = createUseStyles({
  root: {},
});

interface InjectedProductProps {
  isClick: boolean;
  setToggle: () => void;
}

interface ProductProps {
  render?(props: InjectedProductProps): React.ReactNode;
  className?: string;
  children: React.ReactNode;
}

export const Product = ({ children, className, render = null, ...other }: ProductProps) => {
  const classes = useStyles();
  const styledClasses = classNames(classes.root, className);

  const [isClick, setClick] = useState(false);

  const setToggle = () => {
    setClick(prevState => !prevState);
  };

  return (
    <div className={styledClasses} {...other}>
      {children}
      {render && render({ isClick, setToggle })}
    </div>
  );
};

Product.propTypes = {
  render: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Product;
