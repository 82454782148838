// import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { parse, stringify } from "qs";

export const useQueryString = () => {
  let history = useHistory();

  const updateQS = (target, slug) => {
    const query = parse(history.location.search.substr(1));
    query[target] = slug;
    return history.replace({
      search: stringify(query, { arrayFormat: "repeat" }),
    });
  };

  return [updateQS];
};

export default useQueryString;
