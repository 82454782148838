import classNames from "classnames";
import React from "react";

import useStyles from "../style";

interface LinkTypeProps {
  children: React.ReactNode;
  className?: string;
  href: string;
  openInNewTab?: boolean;
}

const defaultProps = {
  className: "",
  openInNewTab: false,
};

function Link({ children, href, className, openInNewTab = false }: LinkTypeProps) {
  const classes = useStyles();
  const styledClasses = classNames(className, classes.shopTheLook__link);

  return (
    <a
      rel="noreferrer"
      href={href}
      target={openInNewTab ? "_blank" : "_self"}
      className={styledClasses}
    >
      {children}
    </a>
  );
}

Link.defaultProps = defaultProps;

export default Link;
