interface ProductObjectCommon {
  brand?: string;
  category?: string;
  variant?: string;
  position?: number;
  price?: string;
  colour?: string;
  size?: string;
  url?: string;
  image?: string;
}
interface ObjectWithId {
  id: string;
}
interface ObjectWithName {
  name: string;
}

type ObjectWithIdOrName = ObjectWithId | ObjectWithName;

type BaseProductObject = ObjectWithIdOrName & ProductObjectCommon;

type ProductFieldObject = BaseProductObject & {
  quantity?: number;
  coupon?: string;
};

type EventTracking = {
  category: string;
  action: string;
  label: string;
};

type PromotionFieldObject = ObjectWithIdOrName & {
  creative?: string;
  position?: string;
};

export interface xoWidget {
  id: string;
  sourceId: string;
  name: string;
}

type EventTypes = {
  removeFromCart: ProductFieldObject[];
  promotionClick: PromotionFieldObject[];
  addToCart: ProductFieldObject[];
  impressionClick: ProductFieldObject[];
  productDetailsView: ProductFieldObject[];
  eventTracking: EventTracking;
  impressionView: string;
  custom: any;
};

export const gtmEnhancedEcommerce = <T extends keyof EventTypes>(
  eventType: T,
  dataList: EventTypes[T],
  pageName: string,
  xoWidget?: xoWidget,
) => {
  window.dataLayer = window.dataLayer || [];
  let eccData;
  if (eventType === "removeFromCart") {
    eccData = {
      event: "eec.remove",
      ecommerce: {
        remove: {
          products: dataList,
        },
      },
    };
  }
  if (eventType === "promotionClick") {
    eccData = {
      event: "eec.promotionClick",
      ecommerce: {
        promoClick: {
          promotions: dataList,
        },
      },
    };
  }
  if (eventType === "eventTracking") {
    eccData = {
      event: "eventTracking",
      list: pageName,
      ...dataList,
    };
  }
  if (eventType === "addToCart") {
    eccData = {
      event: "eec.add",
      ecommerce: {
        add: {
          actionField: { list: pageName },
          products: dataList,
        },
      },
    };
  }
  if (eventType === "impressionClick") {

    eccData = {
      event: "eec.impressionClick",
      ecommerce: {
        click: {
          actionField: { list: pageName },
          products: dataList, 
          ...(xoWidget && {xoWidget}),
        },
      },
    };
  }
  if (eventType === "productDetailsView") {
    eccData = {
      event: "eec.detail",
      ecommerce: {
        detail: {
          actionField: { list: pageName },
          products: dataList,
        },
      },
    };
  }
  if (eventType === "impressionView") {
    eccData = {
      event: "eec.impressionView",
      ecommerce: {
        impressions: {
          actionField: { list: pageName },
          category: dataList,
        },
      },
    };
  }
  if (eventType === "custom") {
    eccData = dataList;
  }
  if (eccData !== undefined) {
    window.dataLayer.push(eccData);
  }
};
