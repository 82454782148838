import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  textBlock: {
    position: "relative",
    height: "100%",
    width: "100%",
    zIndex: 10,
    [theme.breakpoints.down("sm")]: {
      paddingRight: "1rem",
      paddingLeft: "1rem",
    },
  },
}));

export default useStyles;
