export const getCriteoData = (variants: any[]) => {
  return variants.map((variant) => `${variant?.id}`.toUpperCase());
};

export const uniqueArray = (arr: any[]) => [...new Set(arr)];

export const caseInsensitiveCompare = (attr: string) => (str: string) =>
  str.toLowerCase() === attr.toLowerCase();

export const isSelected = (filterParams: string[], attr: string) =>
  filterParams?.some(caseInsensitiveCompare(attr));

export default getCriteoData;
