export const useFPAddToCart = (data, currency) => {
  const pixelData = {
    content_ids: [data.sku],
    content_name: data.name,
    content_type: "product",
    currency,
    value: data.price,
    content_category: data.category,
  };
  fbq("track", "AddToCart", pixelData);
};

export default useFPAddToCart;
