import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";

import { Link } from "@Components/atoms";

const useStyles = createUseStyles((totalColumns) => ({
  dropdownContainer: {
    display: "flex",
    "& > div": {
      paddingRight: "20px",
    },
  },
  styledListContainer: {
    overflow: "auto",
    scrollbarWidth: "none", // Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Chrome, Safari, Opera
    },
    "&:-ms-overflow-style": "none", // IE, Edge
  },
  categoryTitle: {
    color: "#ADADAD",
    cursor: "default",
    fontSize: "12.5px",
    "&:hover": {
      color: "#ADADAD",
    },
  },
  categoryLinkTitle: {
    extend: "categoryTitle",
    cursor: "pointer",
  },
  styledList: {
    listStyleType: "none",
    margin: "0 3rem 0 0",
    totalColumns,
    minWidth: "100%",
    maxWidth: "200px",
    "@global": {
      li: {
        margin: "1.2rem 0",
        cursor: "pointer",
        fontSize: "12.5px",
        whiteSpace: "normal",
        maxWidth: "200px",
      },
    },
  },
  styledCollection: {
    display: "flex",
    width: "100%",
    fontSize: "12.5px",
    "& p": {
      whiteSpace: "normal",
      maxWidth: "200px",
      marginRight: "2rem",
    },
    "@global": {
      img: {
        maxWidth: "200px",
        marginRight: "2rem",
      },
    },
  },
}));

const getColumnsCount = (totalRows, maxRows = 7) => {
  const columnAmount = Math.ceil(totalRows / maxRows);
  return columnAmount;
};

const CategoryCollection = ({ categories }) => {
  const totalColumns = getColumnsCount(categories.navigationItemsCollection.total);

  const classes = useStyles(totalColumns);

  const CategoryList = ({ category }) => (
    <ul className={classes.styledList}>
      {category.items
        .filter((item) => item !== null)
        .map((subcategory) => {
          return (
            <Link key={subcategory.url} href={subcategory.url}>
              <li key={subcategory.externalName}>{subcategory.externalName}</li>
            </Link>
          );
        })}
    </ul>
  );

  const CategoryImageList = ({ category }) => (
    <div className={classes.styledCollection}>
      {category.items
        .filter((item) => item !== null)
        .map((subcategory) => {
          if (subcategory?.mediaDesktop?.url) {
            return (
              <Link key={subcategory.link} href={subcategory.link ? subcategory.link.url : "/"}>
                <picture>
                  <source srcSet={`${subcategory?.mediaDesktop?.url}`} type="image/webp" />
                  <img src={subcategory?.mediaDesktop?.urlJpg} alt={subcategory.altText} />
                </picture>

                <p key={subcategory?.externalName}>{subcategory?.externalName}</p>
              </Link>
            );
          }
          return null;
        })}
    </div>
  );

  return (
    <div className={classes.dropdownContainer}>
      {categories.navigationItemsCollection.items
        .filter((item) => item !== null)
        .map((category) => {
          const isMediaPresent = category.mediaCollection.items.length !== 0;
          const isLinkListPresent = category.navigationLinksCollection.items.length !== 0;
          return (
            <div key={category.externalName} className={classes.styledListContainer}>
              {category.mainLink ? (
                <Link href={category.mainLink ? category.mainLink.url : "/"}>
                  <p className={classes.categoryLinkTitle}>{category.externalName}</p>
                </Link>
              ) : (
                <p className={classes.categoryTitle}>{category.externalName}</p>
              )}
              {isLinkListPresent ? (
                <CategoryList category={category.navigationLinksCollection} />
              ) : null}
              {isMediaPresent ? <CategoryImageList category={category.mediaCollection} /> : null}
            </div>
          );
        })}
    </div>
  );
};

export default CategoryCollection;

CategoryCollection.propTypes = {
  categories: PropTypes.object.isRequired,
};
