import React, { forwardRef, ImgHTMLAttributes, useEffect, useRef, useState } from "react";

import { useImage, UseImageProps } from "./useImage";

export type Dict<T = any> = Record<string, T>;
export function omit<T extends Dict, K extends keyof T>(object: T, keys: K[]) {
  const result: Dict = {};

  Object.keys(object).forEach((key) => {
    if (keys.includes(key as K)) return;
    result[key] = object[key];
  });

  return result as Omit<T, K>;
}

interface NativeImageOptions {
  htmlHeight?: string | number;
  htmlWidth?: string | number;
}

interface ImageOptions {
  className?: string;
  fallback?: React.ReactElement;
  fallbackSrc?: string;
  ignoreFallback?: boolean;
  loading?: "eager" | "lazy";
}

export interface ImageProps
  extends UseImageProps,
    NativeImageOptions,
    ImageOptions,
    Omit<React.ComponentPropsWithoutRef<"img">, keyof UseImageProps> {}

const Image = forwardRef<HTMLImageElement, ImageProps>((props, ref) => {
  const {
    fallbackSrc,
    fallback,
    src,
    loading,
    ignoreFallback,
    crossOrigin,
    htmlWidth,
    htmlHeight,
    alt,
    ...rest
  } = props;

  const shouldIgnore = loading != null || ignoreFallback;

  const status = useImage({
    ...props,
    ignoreFallback: shouldIgnore,
  });

  const shared = {
    ref,
    ...(shouldIgnore ? rest : omit(rest, ["onError", "onLoad"])),
  };

  if (status !== "loaded") {
    if (fallback) return fallback;

    return <img src={fallbackSrc} {...shared} />;
  }

  return <img width={htmlWidth} height={htmlHeight} ref={ref} alt={alt} src={src} {...rest} />;
});

export default Image;
