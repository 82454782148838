export const useFPViewContent = (data, currency) => {
  const pixelData = {
    content_ids: [data.sku],
    content_name: data.name,
    content_type: "product",
    currency,
    value: data.price,
    content_category: data.category,
  };
  try {
    fbq("track", "ViewContent", pixelData);
  } catch (e) {
    console.error(e);
  }
};

export default useFPViewContent;
