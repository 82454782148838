import React, { useEffect, useState } from "react";

import { sessionPromise } from "../../../legacy/components/sessiondata";
import SessionData, { INIT_DATA, SessionDataType } from "./sessionDataContext";

interface SessionDataProviderProps {
  children: React.ReactElement;
}

export function SessionDataProvider({ children }: SessionDataProviderProps) {
  const [sessionData, setSessionData] = useState<SessionDataType>(INIT_DATA);

  useEffect(() => {
    sessionPromise.done((response) => {
      response.loaded = true;
      setSessionData(response);
    });
  }, []);

  return (
    <SessionData.Provider value={[sessionData, setSessionData]}>{children}</SessionData.Provider>
  );
}

export default SessionDataProvider;
