import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { createPortal } from "react-dom";

import { Overlay } from "@Components/atoms/";

import { useStyles } from "./style";

interface Dropdown {
  className?: string;
  isActive: boolean;
  isOverlay?: boolean;
  onMouseEnter?: () => void;
  toggle?: () => void;
}

export const Dropdown: React.FC<Dropdown> = ({
  isActive,
  toggle,
  children,
  isOverlay = true,
  onMouseEnter,
  className,
}) => {
  const classes = useStyles();

  return (
    <AnimatePresence>
      {isActive && (
        <motion.div
          className={classNames(classes.dropdownContainer, className)}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {children}
          {isOverlay &&
            createPortal(
              <div onClick={toggle} onMouseEnter={onMouseEnter}>
                <Overlay />
              </div>,
              document.body,
            )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Dropdown;
