import React from "react";
import visibilityAnimations from "@Components/animations/AnimateVisibility";
import { useStyles } from "./style";
import classNames from "classnames";

interface Overlay {
  className?: string;
  duration?: number;
}

export const Overlay = ({ className, duration = 0.5 }: Overlay) => {
  const classes = useStyles();
  const styledClasses = classNames(classes.modalOverlay, className);

  return <visibilityAnimations.Fade className={styledClasses} duration={duration} />;
};

export default Overlay;
