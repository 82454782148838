import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles(theme => ({
  productBox: {
    boxSizing: "border-box",
    width: "25%",
    padding: 0,
    marginRight: "2rem",
    whiteSpace: "normal",

    "&:last-child": {
      marginRight: 0,
    },

    [theme.breakpoints.down("xs")]: {
      width: "50%",
      margin: "1rem 0",

      "&:not(:nth-child(2n))": {
        paddingRight: "1rem",
      },
      "&:nth-child(2n)": {
        paddingLeft: "1rem",
      },
    },
  },
}));
