import React from "react";
import { useTheme } from "react-jss";

import { Carousel } from "@Components/atoms";
import { useMediaQuery } from "@Hooks/index";
import { useStyles } from "./style";
import { ThemeType } from "@Theme/theme";

const CAROUSEL_SETTINGS = {
  loop: false,
  slidesPerView: "auto",
};

interface QuickLinksProps {
  children: React.ReactNode;
}

export const QuickLinks = ({ children }: QuickLinksProps) => {
  const theme = useTheme<ThemeType>();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      {matches ? (
        <div className={classes.quickLinksContainer}>{children}</div>
      ) : (
        <Carousel className={classes.customCarousel} {...CAROUSEL_SETTINGS}>
          {children}
        </Carousel>
      )}
    </>
  );
};

export default QuickLinks;
