import { createUseStyles } from "react-jss";

export interface VariantGalleryStyleProps {
  panelHeight: number;
}

const useStyles = createUseStyles((theme) => ({
  scrollContainer: {
    overflowY: "scroll",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      width: 0,
      background: "transparent",
    },
  },
  imagesContainer: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: "1rem",
    },
  },
  tagContainer: {
    position: "absolute",
    left: "12px",
    bottom: "12px",
    zIndex: theme.zIndexValues.high,
    [theme.breakpoints.up("sm")]: {
      top: "12px",
    },
  },
  extendedContainer: {
    height: (props: VariantGalleryStyleProps) => props.panelHeight,
  },
}));

export default useStyles;
