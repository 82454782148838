import React from "react";
import PropTypes from "prop-types";

import { ColorPicker, SizePicker } from "@Components/molecules";
// import { ModalsContext } from "@Context/index";

export const VariantPicker = ({
  loading,
  variants,
  setCurrentVariantID,
  currentVariantID,
  sizes,
  outOfStock,
}) => {
  return (
    <div style={{ width: "100%", marginBottom: "1rem" }}>
      <ColorPicker
        loading={loading}
        variants={variants}
        setCurrentVariantID={setCurrentVariantID}
        currentVariantID={currentVariantID}
      />
      <SizePicker
        loading={loading}
        setCurrentVariantID={setCurrentVariantID}
        currentVariantID={currentVariantID}
        sizes={sizes}
        outOfStock={outOfStock}
      />
    </div>
  );
};

export default VariantPicker;

VariantPicker.propTypes = {
  loading: PropTypes.bool.isRequired,
  setCurrentVariantID: PropTypes.func.isRequired,
  currentVariantID: PropTypes.string.isRequired,
  variants: PropTypes.arrayOf(PropTypes.object).isRequired,
  sizes: PropTypes.arrayOf(PropTypes.object).isRequired,
  outOfStock: PropTypes.bool,
};
