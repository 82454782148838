import React from "react";

import { Button } from "@mantine/core";
import { IconCaretRight } from "@tabler/icons";

import { PossibleReturnOptions, ReasonWithType } from "../types";

interface ReasonPickerProps {
  count: number;
  handleClose: () => void;
  handleReturnReasonChange: (el: ReasonWithType) => void;
  handleReturnReasonRemove: (el: string) => void;
  possibleReturnOptions: PossibleReturnOptions[];
  productVariantId: string;
}

function ReasonPicker({
  possibleReturnOptions,
  handleReturnReasonChange,
  productVariantId,
  handleClose,
  count,
  handleReturnReasonRemove,
}: ReasonPickerProps) {
  const [nestedReason, setNestedState] = React.useState("");
  const activeMultiReason = possibleReturnOptions.find((reason) => reason?.name === nestedReason);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {nestedReason ? (
        <>
          <Button onClick={() => handleReturnReasonRemove(productVariantId)} variant="filled">
            {activeMultiReason?.name}
          </Button>
          {activeMultiReason &&
            activeMultiReason.reasons &&
            activeMultiReason.reasons.map((reason) => {
              const subReason = reason?.name || "";
              return (
                <Button
                  onClick={() => {
                    handleReturnReasonChange({
                      type: "MultiReasonOption",
                      productVariantId,
                      quantity: count,
                      reason: activeMultiReason?.name,
                      subReason,
                    });
                    handleClose();
                  }}
                  variant="outline"
                >
                  {reason?.name}
                </Button>
              );
            })}
        </>
      ) : (
        <>
          {possibleReturnOptions.map((option) => {
            const type = option?.__typename;
            const isSubReasonAvailable = option?.__typename === "MultiReasonOption";

            const reasonTitle = option?.name || "";

            if (isSubReasonAvailable) {
              return (
                <Button
                  variant="outline"
                  onClick={() => setNestedState(reasonTitle)}
                  rightIcon={<IconCaretRight />}
                >
                  {option.name}
                </Button>
              );
            }
            return (
              <Button
                variant="outline"
                onClick={() => {
                  handleReturnReasonChange({
                    type,
                    productVariantId,
                    quantity: count,
                    reason: reasonTitle,
                    subReason: "",
                  });
                  handleClose();
                }}
              >
                {reasonTitle}
              </Button>
            );
          })}
        </>
      )}
    </>
  );
}

export default ReasonPicker;
