import React from "react";

import Button from "@Components/atoms/Button";

import { DefaultButtonFragment } from "../../../../globals/fragments.generated";
import useStyles from "./style";

interface ButtonBlockProps {
  data: DefaultButtonFragment;
}

const toToLink = (url: string) => (window.location.href = url);

export function ButtonBlock({ data }: ButtonBlockProps) {
  const classes = useStyles();
  const link = data.link?.url;
  const buttonText = data.externalName;

  return (
    <div className={classes.buttonPositionContainer}>
      <Button onClick={() => toToLink(link || "")}>{buttonText}</Button>
    </div>
  );
}

export default ButtonBlock;
