import React from "react";

import { SessionDataType } from "@Context/sessionData/sessionDataContext";
import { useXO } from "@Services/attraqt";

import { DefaultSimpleCarouselFragment } from "../../../../globals/fragments.generated";
import SimpleCarouselUI from "./components/SimpleCarouselUI";
import {
  parseContentfulProductPickerJSON,
  parseContentfulToCarouselProductSchema,
  parseXoToCarouselProductSchema,
} from "./utils";

interface SimpleCarouselProps {
  fractionOfFullScreen: number;
  initData: DefaultSimpleCarouselFragment;
  isAuthenticated: SessionDataType["is_authenticated"];
  isSessionLoaded: SessionDataType["loaded"];
  userId: SessionDataType["user_id"];
}

function SimpleCarousel({
  initData,
  fractionOfFullScreen,
  userId,
  isAuthenticated,
  isSessionLoaded,
}: SimpleCarouselProps) {
  const xoWidgetId = initData.xoWidget?.widgetId || "";
  const isXoIdProvided = !!xoWidgetId;
  const isXoRecommendations = isSessionLoaded && isXoIdProvided;

  const {
    data: xoData,
    isLoading: isLoadingXo,
    failureCount,
  } = useXO({
    userId,
    isAuthenticated,
    trigger: isXoRecommendations,
    xoWidgetId,
  });

  const isXoRecoomendationsData = !!xoData;
  const isUserUsingAdBlock = failureCount > 0;

  if (
    (initData.products && initData.products.length && !isXoIdProvided) ||
    (initData.products && initData.products.length && isUserUsingAdBlock)
  ) {
    const products = parseContentfulProductPickerJSON(initData.products);
    const parsedCarouselData = parseContentfulToCarouselProductSchema(products);
    return (
      <SimpleCarouselUI
        fractionOfFullScreen={fractionOfFullScreen}
        products={[...parsedCarouselData, ...parsedCarouselData, ...parsedCarouselData]}
      />
    );
  }

  if (isXoRecoomendationsData && isXoIdProvided && !isLoadingXo && !isUserUsingAdBlock) {
    const parsedCarouselData = parseXoToCarouselProductSchema(xoData?.recommendations);
    return (
      <SimpleCarouselUI
        fractionOfFullScreen={fractionOfFullScreen}
        products={[...parsedCarouselData, ...parsedCarouselData, ...parsedCarouselData]}
      />
    );
  }

  return <div />;
}

export default SimpleCarousel;
