import classNames from "classnames";
import React from "react";

import useStyles from "./style";

interface LinkTypeProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  href: string;
  openInNewTab?: boolean;
  underline?: boolean;
}

const defaultProps = {
  className: "",
  disabled: false,
  openInNewTab: false,
  underline: false,
};

export function Link({
  children,
  href,
  disabled = false,
  className,
  openInNewTab = false,
  underline,
  ...other
}: LinkTypeProps) {
  const classes = useStyles();
  const styledClasses = classNames(
    className,
    classes.root,
    !href && classes.noPointer,
    disabled && classes.disabled,
    underline && classes.underline,
  );

  return (
    <a
      rel="noreferrer"
      href={href}
      target={openInNewTab ? "_blank" : "_self"}
      className={styledClasses}
      {...other}
    >
      {children}
    </a>
  );
}

Link.defaultProps = defaultProps;

export default Link;
