import { useState, useEffect } from "react";
import { search, SearchResponse } from "@attraqt/search";
import { ATTRAQT_TOKEN } from '@Services/index'

interface Args {
  searchSlug: string;
  filterParams: Params;
  variantAmount?: number;
  sortObject?: Sort;
}

type Params = {
  [index: string]: string | number;
}

type Facet = {
  id: string;
  values: string[];
}

type Sort = {
  attribute: string;
  order: string;
  [key: string]: string;
}

const toArray = (value: string | Array<string>) => {
  if (Array.isArray(value)) {
    return value
  } else return [value]
}


const generateOptions = (facets: Facet[], variantAmount = 20, sortBy?: Sort) => {
  const attribute = sortBy?.attribute || "relevancy"
  const order = sortBy?.order || "desc"
  const defaultSort = { attribute, order } as const;

  return {
    limit: variantAmount,
    offset: 0,
    disable: [],
    facets,
    sortBy: [defaultSort],
    filter: ""
  }
}

export const useAttraqtSearch = ({ searchSlug, filterParams, sortObject, variantAmount }: Args) => {
  search.init({ token: ATTRAQT_TOKEN });
  const [response, setResponse] = useState<SearchResponse>([]);
  const [loading, setLoadingStatus] = useState<boolean>(true);
  const [errorResponse, setErrorResponse] = useState<string>("");

  let facets = Object.keys(filterParams).map(param => {
    return { "id": `facet-${param}`, "values": toArray(filterParams[param]) }
  })
  facets = facets.filter(Boolean);

  async function fetchSearch() {
    try {
      setLoadingStatus(true)
      const response = await search.query(searchSlug, generateOptions(facets, variantAmount, sortObject));
      setResponse(response)
      setLoadingStatus(false)
    } catch (error) {
      let errorMessage = "Failed to do fetch";
      setLoadingStatus(false)
      if (error instanceof Error) {
        errorMessage = error.message;
        setErrorResponse(error.message)
      } else {
        setErrorResponse(errorMessage)
      }
    }
  }

  useEffect(() => {
    fetchSearch();
  }, [JSON.stringify(searchSlug + variantAmount), JSON.stringify(sortObject), JSON.stringify(filterParams)]);

  return { response, loading, errorResponse };
};

export default useAttraqtSearch;
