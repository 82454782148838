import { useState, useContext } from "react";

import { SessionDataContext } from "../context/sessionData";
import { NewsletterSubscribeResponse } from "../types/API/newsletter";

interface UsePostDataProps {
  url: string;
  payload: string;
  contentType: string;
  redirect?: string;
}

export const usePostData = ({ url, payload, contentType, redirect }: UsePostDataProps) => {
  const [res, setRes] = useState<NewsletterSubscribeResponse>({
    success: null,
    error: null,
    isLoading: false,
  });
  const [sessionData, setSessionData] = useContext(SessionDataContext);
  const callAPI = () => {
    if (sessionData) {
      setRes({ success: null, error: null, isLoading: true });
      fetch(url, {
        body: payload,
        headers: {
          "Content-Type": contentType,
          "X-CSRFToken": sessionData.csrf_token,
        },
        credentials: "same-origin",
        method: "POST",
      })
        .then(res => {
          fetch("/session-data/")
            .then(res => res.json())
            .then(res => setSessionData(res));
          if (!res.ok) {
            setRes({ success: res.ok, isLoading: false, error: true });
            throw new Error("Network response was not ok");
          }
          return res;
        })
        .then(res => {
          const cartID = res.headers.get("X-CART");
          document.cookie = `cart=${cartID};path=/`;
          setRes({ success: res.ok, isLoading: false, error: null });
          if (redirect) {
            window.location.assign(redirect);
          }
        })
        .catch(error => {
          setRes({ success: null, isLoading: false, error });
        });
    }
  };
  // return [res, callAPI];
  return { res, callAPI };
};

export default usePostData;
