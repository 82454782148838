import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles(theme => ({
    productImage: {
        marginRight: "auto",
        marginLeft: "auto",
        marginBottom: "11px",
        width: "calc(100% - 5px)",
        [theme.breakpoints.up("sm")]: {
          width: "calc(100% - 10px)",
        },
      },
  }));