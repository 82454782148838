import { useQuery } from "react-query";

import { CartData } from "./types";

export const useWidget = () => {
  return useQuery<CartData>("variants", () => fetch("/api/cart/get/").then((res) => res.json()), {
    refetchOnWindowFocus: false,
  });
};

export default useWidget;
