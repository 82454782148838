import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "react-jss";

import { AspectRatio, Box, Carousel, Image, ProductSkeleton } from "@Components/atoms";
import Status from "@Components/molecules/Status";
import { useMediaQuery } from "@Hooks/index";
import { Image as ImageType } from "@Types/API/product";
import { ConditionalWrapper } from "@Utilities/utils";

import useStyles, { VariantGalleryStyleProps } from "./style";

interface VariantGalleryProps extends VariantGalleryStyleProps {
  currentVariantTag: { comingSoon: boolean; new: boolean; newSeason: boolean; sale: boolean };
  images: ImageType[];
}

const IMAGES_PLACEHOLDER_RATIO = 1560 / 3120;

export function VariantGallery({ images, panelHeight, currentVariantTag }: VariantGalleryProps) {
  const theme = useTheme() as any;
  const classes = useStyles({ theme, panelHeight });
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [isPanelLonger, setIsPanelLonger] = useState(true);
  const variantGalleryRef = useRef<HTMLDivElement>(null);
  const styledClasses = classNames([isPanelLonger && !isMobile && classes.extendedContainer]);

  useEffect(() => {
    setIsPanelLonger(panelHeight > variantGalleryRef?.current?.clientHeight + 100);
  }, [panelHeight]);

  const isImages = !!images;
  const variantImages = images || [...Array(2)];
  const isTag = Object.keys(currentVariantTag).length > 0;

  return (
    <AspectRatio
      ref={variantGalleryRef}
      ratio={isMobile ? theme.aspectRatio.defaultImageAspectRatio : IMAGES_PLACEHOLDER_RATIO}
      className={styledClasses}
    >
      <div className={classes.scrollContainer}>
        <ConditionalWrapper
          condition={isMobile}
          wrapper={(children) => (
            <Carousel scrollbar scrollbarLoading={isImages}>
              {children}
            </Carousel>
          )}
        >
          {variantImages.map((image, i) => (
            <AspectRatio
              key={i}
              ratio={theme.aspectRatio.defaultImageAspectRatio}
              className={classes.imagesContainer}
            >
              <Image
                key={i}
                fallback={<ProductSkeleton />}
                src={image?.image_780x1030}
                srcSet={`${image?.image_780x1030}, ${image?.image_1560x2060} 2x`}
              />
            </AspectRatio>
          ))}
        </ConditionalWrapper>
        {isTag && (
          <Status
            isOnSale={currentVariantTag.sale}
            isComingSoon={currentVariantTag.comingSoon}
            isNew={currentVariantTag.new}
            isNewSeason={currentVariantTag.newSeason}
          >
            {(statusTag) =>
              statusTag ? <Box className={classes.tagContainer}>{statusTag}</Box> : null
            }
          </Status>
        )}
      </div>
    </AspectRatio>
  );
}

export default VariantGallery;
