import React from "react";

import { AspectRatio, Button, createStyles, Grid, Group, Image, Stack, Text } from "@mantine/core";

import { Line, ReturnReference } from "../../types";
import { addCurrencyToPrice } from "../../utils";
import QuantityCounter from "../QuantityCounter";

const OrderContext = React.createContext<Line | undefined>(undefined);
const ReasonContext = React.createContext<Omit<ReturnReference, "productVariant"> | undefined>(
  undefined,
);

const useReasonContext = () => {
  const context = React.useContext(ReasonContext);

  if (!context) {
    throw new Error("Component need to be a child of Order component");
  }

  return context;
};

const useOrderContext = () => {
  const context = React.useContext(OrderContext);

  if (!context) {
    throw new Error("Component need to be a child of Order component");
  }

  return context;
};

const useStyles = createStyles({
  customImage__figure: {
    width: "100%",
  },
});

export function Title() {
  const { productName } = useOrderContext();
  return (
    <Text size="lg" transform="capitalize">
      {productName}
    </Text>
  );
}
export function Color() {
  const { productColor } = useOrderContext();
  return (
    <Text size="lg" transform="capitalize">
      Colour: {productColor}
    </Text>
  );
}
export function Size() {
  const { productSize } = useOrderContext();
  return (
    <Text size="lg" transform="capitalize">
      Size: {productSize}
    </Text>
  );
}
export function Quantity() {
  const { quantity } = useReasonContext();
  return (
    <Text size="sm" transform="capitalize">
      <Group>Quantity: {quantity}</Group>
    </Text>
  );
}

export function QuantityPicker({
  quantity,
  handleReturnReasonQuantityChange,
}: {
  handleReturnReasonQuantityChange: (productVariantId: string, newQuantity: number) => void;
  quantity: number;
}) {
  const { productVariantId } = useOrderContext();

  const handleChange = (newQuantity: number) =>
    handleReturnReasonQuantityChange(productVariantId, newQuantity);

  return <QuantityCounter count={quantity} handleChange={handleChange} min={1} max={quantity} />;
}

export function Price() {
  const { price } = useOrderContext();
  return (
    <Text size="sm" transform="capitalize">
      Price: {addCurrencyToPrice(price)}
    </Text>
  );
}

export function ReasonButton({
  isSelectedReason,
  openReasonDrawer,
  productVariantId,
  handleReturnReasonRemove,
}: {
  handleReturnReasonRemove: (hash: string) => void;
  isSelectedReason: boolean;
  openReasonDrawer: (hash: string) => void;
  productVariantId: string;
}) {
  return (
    <Grid>
      <Grid.Col xs={12} sm={9}>
        <Button.Group>
          <Button
            fullWidth
            disabled={isSelectedReason}
            onClick={() => openReasonDrawer(productVariantId)}
            variant={isSelectedReason ? "filled" : "outline"}
          >
            Return
          </Button>
          {isSelectedReason ? (
            <Button onClick={() => handleReturnReasonRemove(productVariantId)}>X</Button>
          ) : null}
        </Button.Group>
      </Grid.Col>
    </Grid>
  );
}

export function Reason() {
  const { reason } = useReasonContext();
  return (
    <Text size="sm" transform="capitalize">
      {reason}
    </Text>
  );
}

export function Photo() {
  const { classes } = useStyles();
  const { productImageUrl } = useOrderContext();
  return (
    <AspectRatio ratio={170 / 224}>
      <Image
        classNames={{ figure: classes.customImage__figure }}
        src={productImageUrl}
        alt="text"
      />
    </AspectRatio>
  );
}

interface ReturnSummaryProps {
  media: React.ReactNode;
  order: Line;
  reason: Omit<ReturnReference, "productVariant">;
}

function Order({
  media,
  children = null,
  order,
  reason,
}: React.PropsWithChildren<ReturnSummaryProps>) {
  return (
    <OrderContext.Provider value={order}>
      <ReasonContext.Provider value={reason}>
        <Grid grow gutter="xl" style={{ width: "100%" }}>
          <Grid.Col xs={12} sm={4}>
            {media}
          </Grid.Col>
          <Grid.Col xs={12} sm={8}>
            <Stack justify="space-between" style={{ height: "100%" }}>
              {children}
            </Stack>
          </Grid.Col>
        </Grid>
      </ReasonContext.Provider>
    </OrderContext.Provider>
  );
}

Order.Title = Title;
Order.Color = Color;
Order.Size = Size;
Order.Quantity = Quantity;
Order.QuantityPicker = QuantityPicker;
Order.Price = Price;
Order.ReasonButton = ReasonButton;
Order.Reason = Reason;
Order.Photo = Photo;

export default Order;
