import { useEffect, useState } from "react";

export const useScrollPosition = () => {
  const [topScrollPosition, setTopScrollPosition] = useState(0);
  const [bottomScrollPosition, setBottomScrollPosition] = useState();

  const handleScroll = () => {
    setTopScrollPosition(window.pageYOffset);
    setBottomScrollPosition(document.body.offsetHeight - (window.innerHeight + window.scrollY));
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { topScrollPosition, bottomScrollPosition };
};

export default useScrollPosition;
