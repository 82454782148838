import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import classNames from "classnames";

import { capitalize } from "@Utilities/utils";

const useStyles = createUseStyles((theme) => ({
  root: {
    width: "100%",
    marginLeft: "auto",
    boxSizing: "border-box",
    marginRight: "auto",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  disableGutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  bottomGutters: {
    paddingBottom: theme.spacing(3),
  },
  topGutters: {
    paddingTop: theme.spacing(3),
  },
  fixed: Object.keys(theme.breakpoints.values).reduce((acc, breakpoint) => {
    const value = theme.breakpoints.values[breakpoint];

    if (value !== 0) {
      acc[theme.breakpoints.up(breakpoint)] = {
        maxWidth: value,
      };
    }

    return acc;
  }, {}),

  maxWidthXs: {
    [theme.breakpoints.up("xs")]: {
      maxWidth: Math.max(theme.breakpoints.values.xs, 444),
    },
  },

  maxWidthSm: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: theme.breakpoints.values.sm,
    },
  },

  maxWidthMd: {
    [theme.breakpoints.up("md")]: {
      maxWidth: theme.breakpoints.values.md,
    },
  },

  maxWidthLg: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: theme.breakpoints.values.lg,
    },
  },

  maxWidthXl: {
    [theme.breakpoints.up("xl")]: {
      maxWidth: theme.breakpoints.values.xl,
    },
  },
  backgroundColor: {
    backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : "transparent"),
  },
}));

export const Container = (props) => {
  const {
    disableGutters,
    bottomGutters,
    topGutters,
    fixed,
    maxWidth = "lg",
    children,
    className,
    id,
    backgroundColor,
    ...other
  } = props;
  const theme = useTheme();
  const classes = useStyles({ theme, backgroundColor });

  const styledClasses = classNames(
    classes.root,
    fixed && classes.fixed,
    disableGutters && classes.disableGutters,
    bottomGutters && classes.bottomGutters,
    topGutters && classes.topGutters,
    className ? className : null,
    maxWidth !== false && classes["maxWidth".concat(capitalize(String(maxWidth)))],
    backgroundColor && classes.backgroundColor,
  );

  return (
    <div id={id} className={styledClasses} {...other}>
      {children}
    </div>
  );
};

export default Container;

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  disableGutters: PropTypes.bool,
  bottomGutters: PropTypes.bool,
  topGutters: PropTypes.bool,
  className: PropTypes.string,
  fixed: PropTypes.bool,
  id: PropTypes.string,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
  backgroundColor: PropTypes.string,
};
