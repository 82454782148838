import PropTypes from "prop-types";

/*
  Filter product list from out of stock.
*/

export function productInStock(productList, activeSize, in_stock) {
  if (in_stock) {
    productList = productList.filter((product) => {
      if (activeSize.length > 0) {
        let isInStock;
        activeSize.forEach((activeSize) => {
          product.sizes.filter((obj) => {
            if (obj.size === activeSize) {
              isInStock = obj.in_stock;
            }
          });
        });
        return isInStock;
      } else {
        return product.sizes.some((el) => !!el.in_stock == true);
      }
    });
  }
  return productList;
}

/*
Arguments:
target - aimed value as a key
check - key which function compare the target against
list - iteration needs to match at least one Array item
items - list of items,
identiticator - used for query strings, if not present returns the name instead
*/
export function getOccurences(
  target,
  check1,
  check2,
  check3,
  list1,
  list2,
  list3,
  list4,
  items,
  identificator = false,
) {
  let isIdentificator = !!identificator;
  let hasFilter = list2.length > 0 || list3.length > 0 || list4.length > 0;
  let hasDoubleFilter =
    (list2.length > 0 && list3.length > 0) ||
    (list2.length > 0 && list4.length > 0) ||
    (list3.length > 0 && list4.length > 0);
  let hasTripleFilter = list2.length > 0 && list3.length > 0 && list4.length > 0;
  return items.reduce((allSizes, product) => {
    const variantInStock = product.sizes.some((el) => el.in_stock > 0);
    if (hasTripleFilter) {
      if (
        list2.some((el) => product[check1].filter((option) => option.slug === el).length > 0) &&
        list3.some((el) => product[check2].filter((option) => option.slug === el).length > 0) &&
        list4.some((el) => product[check3].filter((option) => option.slug === el).length > 0)
      ) {
        product[target].map((el) => {
          if (allSizes[el.slug]) {
            if (variantInStock) {
              allSizes[el.slug].amount++;
            }
          } else {
            allSizes[el.slug] = {
              id: product.id,
              amount: variantInStock ? 1 : 0,
              isActive: list1.includes(isIdentificator ? product[identificator] : product[target]),
              slug: isIdentificator ? product[identificator][0].slug : el.slug,
              name: isIdentificator ? product[identificator][0].name : el.name,
              in_stock: variantInStock,
            };
          }
        });
      }
    } else if (hasDoubleFilter) {
      if (
        (list2.some((el) => product[check1].filter((option) => option.slug === el).length > 0) &&
          list3.some((el) => product[check2].filter((option) => option.slug === el).length > 0)) ||
        (list2.some((el) => product[check1].filter((option) => option.slug === el).length > 0) &&
          list4.some((el) => product[check3].filter((option) => option.slug === el).length > 0)) ||
        (list3.some((el) => product[check2].filter((option) => option.slug === el).length > 0) &&
          list4.some((el) => product[check3].filter((option) => option.slug === el).length > 0))
      ) {
        product[target].map((el) => {
          if (allSizes[el.slug]) {
            if (variantInStock) {
              allSizes[el.slug].amount++;
            }
          } else {
            allSizes[el.slug] = {
              id: product.id,
              amount: variantInStock ? 1 : 0,
              isActive: list1.includes(isIdentificator ? product[identificator] : product[target]),
              slug: isIdentificator ? product[identificator][0].slug : el.slug,
              name: isIdentificator ? product[identificator][0].name : el.name,
              in_stock: variantInStock,
            };
          }
        });
      }
    } else if (hasFilter) {
      if (
        list2.some((el) => product[check1].filter((option) => option.slug === el).length > 0) ||
        list3.some((el) => product[check2].filter((option) => option.slug === el).length > 0) ||
        list4.some((el) => product[check3].filter((option) => option.slug === el).length > 0)
      ) {
        product[target].map((el) => {
          if (allSizes[el.slug]) {
            if (variantInStock) {
              allSizes[el.slug].amount++;
            }
          } else {
            allSizes[el.slug] = {
              id: product.id,
              amount: variantInStock ? 1 : 0,
              isActive: list1.includes(isIdentificator ? product[identificator] : product[target]),
              slug: isIdentificator ? product[identificator][0].slug : el.slug,
              name: isIdentificator ? product[identificator][0].name : el.name,
              in_stock: variantInStock,
            };
          }
        });
      }
    } else {
      product[target].map((el) => {
        if (allSizes[el.slug]) {
          if (variantInStock) {
            allSizes[el.slug].amount++;
          }
        } else {
          allSizes[el.slug] = {
            id: product.id,
            amount: variantInStock ? 1 : 0,
            isActive: list1.includes(isIdentificator ? product[identificator] : product[target]),
            slug: isIdentificator ? product[identificator][0].slug : el.slug,
            name: isIdentificator ? product[identificator][0].name : el.name,
            in_stock: variantInStock,
          };
        }
      });
    }
    return allSizes;
  }, {});
}

/*
  Function create schema for json ld
*/
export function constructSchema(object, arr, currency) {
  arr.map((each) => {
    object["offers"].itemOffered.push({
      "@type": "Product",
      itemCondition: "http://schema.org/NewCondition",
      name: each.name,
      sku: each.sku,
      category: each.categoryName[0],
      price: each.stock_info.price,
      priceCurrency: currency,
      availability: "http://schema.org/InStock",
      image: each.product_image.image_270x270,
    });
  });
  return object;
}

/*
  Function creates data for Criteo
*/
export function getCriteoData(startPoint, endingPoint, list) {
  const productList = list.map((product) => product).slice(startPoint, endingPoint);
  const criteoList = productList.map((product) =>
    `${product?.product?.sku}-${product?.color?.raw_value}-${product?.size[0]?.slug}`.toUpperCase(),
  );
  return criteoList;
}

/*
  Capitalize, string/array of strings
*/

export const capitalize = (slug) => {
  if (Array.isArray(slug)) {
    const capitalizeArray = slug.map((el) => el.charAt(0).toUpperCase() + el.slice(1));
    return capitalizeArray;
  }

  if (typeof slug !== "string") {
    throw new Error("PG-UI: capitalize(string) expects a string argument.");
  }

  return slug.charAt(0).toUpperCase() + slug.slice(1);
};

// React Utils

export const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

ConditionalWrapper.propTypes = {
  condition: PropTypes.bool.isRequired,
  wrapper: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

// Price utils

export const getPercentageDiscount = (price, retail_price) =>
  Math.round(((retail_price - price) / retail_price) * 100);

export const getFullPrice = (price) =>
  price
    .toLocaleString("en-GB", {
      currency: "GBP",
      style: "currency",
    })
    .replace(/\.00$/, "");

export const formatGrammaticalNumber = (quantity, plural, singular) => {
  const properForm = quantity > 1 || quantity === 0 ? plural : singular;
  return `${quantity} ${properForm}`;
};

export const mapResponsive = (prop, mapper) => {
  if (Array.isArray(prop)) {
    return prop.map((item) => {
      if (item === null) {
        return null;
      }
      return mapper(item);
    });
  }

  if (typeof prop === "object") {
    return Object.keys(prop).reduce((result, key) => {
      result[key] = mapper(prop[key]);
      return result;
    }, {});
  }

  if (prop != null) {
    return mapper(prop);
  }

  return null;
};

export const getThumbnailsSizeFromOriginalUrl = (url, size, quality) =>
  url
    .replace("media/products", "media/__sized__/products")
    .replace(".jpg", `-thumbnail-${size}-${quality}.jpg`);

// returns from attraqt [retailPrice, price] used in <Price />
export const getAttraqtPrices = (retailPrice, price) => [
  retailPrice ? retailPrice / 100 : price / 100,
  price / 100,
];
