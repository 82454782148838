import React from "react";
import { use100vh } from "react-div-100vh";

import useStyles from "./style";

export interface FullWidthHeaderProps {
  marginBottom: string;
}

const defaultProps = {
  gridGap: 0,
};

export function FullWidthHeader({
  marginBottom,
  children,
}: React.PropsWithChildren<FullWidthHeaderProps>) {
  const height = use100vh();
  const classes = useStyles({ height, marginBottom });

  return (
    <div className={classes.fullHeightBlock} style={{ height: height - 65 }}>
      {children}
    </div>
  );
}

FullWidthHeader.defaultProps = defaultProps;

export default FullWidthHeader;
