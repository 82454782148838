import React from "react";

import Tag from "@Components/atoms/Tag";
import Typography from "@Components/atoms/Typography";

function ComingSoonTag() {
  return (
    <Tag>
      <Typography customColor="#000" gutterBottom={false} gutterSide>
        COMING SOON
      </Typography>
    </Tag>
  );
}

export default ComingSoonTag;
