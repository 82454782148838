import React, { useMemo, useCallback, useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

import AccordionContext from "../AccordionContext";
import Collapse from "./Collapse";
import Toggle from "./Toggle";

const useStyles = createUseStyles(theme => ({
  accordion: {
    borderTop: `1px solid ${theme.color.grey}`,
    width: "100%",
    "& svg": {
      transform: "scale(0.75)",
    },
  },
}));

const toArr = slug => {
  if (Array.isArray(slug)) {
    return slug;
  } else return [slug];
};

const useElementKey = (elementKey, onToggle) => {
  const [activeElementKey, setActiveElementKey] = useState(toArr(elementKey));

  useLayoutEffect(() => {
    elementKey = toArr(elementKey);
    setActiveElementKey(elementKey);
  }, [elementKey, onToggle]);

  return [activeElementKey, setActiveElementKey];
};

const Accordion = ({
  element: Component,
  activeElementKey,
  onToggle,
  children,
  className,
  type = "accordion",
  ...otherProps
}) => {
  const classes = useStyles();
  const styledClasses = classNames(classes.accordion, className);
  const [elementKeys, setElementKey] = useElementKey(activeElementKey, onToggle);

  const handleToggle = useCallback(
    elementKey => {
      if (activeElementKey !== undefined) {
        if (type === "dropdown") {
          if (elementKeys.includes(elementKey)) {
            onToggle(elementKeys.filter(element => element !== elementKey));
          } else {
            onToggle(keys => [...toArr(keys), elementKey]);
          }
        }

        if (type === "accordion") {
          if (elementKeys.includes(elementKey)) {
            onToggle([]);
          } else {
            onToggle([elementKey]);
          }
        }
        return;
      }
      setElementKey(elementKey);
    },
    [activeElementKey, onToggle, type, setElementKey, elementKeys],
  );

  const context = useMemo(() => {
    return { activeElementKey: elementKeys, onToggle: handleToggle };
  }, [elementKeys, handleToggle]);

  return (
    <AccordionContext.Provider value={context}>
      <Component className={styledClasses} {...otherProps}>
        {children}
      </Component>
    </AccordionContext.Provider>
  );
};

Accordion.propTypes = {
  // Element or Component to be rendered as the parent for accordion.
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

  // `elementKey` of the accordion/section which is active/open
  activeElementKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),

  type: PropTypes.oneOf(["accordion", "dropdown"]),

  // onToggle callback.
  onToggle: PropTypes.func,

  children: PropTypes.node,
  className: PropTypes.string,
};

Accordion.defaultProps = {
  element: "div",
  onToggle: () => {},
};

Accordion.Toggle = Toggle;
Accordion.Collapse = Collapse;

export default Accordion;
