import { createUseStyles } from "react-jss";

export const useStylesFilter = createUseStyles(theme => ({
  customHeader: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "18px",
    paddingBottom: "18px",
    width: "calc(100% - 30px)",
  },
  customToggle: {
    padding: "0 20px",
  },
  customCollapse: {
    padding: "10px 20px",
  },
}));

export const useStylesFilterContainer = createUseStyles(theme => ({
  customButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  modalNavigation: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    padding: "20px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
    },
  },
}));

export const useFilterOptionStyles = createUseStyles(theme => ({
  buttonOption: {
    padding: "11px 0",
    border: "1px solid black",
    textAlign: "center",
    margin: "4px",
    cursor: "pointer",
  },
  buttonTwoColumns: {
    flex: "1 0 47%",
    maxWidth: "calc(50% - 8px)",
  },
  buttonThreeColumns: {
    flex: "1 0 30%",
    maxWidth: "calc(33% - 8px)",
  },
  buttonOptionSelected: {
    backgroundColor: theme.color.black,
    color: theme.color.white,
  },
}));