export const manageRecentlySearched = (searched: string) => {
  const recentlySearchedLimit = 4;
  const lastSearched = localStorage.getItem("LAST_SEARCHED");
  let parsedSearched = [];
  if (lastSearched) {
    parsedSearched = JSON.parse(lastSearched);
    parsedSearched.unshift(searched);

    if (parsedSearched.length > recentlySearchedLimit) {
      parsedSearched.pop();
    }
  } else {
    parsedSearched.push(searched);
  }

  localStorage.setItem("LAST_SEARCHED", JSON.stringify(parsedSearched));
};
