import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { motion, AnimatePresence } from "framer-motion";
import classNames from "classnames";

import { useAccordionContext } from "../AccordionContext";

const useStyles = createUseStyles((theme) => ({
  section: {
    overflow: "hidden",
    borderBottom: (props) => (props.isOpen ? `1px solid ${theme.color.grey}` : null),
  },
  content: {
    padding: "10px 28px",
  },
}));

const Collapse = ({ elementKey, children, className, ...otherProps }) => {
  const { activeElementKey } = useAccordionContext();
  const isOpen = activeElementKey === elementKey;
  const classes = useStyles({ isOpen });
  const styledClasses = classNames(classes.content, className);
  const animationState = activeElementKey.includes(elementKey) ? "open" : "collapsed";

  return (
    <motion.section
      {...otherProps}
      className={classes.section}
      initial="collapsed"
      animate={animationState}
      exit="collapsed"
      variants={{
        open: { height: "auto" },
        collapsed: { height: 0 },
      }}
      transition={{ duration: 0.5 }}
    >
      <div className={styledClasses}>{children}</div>
    </motion.section>
  );
};

Collapse.propTypes = {
  elementKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Collapse;
