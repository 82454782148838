/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */

import React from "react";

import { SessionDataContext } from "@Context/sessionData";

interface OrderViewInterface {
  triggerQuery: (value: Record<string, any>) => void;
}

function OrderView({ triggerQuery }: OrderViewInterface) {
  const [sessionData] = React.useContext(SessionDataContext);

  const orderId = document.getElementById("return-order-id")?.innerHTML || "";
  const returnOrderButton = document.getElementById("return-portal-button") as HTMLButtonElement;

  // hydrate /my-account button with return portal
  React.useEffect(() => {
    if (sessionData.user_email && returnOrderButton) {
      returnOrderButton.disabled = false;
      returnOrderButton.onclick = () =>
        triggerQuery({ email: sessionData.user_email, id: orderId });
    }
  }, [triggerQuery, sessionData.user_email, returnOrderButton]);

  return <div />;
}

export default OrderView;
