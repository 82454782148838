import React from "react";
import DOMPurify from "dompurify";
import { createUseStyles, useTheme } from "react-jss";
import PropTypes from "prop-types";

import { DescriptionSkeleton } from "./loader";
import { Accordion, Typography } from "@Components/atoms";

const useStyles = createUseStyles(theme => ({
  customAccordion: {
    marginBottom: "3rem",
    "& div:nth-child(2)": {
      marginTop: "-4px",
    },
    [theme.breakpoints.up("sm")]: {
      borderTop: `none`,
      "& > div, section > div": {
        padding: 0,
      },
      "& > section > div": {
        padding: "10px 0",
      },
    },
  },
  description: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem",
      width: "100vw",
    },
  },
}));

const ProductDescription = ({ loading, description, returnPolicy, sku, activeElement, setActiveElement }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.description}>
      {loading ? (
        <DescriptionSkeleton />
      ) : (
        <Accordion activeElementKey={activeElement} onToggle={setActiveElement} className={classes.customAccordion}>
          {description && (
            <>
              <Accordion.Toggle elementKey={0}>
                <Typography variant="h2" gutterBottom={false}>
                  description
                </Typography>
              </Accordion.Toggle>
              <Accordion.Collapse elementKey={0}>
                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}></p>
                <p>Product Code: {sku} </p>
              </Accordion.Collapse>
            </>
          )}
          {returnPolicy && (
            <>
              <Accordion.Toggle elementKey={1}>
                <Typography variant="h2" gutterBottom={false}>
                  delivery & returns
                </Typography>
              </Accordion.Toggle>
              <Accordion.Collapse elementKey={1}>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(returnPolicy) }}></div>
              </Accordion.Collapse>
            </>
          )}
        </Accordion>
      )}
    </div>
  );
};

ProductDescription.propTypes = {
  sku: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  returnPolicy: PropTypes.string,
  loading: PropTypes.bool,
  activeElement: PropTypes.array.isRequired,
  setActiveElement: PropTypes.func.isRequired,
};

export default ProductDescription;
