import React, { useRef } from "react";
import { motion } from "framer-motion";

const Path = ({ color, ...other }) => (
  <motion.path fill="transparent" strokeWidth="2" strokeLinecap="round" {...other} stroke={color} />
);

export const Burger = ({ toggle, isOpen, color }) => {
  const containerRef = useRef(null);

  return (
    <motion.div initial={false} animate={isOpen ? "open" : "closed"} ref={containerRef}>
      <button className="menu-icon" onClick={toggle}>
        <svg width="23" height="23" viewBox="0 0 23 23">
          <Path
            color={color}
            variants={{
              closed: { d: "M 2 2.5 L 20 2.5" },
              open: { d: "M 3 16.5 L 17 2.5" },
            }}
          />
          <Path
            color={color}
            d="M 2 9.423 L 20 9.423"
            variants={{
              closed: { opacity: 1 },
              open: { opacity: 0 },
            }}
            transition={{ duration: 0.1 }}
          />
          <Path
            color={color}
            variants={{
              closed: { d: "M 2 16.346 L 20 16.346" },
              open: { d: "M 3 2.5 L 17 16.346" },
            }}
          />
        </svg>
      </button>
    </motion.div>
  );
};

export default Burger;
