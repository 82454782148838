import { useState } from "react";

const useDrawer = () => {
  const [selectedVariantIdForDrawer, setVariantIdForDrawer] = useState("");
  const isOpenDrawer = !!selectedVariantIdForDrawer;

  const handleOpenDrawer = (id: string) => {
    setVariantIdForDrawer(id);
  };
  const handleCloseDrawer = () => {
    setVariantIdForDrawer("");
  };
  return [selectedVariantIdForDrawer, isOpenDrawer, handleOpenDrawer, handleCloseDrawer] as const;
};

export default useDrawer;
