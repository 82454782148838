import { useQuery } from "react-query";

import { ATTRAQT_PLP_WIDGET_ID, useXO } from "@Services/attraqt";

interface UseWidgetOptions {
  isAuthenticated: boolean;
  slug: string;
  trigger: boolean;
  userId: string;
}

const useVariants = ({ slug, trigger, userId, isAuthenticated }: UseWidgetOptions) => {
  const xoWidgetId = document.getElementById("xo_widget_id")?.innerHTML || "";
  const isXoEnabled = !!xoWidgetId;
  const variables = JSON.stringify({ $collectionsList: slug });
  const variantESUrl = `/api/product-variant/collection/${slug}/`;

  const xoQuery = useXO({
    userId,
    isAuthenticated,
    trigger,
    variables,
    xoWidgetId: xoWidgetId || "",
  });

  const isUserUsingAdBlock = xoQuery.failureCount > 0;

  const esQuery = useQuery("esVariants", () => fetch(variantESUrl).then((res) => res.json()), {
    refetchOnWindowFocus: false,
    enabled: trigger && (isUserUsingAdBlock || !isXoEnabled),
    retry: false,
  });

  const tryXO = isUserUsingAdBlock ? esQuery : xoQuery;
  const properQuery = isXoEnabled ? tryXO : esQuery;

  return properQuery;
};

export default useVariants;
