import classNames from "classnames";
import React from "react";

import { Image, Link, ProductSkeleton, Typography } from "@Components/atoms";

import useStyles from "./styles";

type PannerType = "4 Products" | "Full Width";
interface PLPBannerProps {
  alt: string;
  bannerDescription: string;
  bannerTitle: string;
  className: string;
  link: {
    openInNewWindow: boolean;
    url: string;
  };
  mainImg: string;
  matchesDesktop: boolean;
  mobileImg: string;
  onClick: () => void;
  type: PannerType;
}

const formatDesktopImg = (img: string) => `${img}&w=1440, ${img}&w=2880 2x`;
const formatMobileImg = (img: string) => `${img}&w=700, ${img}&w=1400 2x`;

function PLPBanner({
  link,
  mainImg,
  alt,
  bannerTitle,
  bannerDescription,
  matchesDesktop,
  mobileImg,
  className,
  type,
  ...other
}: PLPBannerProps) {
  const classes = useStyles();

  const styledContainer = classNames(
    classes.bannerBox,
    type === "4 Products" && classes.fourByFourBanner,
    type === "Full Width" && classes.fullWidthBanner,
    className,
  );

  const styledAspectRatio = classNames(
    classes.bannerImg,
    type === "4 Products" && classes.rootAspectRatio,
  );

  const currentPhotoSet = matchesDesktop
    ? formatDesktopImg(mainImg)
    : mobileImg
    ? formatMobileImg(mobileImg)
    : formatMobileImg(mainImg);

  const currentPhoto = matchesDesktop
    ? `${mainImg}?w=1440&q=75`
    : mobileImg
    ? `${mobileImg}?w=700&q=75`
    : `${mainImg}?w=700&q=75`;

  const fontSize = matchesDesktop ? "14px" : "12px";

  return (
    <Link
      href={link?.url}
      className={styledContainer}
      openInNewTab={link?.openInNewWindow}
      data-test="hp-main-baner"
      {...other}
    >
      <div className={styledAspectRatio}>
        <Image
          src={currentPhoto}
          srcSet={currentPhotoSet}
          alt={alt}
          className={classes.bannerImg}
          fallback={<ProductSkeleton />}
        />
      </div>
      <div className={classes.descriptionBox}>
        {bannerTitle && (
          <Typography fontSize={fontSize} gutterSide data-test="product-name">
            {bannerTitle}
          </Typography>
        )}
        {bannerTitle && (
          <Typography fontSize={fontSize} color="secondary" gutterSide>
            {bannerDescription}
          </Typography>
        )}
      </div>
    </Link>
  );
}

export default PLPBanner;
