import React from "react";
import ReactPlayer from "react-player";

import { ProductSkeleton } from "@Components/atoms";

import { DefaultVideoMediaFragment } from "../../../../globals/fragments.generated";
import useStyle from "./style";

interface VideoBlockProps {
  data: DefaultVideoMediaFragment;
  isMobile: boolean;
}

const defaultProps = {
  poster: "",
};
export function VideoMedia({ data, isMobile }: VideoBlockProps) {
  const classes = useStyle();

  const containerLink = data.link;
  const TagLinkWrapper = containerLink?.url ? "a" : "div";
  const isNewWindow = containerLink?.openInNewWindow ? "_black" : "_self";

  const isMobileVideoProvided = !!data.mediaMobile?.url;
  const desktopVideo = data.mediaDesktop;
  const mobileVideo = isMobileVideoProvided ? data.mediaMobile : desktopVideo;
  const video = isMobile ? mobileVideo : desktopVideo;
  const poster = isMobile ? data.posterMobile : data.posterDesktop;

  const isAutoplay = !!data.autoplay;
  const isControls = containerLink?.url ? false : !isAutoplay;
  const isLoop = !!data.loop;

  return (
    <TagLinkWrapper className={classes.videoBlock} href={containerLink?.url} target={isNewWindow}>
      <ReactPlayer
        url={video?.url}
        loop={isLoop}
        controls={isControls}
        playing={isAutoplay}
        muted={isAutoplay}
        width="100%"
        playsinline
        height="100%"
        className={classes.videoPlayer}
        fallback={<ProductSkeleton />}
        config={{
          file: {
            attributes: {
              poster: poster?.url,
            },
          },
        }}
      />
    </TagLinkWrapper>
  );
}

VideoMedia.defaultProps = defaultProps;

export default VideoMedia;
