import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import PropTypes from "prop-types";

import { Typography } from "@Components/atoms";

const useStyles = createUseStyles({
  root: {
    width: "100%",
    marginBottom: "4rem",
  },
  breadcrumbsList: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  breadcrumbsListChild: {
    padding: [0, 10],
    textDecoration: "underline",
  },
});

export const Breadcrumbs = ({ children, component: Component = "nav", title = "View More", ...other }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const processedBreadcrumbs = React.Children.toArray(children)
    .filter(child => React.isValidElement(child))
    .map((child, index) => (
      <li className={classes.breadcrumbsListChild} key={`breadcrumbs-child-${index}`}>
        {child}
      </li>
    ));

  return (
    <Component className={classes.root} {...other}>
      {title.length > 0 && (
        <Typography variant="h2" textTransform="uppercase" align="center" paragraph>
          {title}
        </Typography>
      )}
      <ul className={classes.breadcrumbsList}>{processedBreadcrumbs}</ul>
    </Component>
  );
};

Breadcrumbs.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  component: PropTypes.elementType,
};

export default Breadcrumbs;
