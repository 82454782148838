import React from "react";
import Div100vh from "react-div-100vh";
import { useTheme } from "react-jss";
import ReactPlayer from "react-player";

import { Container, Image, Link, ProductSkeleton, Typography } from "@Components/atoms";
import { BannerArrow } from "@Components/atoms/Icons";
import { useMediaQuery } from "@Hooks/index";
import { ThemeType } from "@Theme/theme";
import { gtmEnhancedEcommerce } from "@Utilities/gtm";

import { DefaultFullWidthImageFragment } from "../../../../globals/fragments.generated";
import useStyles from "./style";

interface FullWidthMediaProps {
  data: DefaultFullWidthImageFragment;
}

export function FullWidthMedia({ data }: FullWidthMediaProps) {
  const theme = useTheme<ThemeType>();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const bannerText = data.content;
  const bannerTextColor = data.textColour?.colourHex;
  const bannerContent = data.content?.contentCollection?.items.filter((item) => item !== null);

  const bannerMedia = bannerContent?.find((item) => item?.__typename === "Media");
  const bannerLink = bannerContent?.find((item) => item?.__typename === "Button");

  const mediaURL = isMobile ? bannerMedia?.mediaMobile : bannerMedia?.mediaDesktop;
  const mediaFullType = isMobile
    ? bannerMedia?.mediaMobile?.contentType
    : bannerMedia?.mediaDesktop?.contentType;

  const isVideo = mediaFullType.split("/")[0] === "video";

  const handleTracking = () => {
    gtmEnhancedEcommerce(
      "promotionClick",
      [
        {
          id: data?.content?.internalName,
          name: data?.content?.externalName,
          creative: bannerMedia?.internalName,
          position: data?.internalName,
        },
      ],
      "Homepage",
    );
  };

  return (
    <Container
      maxWidth={false}
      disableGutters
      bottomGutters
      className={classes.bannerContainer}
      onClick={handleTracking}
    >
      <Link href={bannerMedia?.link?.url}>
        <Div100vh>
          {isVideo ? (
            <ReactPlayer
              url={mediaURL.url}
              playing
              loop
              muted
              width="100%"
              height="100%"
              className={classes.videoPlayer}
              fallback={<ProductSkeleton />}
              playsinline
            />
          ) : (
            <picture>
              <source srcSet={mediaURL.url} type="image/webp" />
              <Image
                src={mediaURL.urlJpg}
                className={classes.imageBanner}
                fallback={<ProductSkeleton />}
              />
            </picture>
          )}
        </Div100vh>
      </Link>
      <div className={classes.bannerContent}>
        {bannerMedia && (
          <Typography
            variant="h2"
            customColor={bannerTextColor}
            mb="16px"
            fontSize={matches ? "20px" : "24px"}
            align="center"
          >
            {bannerText?.externalName}
          </Typography>
        )}
        {bannerLink && (
          <Typography fontSize="14px" gutterBottom={false}>
            <Link href={bannerLink.link.url} underline>
              {bannerLink?.externalName}
            </Link>
          </Typography>
        )}
      </div>
      <BannerArrow />
    </Container>
  );
}

export default FullWidthMedia;
