import React from "react";

import Tag from "@Components/atoms/Tag";
import Typography from "@Components/atoms/Typography";

function SaleTag() {
  return (
    <Tag>
      <Typography customColor="#ec5840" gutterBottom={false} gutterSide>
        SALE
      </Typography>
    </Tag>
  );
}

export default SaleTag;
