import React from "react";

import { Button, Container, Stack, Text } from "@mantine/core";

import Order from "../../components/Order/Order";
import OrderList from "../../components/OrderList/OrderList";
import { ReasonWithType, ReturnReference } from "../../types";

interface OrderListProps {
  handleNextStep: () => void;
  handleOpenDrawer: (id: string) => void;
  handleReturnReasonQuantityChange: (id: string, newQuantity: number) => void;
  handleReturnReasonRemove: (el: string) => void;
  isAfterReturnPeriod: boolean;
  numberOfReturnDays: number;
  productsNotSuitableForReturn: ReturnReference[];
  returnPossibleUntil: string;
  returnReasons: ReasonWithType[];
  returnedLines: ReturnReference[];
  toReturnLines: ReturnReference[];
}

function ReturnPageList({
  handleNextStep,
  handleOpenDrawer,
  handleReturnReasonQuantityChange,
  handleReturnReasonRemove,
  returnedLines,
  toReturnLines,
  isAfterReturnPeriod,
  numberOfReturnDays,
  returnReasons,
  returnPossibleUntil,
  productsNotSuitableForReturn,
}: OrderListProps) {
  const isNextDisabled = returnReasons.length === 0;
  const isAnyProductReturned = returnedLines.length > 0;
  const isAnytoReturnLines = toReturnLines.length > 0;
  const isNotSuitableForReturn = productsNotSuitableForReturn.length > 0;

  const checkForReason = (id: string) =>
    returnReasons.find((selectedReasonA) => selectedReasonA.productVariantId === id);

  return (
    <Container pb="xl" size="sm">
      <Stack spacing="xl" align="center">
        {/* after return period - products not returned  */}
        {isAfterReturnPeriod === true ? (
          <>
            <OrderList
              variants={toReturnLines}
              header={
                <>
                  <Text size="sm" mb="md">
                    Your {numberOfReturnDays}-day return window has passed and these items cannot be
                    returned online
                  </Text>
                  <Text size="sm" mb="xl">
                    Please contact our Customer Service for assistance.
                  </Text>
                </>
              }
            >
              <Stack spacing={5} justify="flex-start">
                <Order.Title />
                <Order.Color />
                <Order.Size />
                <Order.Quantity />
                <Order.Price />
              </Stack>
            </OrderList>
            <Button variant="outline" disabled={isNextDisabled} fullWidth onClick={handleNextStep}>
              Next
            </Button>
          </>
        ) : null}
        {/* In return perion - products to return  */}
        {isAfterReturnPeriod === false ? (
          <>
            <OrderList
              variants={toReturnLines}
              header={
                <>
                  <Text size="sm" mb="md">
                    You can make returns from this order until {returnPossibleUntil}
                  </Text>
                  {isAnytoReturnLines ? (
                    <Text size="sm" mb="xl">
                      Select products to return
                    </Text>
                  ) : null}
                </>
              }
            >
              {({ productVariantId, quantity }) => (
                <>
                  <Stack spacing={5} justify="flex-start">
                    <Order.Title />
                    <Order.Color />
                    <Order.Size />
                    {checkForReason(productVariantId) ? (
                      <Order.QuantityPicker
                        handleReturnReasonQuantityChange={handleReturnReasonQuantityChange}
                        quantity={quantity}
                      />
                    ) : (
                      <Order.Quantity />
                    )}
                    <Order.Price />
                  </Stack>
                  <Order.ReasonButton
                    handleReturnReasonRemove={handleReturnReasonRemove}
                    isSelectedReason={!!checkForReason(productVariantId)}
                    openReasonDrawer={handleOpenDrawer}
                    productVariantId={productVariantId}
                  />
                </>
              )}
            </OrderList>
            <Button variant="outline" disabled={isNextDisabled} fullWidth onClick={handleNextStep}>
              Next
            </Button>
          </>
        ) : null}
        {/* products returned  */}
        {isAnyProductReturned === true ? (
          <OrderList
            variants={returnedLines}
            header={
              <Text size="xl" mb="xl" fw={700}>
                Products returned
              </Text>
            }
          >
            <Stack spacing={5} justify="flex-start">
              <Order.Title />
              <Order.Color />
              <Order.Size />
              <Order.Quantity />
              <Order.Price />
            </Stack>
            <Order.Reason />
          </OrderList>
        ) : null}
        {isNotSuitableForReturn === true ? (
          <OrderList
            variants={productsNotSuitableForReturn}
            header={
              <Text size="xl" mb="xl" fw={700}>
                Items not eligible for return or exchange
              </Text>
            }
          >
            <Stack spacing={5} justify="flex-start">
              <Order.Title />
              <Order.Color />
              <Order.Size />
              <Order.Quantity />
              <Order.Price />
            </Stack>
          </OrderList>
        ) : null}
      </Stack>
    </Container>
  );
}

export default ReturnPageList;
