import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import loaderLogo from "../../../../../images/current/loader.png";

export const Spinner = props => (
  <div className="text-center spinner__wrapper">
    <img className={classNames({ spinner: true, spinner__small: props.small })} src={loaderLogo} alt="" />
  </div>
);

Spinner.propTypes = {
  small: PropTypes.bool,
};

export default Spinner;
