import { useQuery } from 'react-query'

export const useCarousel = () => {

  return useQuery('variants', () =>
    fetch(`/api/carousel/`).then(res =>
      res.json()
    ), { refetchOnWindowFocus: false }
  )
}

export default useCarousel;