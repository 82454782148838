import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";

import { Link, Accordion, Typography } from "@Components/atoms/";
import { useMediaQuery } from "@Hooks/index";

const useStyles = createUseStyles((theme) => ({
  styledList: {
    listStyleType: "none",
    margin: "0 3rem 0 0",
    fontSize: "13px",
    "@global": {
      li: {
        margin: "1rem 0",
      },
    },
  },
  desktopLinksContainer: {
    display: "flex",
    direction: "columns",
    [theme.breakpoints.up("sm")]: {
      width: "70%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "35%",
    },
  },
  linkItem: {
    flexBasis: "33%",
  },
  customHeader: {
    fontSize: "1rem",
  },
}));

export const FooterLinks = ({ mobileLinks, desktopLinks }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [activeElement, setActiveElement] = useState([]);

  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      {matches ? (
        <div className={classes.desktopLinksContainer}>
          {desktopLinks.items
            .filter((item) => item !== null)
            .map((linkItem, index) => {
              return (
                <div key={index} className={classes.linkItem}>
                  <Typography fontSize="1rem" variant="h2" fontFamily="primaryFont">
                    {linkItem.externalName}
                  </Typography>
                  <ul className={classes.styledList}>
                    {linkItem.navigationLinksCollection.items
                      .filter((item) => item !== null)
                      .map((link, index) => (
                        <Link href={link?.url} key={index}>
                          <li>{link.externalName}</li>
                        </Link>
                      ))}
                  </ul>
                </div>
              );
            })}
        </div>
      ) : (
        <>
          {mobileLinks.items
            .filter((item) => item !== null)
            .map((linkItem, index) => {
              return (
                <Accordion activeElementKey={activeElement} onToggle={setActiveElement} key={index}>
                  <Accordion.Toggle elementKey={index}>
                    <Typography variant="h2" gutterBottom={false}>
                      {linkItem.externalName}
                    </Typography>
                  </Accordion.Toggle>
                  <Accordion.Collapse elementKey={index}>
                    <ul className={classes.styledList}>
                      {linkItem.navigationLinksCollection.items
                        .filter((item) => item !== null)
                        .map((link, index) => (
                          <Link href={link?.url} key={index}>
                            <li>{link.externalName}</li>
                          </Link>
                        ))}
                    </ul>
                  </Accordion.Collapse>
                </Accordion>
              );
            })}
        </>
      )}
    </>
  );
};

export default FooterLinks;

FooterLinks.propTypes = {
  mobileLinks: PropTypes.object,
  desktopLinks: PropTypes.object,
};
