import theme from "../../../theme";

export const SPACINGS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const GRID_SIZES = ["auto", true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

function _extends() {
  _extends =
    Object.assign ||
    function (target) {
      for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];

        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }

      return target;
    };

  return _extends.apply(this, arguments);
}
function generateGrid(globalStyles, theme, breakpoint) {
  const styles = {};
  const emptyStyles = {};
  GRID_SIZES.forEach(size => {
    const key = `grid-${breakpoint}-${size}`;

    if (size === true) {
      styles[key] = {
        flexBasis: 0,
        flexGrow: 1,
        maxWidth: "100%",
      };
      return;
    }

    if (size === "auto") {
      styles[key] = {
        flexBasis: "auto",
        flexGrow: 0,
        maxWidth: "none",
      };
      return;
    }

    const width = `${Math.round((size / 12) * 10e7) / 10e5}%`;

    styles[key] = {
      flexBasis: width,
      flexGrow: 0,
      maxWidth: width,
    };

    emptyStyles[key] = {};
  });

  if (breakpoint === "xs") {
    _extends(globalStyles, styles);
  } else {
    globalStyles[theme.breakpoints.up(breakpoint)] = styles;
    _extends(globalStyles, emptyStyles);
  }
}

function getOffset(val, div = 1) {
  const parse = parseFloat(val);
  return `${parse / div}${String(val).replace(String(parse), "") || "px"}`;
}

function generateGutter(theme, breakpoint) {
  const styles = {};
  SPACINGS.forEach(spacing => {
    const themeSpacing = theme.spacing(spacing);

    if (themeSpacing === 0) {
      return;
    }

    styles[`spacing-${breakpoint}-${spacing}`] = {
      margin: `-${getOffset(themeSpacing, 2)}`,
      width: `calc(100% + ${getOffset(themeSpacing)})`,
      "& > $item": {
        padding: getOffset(themeSpacing, 2),
      },
    };
  });
  return styles;
}

const gutter = generateGutter(theme, "xs");

const grid = theme.breakpoints.keys.reduce((accumulator, key) => {
  generateGrid(accumulator, theme, key);
  return accumulator;
}, {});

export const gridStyle = {
  root: {},
  container: {
    boxSizing: "border-box",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },

  item: {
    boxSizing: "border-box",
    margin: "0",
  },

  zeroMinWidth: {
    minWidth: 0,
  },

  directionXsColumn: {
    flexDirection: "column",
  },

  directionXsColumnReverse: {
    flexDirection: "column-reverse",
  },

  directionXsRowReverse: {
    flexDirection: "row-reverse",
  },

  wrapXsNowrap: {
    flexWrap: "nowrap",
  },

  wrapXsWrapReverse: {
    flexWrap: "wrap-reverse",
  },

  alignItemsCenter: {
    alignItems: "center",
  },

  alignItemsFlexStart: {
    alignItems: "flex-start",
  },

  alignItemsFlexEnd: {
    alignItems: "flex-end",
  },

  alignItemsBaseline: {
    alignItems: "baseline",
  },

  alignContentXsCenter: {
    alignContent: "center",
  },

  alignContentXsFlexStart: {
    alignContent: "flex-start",
  },

  alignContentXsFlexEnd: {
    alignContent: "flex-end",
  },

  alignContentXsSpaceBetween: {
    alignContent: "space-between",
  },

  alignContentXsSpaceAround: {
    alignContent: "space-around",
  },

  justifyCenter: {
    justifyContent: "center",
  },

  justifyFlexEnd: {
    justifyContent: "flex-end",
  },

  justifySpaceBetween: {
    justifyContent: "space-between",
  },

  justifySpaceAround: {
    justifyContent: "space-around",
  },

  justifySpaceEvenly: {
    justifyContent: "space-evenly",
  },
  ...grid,
  ...gutter,
};

export default gridStyle;
