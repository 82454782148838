import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";

interface Wizard {
  numberOfSteps: number;
}

interface WizardReturn {
  handleNextStep: () => void;
  handlePrevStep: () => void;
  step: number;
}

export const WizardContext = createContext<WizardReturn | null>(null);

export const useWizard = () => {
  const context = useContext(WizardContext);

  if (!context) {
    throw new Error("Not a child of wizard component");
  }

  return context;
};

export function WizardProvider({ children, numberOfSteps }: PropsWithChildren<Wizard>) {
  const [step, setStep] = useState(0);

  const incrementStep = () => {
    setStep((prevStep) => {
      if (prevStep < numberOfSteps) {
        return prevStep + 1;
      }
      return prevStep;
    });
  };

  const decrementStep = () => {
    setStep((prevStep) => {
      if (prevStep >= 1) {
        return prevStep - 1;
      }
      return prevStep;
    });
  };

  const handleNextStep = () => {
    window.scrollTo({ top: 0 });
    incrementStep();
  };

  const handlePrevStep = () => {
    window.scrollTo({ top: 0 });
    decrementStep();
  };

  const wizardToolkit = useMemo(() => ({ step, handleNextStep, handlePrevStep }), [step]);

  return <WizardContext.Provider value={wizardToolkit}>{children}</WizardContext.Provider>;
}
