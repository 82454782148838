import { parse, stringify } from "qs";

export function addQS(history, target, slug) {
  const query = parse(history.location.search.substr(1));
  let baseQuery = {
    category: [].concat(query.category),
    sub_category: [].concat(query.sub_category),
    size: [].concat(query.size),
    color: [].concat(query.color),
    order: query.order || undefined,
    q: query.q,
    page: query.page,
    in_stock: query.in_stock || undefined,
  };
  switch (target) {
    case "category":
      baseQuery.category = slug;
      break;
    case "sub category":
      baseQuery.sub_category = slug;
      break;
    case "size":
      baseQuery.size = slug;
      if (slug?.length === 0) {
        baseQuery.in_stock = undefined;
      } else {
        baseQuery.in_stock = "true";
      }
      break;
    case "colour":
      baseQuery.color = slug;
      break;
    case "order":
      baseQuery.order = slug;
      break;
    case "page":
      baseQuery.page = slug;
      break;
    case "in_stock":
      baseQuery.in_stock = slug;
      break;
  }
  return history.replace({
    search: stringify(baseQuery, { arrayFormat: "repeat" }),
  });
}

export function clearCategoryQS(history, target) {
  const query = parse(history.location.search.substr(1));
  let baseQuery = {
    category: [].concat(query.category),
    sub_category: [].concat(query.sub_category),
    size: [].concat(query.size),
    color: [].concat(query.color),
    q: query.q,
    page: query.page,
    in_stock: query.in_stock,
  };

  switch (target) {
    case "category":
      baseQuery.category = [];
      baseQuery.sub_category = [];
      break;
    case "sub category":
      baseQuery.sub_category = [];
      break;
    case "size":
      baseQuery.in_stock = undefined;
      baseQuery.size = [];
      break;
    case "colour":
      baseQuery.color = [];
      break;
    case "order":
      baseQuery.order = undefined;
      break;
    case "in_stock":
      baseQuery.in_stock = undefined;
      break;
  }
  return history.replace({
    search: stringify(baseQuery, { arrayFormat: "repeat" }),
  });
}

export function replaceTargetQS(history, target, slug) {
  const query = parse(history.location.search.substr(1));

  query[target] = slug;

  return history.replace({
    search: stringify(query, { arrayFormat: "repeat" }),
  });
}

export function clearTargetQS(history, target) {
  const query = parse(history.location.search.substr(1));

  delete query[target];

  return history.replace({
    search: stringify(query, { arrayFormat: "repeat" }),
  });
}

export function clearQS(history) {
  let query = {
    q: parse(history.location.search.substr(1)).q,
    page: parse(history.location.search.substr(1)).page,
  };
  return history.replace({
    search: stringify(query),
  });
}

export function getParams(location) {
  const query = parse(location.search.substr(1));
  return {
    category: query.category === undefined ? [] : [].concat(query.category),
    sub_category: query.sub_category === undefined ? [] : [].concat(query.sub_category),
    size: query.size === undefined ? [] : [].concat(query.size),
    color: query.color === undefined ? [] : [].concat(query.color),
    order: query.order === undefined ? [] : [].concat(query.order),
    searchQuery: query.q,
    page: query.page || 1,
    in_stock: query.in_stock || false,
  };
}
