import React from "react";
import { useMediaQuery } from "@Hooks/index";
import { useTheme } from "react-jss";
import { ColorBox, Typography, Link } from "@Components/atoms";
import { Colors } from "@Types/API/variants";
import { useStyles } from "./style";

interface CarouselColorPicker {
  productGTMEvent: (colour?: string, displayColour?: string) => void;
  colors: Colors[];
  link: string;
  actualColor: string;
  maxBoxNumber?: number;
}

const CarouselColorPicker = ({ colors, link, actualColor, productGTMEvent, maxBoxNumber = 4 }: CarouselColorPicker) => {
  const classes = useStyles();
  const theme = useTheme() as any;
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const outer = isMobile ? "32" : "24";
  const inner = isMobile ? "26" : "20";
  const padding = isMobile ? "3" : "2";

  return (
    <div className={classes.colorPickerContainer}>
      {colors
        ?.sort((x, y) => (x.raw_value == actualColor ? -1 : y.raw_value == actualColor ? 1 : 0))
        .slice(0, maxBoxNumber)
        .map(color => {
          const hexColor = color.hexcolor;
          const colorRawValue = color.raw_value;
          const displayValue = color.display_value;

          return (
            <Link
              onClick={() => productGTMEvent(colorRawValue, displayValue)}
              href={`${link}?color=${colorRawValue}`}
              key={link + actualColor + colorRawValue}
            >
              <ColorBox
                fill={hexColor}
                selected={colorRawValue === actualColor}
                inner={inner}
                outer={outer}
                padding={padding}
              />
            </Link>
          );
        })}
      <span>
        <Link href={link} onClick={() => productGTMEvent(actualColor)}>
          <Typography gutterBottom={false}>
            {colors.length > maxBoxNumber ? `+ ${colors.length - maxBoxNumber}` : null}
          </Typography>
        </Link>
      </span>
    </div>
  );
};

export default CarouselColorPicker;
