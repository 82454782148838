import React from "react";

import Tag from "@Components/atoms/Tag";
import Typography from "@Components/atoms/Typography";

function NewSeasonTag() {
  return (
    <Tag>
      <Typography customColor="#000" gutterBottom={false} gutterSide>
        NEW SEASON
      </Typography>
    </Tag>
  );
}

export default NewSeasonTag;
