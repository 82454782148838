import { compose, space, color, typography } from "styled-system";
import { createUseStyles } from "react-jss";
import { TypographyStyleProps }from './Typography'

export const useStyles = createUseStyles(theme => ({
    system: compose(space, color, typography),
    root: {
      margin: 0,
      fontWeight: (props: TypographyStyleProps) => props.fontWeight,
    },
    typographyFontSize: {
      fontSize: (props: TypographyStyleProps) => props.fontSize,
    },
    typographyGutter: {
      marginBottom: theme.spacing(1),
    },
    typographyGutterTop: {
      marginTop: theme.spacing(1),
    },
    typographyGutterSide: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    typographyParagraph: {
      marginBottom: theme.spacing(2),
    },
    typographyDecorator: (props: TypographyStyleProps) => ({
      textDecoration: props.decorator,
    }),
    typographyCursor: (props: TypographyStyleProps) => ({
      cursor: props.cursor,
    }),
    textTransform: (props: TypographyStyleProps) => ({
      textTransform: props.textTransform,
    }),
    alignLeft: {
      textAlign: "left",
    },
    alignCenter: {
      textAlign: "center",
    },
    alignRight: {
      textAlign: "right",
    },
    alignJustify: {
      textAlign: "justify",
    },
    noWrap: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    displayInline: {
      display: "inline",
    },
    displayBlock: {
      display: "block",
    },
    colorInherit: {
      color: "inherit",
    },
    colorPrimary: {
      color: theme.palette.primary.main,
    },
    colorSecondary: {
      color: theme.palette.secondary.main,
    },
    colorTertiary: {
      color: theme.palette.tertiary.main,
    },
    colorError: {
      color: theme.palette.error.main,
    },
    customColor: {
      color: (props: TypographyStyleProps) => props.customColor,
    },
    primaryFont: {
      fontFamily: '"acumin-pro", sans-serif',
    },
    secondaryFont: {
      fontFamily: '"nimbus-roman", serif',
    },
    newParagraph: {
      fontFamily: '"acumin-pro", sans-serif',
      fontSize: "12px",
      textTransform: "unset",
      letterSpacing: "unset",
      [theme.breakpoints.up("md")]: {
        fontSize: "14px",
      },
    }
  }));