import React, { PropsWithChildren } from "react";

import Item from "./components/Item";
import useStyles, { CustomGridStyleProps } from "./style";

const CustomGridDefaultProps = {
  isFullWidth: false,
  gridGap: 0,
  columns: 12,
};

export function CustomGrid({
  children,
  isFullWidth = false,
  gridGap,
  columns,
}: PropsWithChildren<CustomGridStyleProps>) {
  const classes = useStyles({ isFullWidth, gridGap, columns });

  return <div className={classes.customGrid}>{children}</div>;
}

CustomGrid.defaultProps = CustomGridDefaultProps;
CustomGrid.Item = Item;

export default CustomGrid;
